import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import {
    Button, Card, CardBody, Col, FormGroup, Label, Row
} from 'reactstrap'
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../data/extensions/alerte_extensions/ErrorAlert";
import { useLocation } from 'react-router-dom';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { Breadcrumb } from "react-bootstrap";
import Select from 'react-select'
import { selectThemeColors } from '../data/Utils'
import {
    ProfileOptions,
    UsersOptions, RecordOptions,
    Conference_NmaeOptions, Wait_For_ModeratorOptions, Moderator_endconfOption,
    Announce_NameOptions, Announce_CountOption, Announce_RecordingOption,
    MuteOption, SoundsOptions
} from "../util/FormConfRomms";
import { ToastContainer } from 'react-toastify';
import ConferenceCenterApiService from "../util/services/ConferenceCenterApiService";
import ConferenceRoomsApiService from "../util/services/ConferenceRoomsApiService";
import accountsApiService from "../util/services/AccountsApiService";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddEditConferenceRoom = () => {
    const [account, setAccounts] = useState({});
    const [initApp, setInitApp] = useState(true);
    const [enabled, setEnabled] = useState('Y');
    const [conference_Name, setConference_Name] = useState(null);
    const [users, setUsers] = useState(null);
    const [profile, setProfile] = useState(null);
    const [record, setRecord] = useState(null);
    const [wait_For_Moderator, setWait_For_Moderator] = useState(null);
    const [moderator_endconf, setModerator_endconf] = useState(null);
    const [announce_Name, setAnnounce_Name] = useState(null);
    const [announce_Count, setAnnounce_Count] = useState(null);
    const [announce_Recording, setAnnounce_Recording] = useState(null);
    const [mute, setMute] = useState(null);
    const [sounds, setSounds] = useState(null);
    const [user_info, setUser_info] = useState(null);
    const [confCenter_info, setConfCenter_info] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    let navigate = useNavigate();
    const location = useLocation();
    const dataa = location?.state?.object;

    const onFinish = (event, values) => {
        event.preventDefault();
        values.Enabled = enabled
        values.ConCenter_id = confCenter_info?.value
        values.account_id = user_info?.value
        values.Profile = profile?.value
        values.Record = record?.value
        values.Wait_For_Moderator = wait_For_Moderator?.value
        values.Moderator_endconf = moderator_endconf?.value
        values.Announce_Name = announce_Name?.value
        values.Announce_Count = announce_Count?.value
        values.Announce_Recording = announce_Recording?.value
        values.Mute = mute?.value
        values.Sounds = sounds?.value
        values.Schedule = {
            StartDate: startDate,
            EndDate: endDate
        }
        if (location?.state?.object) {
            values.confRoom_id = dataa.confRoom_id
            ConferenceRoomsApiService.update(values).then(res => {
                setTimeout(() => {
                    navigate(`/application/conferenceRooms`);
                }, 3000);
                notifySuccess(" Conference room has been updated");
            }).catch(err => {
                notifyError("Error to update Conference room " + err);
            });
        }
        else {
            ConferenceRoomsApiService.save(values).then(res => {
                setTimeout(() => {
                    navigate(`/application/conferenceRooms`);
                }, 3000);
                notifySuccess("Conference room  has been added")
            }).catch(err => {
                notifyError("error ");
            });
        }
    }

    const getAllAccounts = () => {
        accountsApiService.find().then(res => {
            const Useer = []
            const accounts = res.data.data
            accounts.forEach(element => {
                if (element.account_id !== undefined && element.account_id !== null) {
                    let user_data = {
                        value: element.account_id,
                        label: element.Username
                    }
                    Useer.push(user_data)

                    if (dataa && element && dataa.account_id === element.account_id) {
                        setUser_info(user_data);
                    }
                }
            });
            setAccounts(Useer);
        });
    };

    const getAllConfCenter = () => {
        ConferenceCenterApiService.find().then(res => {
            const Useers = []
            const centers = res.data.data
            centers.forEach(element => {
                if (element.ConCenter_id !== undefined && element.ConCenter_id !== null) {
                    let user_data = {
                        value: element.ConCenter_id,
                        label: element.Name
                    }
                    Useers.push(user_data)

                    if (dataa && element && dataa.ConCenter_id === element.ConCenter_id) {
                        setConfCenter_info(user_data);
                    }
                }
            });
            setConference_Name(Useers);
        });
    };

    useEffect(() => {
        const Conference_Name = Conference_NmaeOptions.find(item => item.value == dataa?.Conference_Name)
        setConference_Name(Conference_Name)
        const Users = UsersOptions.find(item => item.value == dataa?.Users)
        setUsers(Users)
        const Profile = ProfileOptions.find(item => item.value == dataa?.Profile)
        setProfile(Profile)
        const Record = RecordOptions.find(item => item.value == dataa?.Record)
        setRecord(Record)
        const Wait_For_Moderator = Wait_For_ModeratorOptions.find(item => item.value == dataa?.Wait_For_Moderator)
        setWait_For_Moderator(Wait_For_Moderator)
        const Moderator_endconf = Moderator_endconfOption.find(item => item.value == dataa?.Moderator_endconf)
        setModerator_endconf(Moderator_endconf)
        const Announce_Name = Announce_NameOptions.find(item => item.value == dataa?.Announce_Name)
        setAnnounce_Name(Announce_Name)
        const Announce_Count = Announce_CountOption.find(item => item.value == dataa?.Announce_Count)
        setAnnounce_Count(Announce_Count)
        const Announce_Recording = Announce_RecordingOption.find(item => item.value == dataa?.Announce_Recording)
        setAnnounce_Recording(Announce_Recording)
        const Mute = MuteOption.find(item => item.value == dataa?.Mute)
        setMute(Mute)
        const Sounds = SoundsOptions.find(item => item.value == dataa?.Sounds)
        setSounds(Sounds)

        getAllAccounts()
        getAllConfCenter()

    }, [initApp]);

    const notifySuccess = (message) => SuccessAlert(message)
    const notifyError = (message) => ErrorAlert(message)

    return (
        <Fragment>
            <ToastContainer
            />
            <div className="page-header">
                <div>
                    {dataa ? <h2 className="main-content-title tx-24 mg-b-5"> Edit Conference Room</h2> : <h2 className="main-content-title tx-24 mg-b-5"> Add Conference Room </h2>}
                    <Breadcrumb >
                        <Link to={`/dashboard`}>
                            <Breadcrumb.Item active>
                                Home /
                            </Breadcrumb.Item></Link>
                        <Link to={`/application/conferenceRooms`}>
                            <Breadcrumb.Item active>
                                Conference Rooms
                            </Breadcrumb.Item></Link>
                    </Breadcrumb>
                </div>

            </div>
            <AvForm onValidSubmit={onFinish}>
                <Row>
                    <Col md='12'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Conference_Name</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={confCenter_info}
                                                options={conference_Name}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setConfCenter_info(obj)}
                                                name='ConCenter_id'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Room Name</Label>
                                            <AvInput type='text' name='Room_Name'
                                                value={dataa?.Room_Name} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Moderator</Label>
                                            <AvInput type='text' name='Moderator'
                                                value={dataa?.Moderator} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Participant</Label>
                                            <AvInput type='text' name='Participant'
                                                value={dataa?.Participant} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Users</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={user_info}
                                                options={account}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setUser_info(obj)}
                                                name='account_id'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Profile</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={profile}
                                                options={ProfileOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setProfile(obj)}
                                                name='Profile'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Record</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={record}
                                                options={RecordOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setRecord(obj)}
                                                name='Record'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Max_Members</Label>
                                            <AvInput type='text' name='Max_Members'
                                                value={dataa?.Max_Members} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>wait For Moderator</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={wait_For_Moderator}
                                                options={Wait_For_ModeratorOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setWait_For_Moderator(obj)}
                                                name='Wait_For_Moderator'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Moderator endconf</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={moderator_endconf}
                                                options={Moderator_endconfOption}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setModerator_endconf(obj)}
                                                name='Moderator_endconf'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Announce Name</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={announce_Name}
                                                options={Announce_NameOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setAnnounce_Name(obj)}
                                                name='Announce_Name'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Announce Count</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={announce_Count}
                                                options={Announce_CountOption}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setAnnounce_Count(obj)}
                                                name='Announce_Count'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Announce Recording</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={announce_Recording}
                                                options={Announce_RecordingOption}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setAnnounce_Recording(obj)}
                                                name='Announce_Recording'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Mute</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={mute}
                                                options={MuteOption}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setMute(obj)}
                                                name='Mute'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Sounds</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={SoundsOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setSounds(obj)}
                                                name='Sounds'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Account Code</Label>
                                            <AvInput type='text' name='Account_Code'
                                                value={dataa?.Account_Code} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <AvInput type='text' name='Description'
                                                value={dataa?.Description} />
                                        </FormGroup>

                                    </Col>

                                    <Col sm="3" className="max-width-100 col-3" >
                                        <Label>Schedule</Label>
                                        <FormGroup>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                dateFormat="dd/MM/yyyy h:mm aa"
                                                showTimeSelect
                                                className="form-control"
                                                value={dataa?.Schedule.StartDate}
                                            >
                                                <AvInput type="text" name="StartDate" id="date" placeholder="Select a date" />
                                            </DatePicker>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="2" className="max-width-100 col-3">
                                        <Label></Label>
                                        <FormGroup>
                                            <DatePicker
                                                selected={endDate}
                                                onChange={(date) => setEndDate(date)}
                                                dateFormat="dd/MM/yyyy h:mm aa"
                                                showTimeSelect
                                                className="form-control"
                                                style={{ borderColor: endDate <= startDate ? "red" : null }}
                                                value={dataa?.Schedule.EndDate}
                                            >
                                                <AvInput type="text" name="EndDate" id="date" placeholder="Select a date" />
                                            </DatePicker>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col><div className="col-md-8 ps-md-2 btn-list">
                                <Link to={`/application/conferenceRooms`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' >
                                    Previous
                                </Button></Link>
                            </div></Col>
                            <Col md={2}> <div className="col-md-8 ps-md-9 btn-list" style={{ left: '40%' }}>
                                <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='submit' >
                                    Submit
                                </Button>
                            </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </AvForm>
        </Fragment>
    )
}

AddEditConferenceRoom.propTypes = {};
AddEditConferenceRoom.defaultProps = {};
export default AddEditConferenceRoom;