import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class BridgeApiService extends BaseApiService {

  constructor() {

    super('bridge');
  }
  getcustomer(params) {

  }
 

}

const bridgeApiService = new BridgeApiService();

export default bridgeApiService;
