import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class GetwaysApiService extends BaseApiService {

  constructor() {

    super('getways');
  }
  getcustomer(params) {

  }
  addGetways(data) {
    return this.setHeaderToken().post(apiUrl + "/getways/save", data);
  }

}

const getwaysApiService = new GetwaysApiService();

export default getwaysApiService;
