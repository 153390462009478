import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap'
import "react-data-table-component-extensions/dist/index.css";
import { Archive, MoreVertical, Trash2 } from "react-feather";
import { useNavigate } from 'react-router-dom'

const AgentTable = ({ agents, enableDelete, handleRowsPerPageChange, handleChangePage, totalRows, }) => {
    const [account_id, setAccount_id] = useState(0)
    const [handleDelete, setHandleDelete] = useState(false)
    const [data, setData] = useState([])
    let navigate = useNavigate();

    const OnDeleteAccountItem = (agent_id) => {
        setAccount_id(agent_id);
        setHandleDelete(!handleDelete);
        enableDelete(agent_id)
    };

    const handleEdit = (id) => {
        const object = agents.find(obj => obj.agent_id === id)
        let path = `/application/agents/addEditAgent`;
        navigate(path, { state: { object } })
    }

    const Badge = ({ status }) => {
        let color = status === `Available (on demand) ` ? `#33FF5E` :
            status === `On break` ? `#f0ad4e` :
                status === `Available` ? `#ACFF33` :
                    status === `Logged out` ? `#33F9FF` :
                        status === `Do not diturb` ? `#33F9FF` : null
        return <span style={{ backgroundColor: color }} className="badge">{status}</span>;
    }
    const columns = [
        {
            name: "AGENT NAME",
            selector: row => [row.Agent_Name],
            sortable: true
        },
        {
            name: "TYPE",
            selector: row => [row.Type],
            sortable: true
        },
        {
            name: "CALL TIMEOUT",
            selector: row => [row.Call_Timeout],
            sortable: true,

        },
        {
            name: "AGENT ID",
            selector: row => [row.Agent_Id],
            sortable: true
        },
        {
            name: "CONTACT",
            selector: row => [row.Contact],
            sortable: true
        },
        {
            name: "MAX_NO_ANSWER",
            selector: row => [row.Max_No_Answer],
            sortable: true
        },
        {
            name: " STATUS",
            cell: row => <Badge status={row.Status} />,
            sortable: true
        },
        {
            name: 'Actions',
            minWidth: '100px',
            cell: row => (
                <UncontrolledDropdown>
                    <DropdownToggle tag='div' className='btn btn-sm'>
                        <MoreVertical size={14} className='cursor-pointer' />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => handleEdit(row.agent_id)} className='w-100'>
                            <Archive size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }} >Edit</span>
                        </DropdownItem>
                        <DropdownItem className='w-100' onClick={() => OnDeleteAccountItem(row.agent_id)} >
                            <Trash2 size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }} >Delete</span>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )
        }
    ];
    const tableDatas = {
        columns,
        data: agents
    };

    return (
        <Fragment>
            <DataTableExtensions {...tableDatas} >
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onChangePage={handleChangePage}
                    paginationServer
                    paginationTotalRows={totalRows} />
            </DataTableExtensions>
        </Fragment>
    )
}
export default AgentTable
