import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import {
    Button, Card, CardBody, Col, FormGroup, Label, Row
} from 'reactstrap'
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../data/extensions/alerte_extensions/ErrorAlert";
import { useLocation } from 'react-router-dom';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { Breadcrumb } from "react-bootstrap";
import Select from 'react-select'
import { selectThemeColors } from '../data/Utils'
import { OrderOptions, ActionsOptions, } from "../util/FormDestinations";
import DestinationApiService from "../util/services/DestinationsApiService";
import { ToastContainer } from 'react-toastify';
import InboundsApiService from "../util/services/InboundsApiService";

const AddEditInbound = () => {
    const [initApp, setInitApp] = useState(true);
    const [enabled, setEnabled] = useState('Y');
    const [action, setAction] = useState(null);
    const [order, setOrder] = useState(null);
    const [destination_info, setDestination_info] = useState(null);
    const [destination, setDestination] = useState([]);

    let navigate = useNavigate();
    const location = useLocation();
    const dataa = location?.state?.object;

    const onFinish = (event, values) => {
        event.preventDefault();
        values.Enabled = enabled
        values.Action = action?.value
        values.Order = order?.value
        values.Destinations_id = destination_info?.value
        if (!values.Destinations_id) {
            notifyError("Destination is required");
            return;
        }
        if (location?.state?.object) {
            values.inbound_id = dataa.inbound_id
            InboundsApiService.update(values).then(res => {
                setTimeout(() => {
                    navigate(`/dialplan/inbounds`);
                }, 3000);
                notifySuccess(" Inbound has been updated");
            }).catch(err => {
                notifyError("Error to update Inbound" + err);
            });
        }
        else {
            InboundsApiService.save(values).then(res => {
                setTimeout(() => {
                    navigate(`/dialplan/inbounds`);
                }, 3000);
                notifySuccess("Inbound  has been added")
            }).catch(err => {
                notifyError(" error ");
            });
        } 
    }

    const getAllDestinations = () => {
        DestinationApiService.find().then(res => {
            const DestinationObject = []
            const destinations = res.data.data
            destinations.forEach(element => {
                if (element.Destinations_id !== undefined && element.Destinations_id !== null) {
                    let Destination_data = {
                        value: element.Destinations_id,
                        label: element.Caller_Id_Number
                    }
                    DestinationObject.push(Destination_data)
                    if (dataa && element && dataa.Destinations_id === element.Destinations_id) {
                        setDestination_info(Destination_data);
                    }
                }
            });
            setDestination(DestinationObject);
        });
    };

    useEffect(() => {
        const Order = OrderOptions.find(item => item.value == dataa?.Order)
        setOrder(Order)
        const Action = ActionsOptions.find(item => item.value == dataa?.Action)
        setAction(Action)
        getAllDestinations()

    }, [initApp]);

    const notifySuccess = (message) => SuccessAlert(message)
    const notifyError = (message) => ErrorAlert(message)

    return (
        <Fragment>
            <ToastContainer
            />
            <div className="page-header">
                <div>
                    {dataa ? <h2 className="main-content-title tx-24 mg-b-5"> Edit Inbound</h2> : <h2 className="main-content-title tx-24 mg-b-5"> Add Inbound</h2>}
                    <Breadcrumb >
                        <Link to={`/dashboard`}>
                            <Breadcrumb.Item active>
                                Home /
                            </Breadcrumb.Item></Link>
                        <Link to={`/dialplan/inbounds`}>
                            <Breadcrumb.Item active>
                                Inbound
                            </Breadcrumb.Item></Link>
                    </Breadcrumb>
                </div>
            </div>
            <AvForm onValidSubmit={onFinish}>
                <Row>
                    <Col md='12'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <AvInput type='text' name='Name'
                                                value={dataa?.Name} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Action</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                // defaultValue={dataa ? TypeOptions.find(item => item.value == dataa.Type) :''}
                                                value={action}
                                                options={ActionsOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setAction(obj)}
                                                name='Action'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md='6'>
                                        <FormGroup>
                                            <Label>Limit</Label>
                                            <AvInput type='text' name='Limit'
                                                defaultValue={dataa?.Limit} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Caller Id Number Prefix </Label>
                                            <AvInput type='text' name='Caller_Id_Number_Prefix'
                                                defaultValue={dataa?.Caller_Id_Number_Prefix} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Order</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                //defaultValue={dataa ? ConditionOptions.find(item => item.value == dataa.Conditions) : null}
                                                options={OrderOptions}
                                                value={order}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setOrder(obj)}
                                                name='Order'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        {destination.length > 0 ? (
                                            <FormGroup>
                                                <Label>Destination</Label>
                                                <Select required
                                                    theme={selectThemeColors}
                                                    className='react-select'
                                                    classNamePrefix='select'
                                                    options={destination}
                                                    value={destination_info}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    onChange={(obj) => setDestination_info(obj)}
                                                    name='Destinations_id'

                                                />
                                            </FormGroup>
                                        ) : (
                                            <Link to={`${process.env.PUBLIC_URL}/Destinations/indexDestination`}> <Button >Add Destination</Button></Link>
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <AvInput type='text' name='Description'
                                                value={dataa?.Description} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col><div className="col-md-8 ps-md-2 btn-list">
                                <Link to={`/dialplan/inbounds`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' >
                                    Previous
                                </Button></Link>
                            </div></Col>
                            <Col md={2}> <div className="col-md-8 ps-md-9 btn-list" style={{ left: '40%' }}>
                                <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='submit' >
                                    Submit
                                </Button>
                            </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </AvForm>
        </Fragment>
    )
}

AddEditInbound.propTypes = {};
AddEditInbound.defaultProps = {};
export default AddEditInbound;