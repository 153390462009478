import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class ConferenceRoomsApiService extends BaseApiService {

  constructor() {

    super('ConferenceRooms');
  }
  getcustomer(params) {

  }
 

}

const conferenceRoomsApiService = new ConferenceRoomsApiService();

export default conferenceRoomsApiService;
