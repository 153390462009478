import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup } from "react-bootstrap";
import Multiselect from "react-select";
import {
  RegisterOptions, ProfileOptions, EnabledOptions, DistinctToOptions, RegisterTransportOptions, CallerIdOptions,
  SupressCngOptions, ExtensionInOptions, ContactOptions
} from "../../util/FormAdvancedGetw";
import GetwaysApiService from "../../util/services/GetwaysApiService";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../../data/extensions/alerte_extensions/ErrorAlert";
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const AddEditGetways = () => {
  const [initApp, setInitApp] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [validateForm, setValidateForm] = useState({});
  const [check, setCheck] = useState(null);
  const [showForm, setShowForm] = useState(false);

  let navigate = useNavigate();
  const location = useLocation();
  const dataa = location?.state?.objec;

  const onFinish = (event) => {
    event.preventDefault();
    if (location?.state?.objec) {
      formValues.id = location?.state?.objec
      GetwaysApiService.update(formValues).then(res => {
        setTimeout(() => {
          navigate(`/accounts/getways`);
        }, 3000);
        notifySuccess("Getway has been updated");
      }).catch(err => {
        notifyError("Error to update Getway" + err);
      });
    } else {
      GetwaysApiService.addGetways(formValues).then(res => {
        setTimeout(() => {
          navigate(`/accounts/getways`);
        }, 3000);
        notifySuccess("Getway has been added")
      }).catch(err => {
        notifyError(" error ");
      });
    }
  }

  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
    setValidateForm({ ...validateForm, [event.target.name]: true });
  }

  const handleChangeAdvanced = (event) => {
    setFormValues({ ...formValues, Advanced: { ...formValues.Advanced, [event.target.name]: event.target.value } });
    setValidateForm({ ...formValues, Advanced: { ...formValues.Advanced, [event.target.name]: true } });
  }

  const handleSelectChange = (selectedOption, { name }) => {
    setFormValues({ ...formValues, [name]: selectedOption.value });
    setValidateForm({ ...validateForm, [name]: true });
  }
  const handleSelectChangeAdvanced = (selectedOption, { name }) => {
    setFormValues({ ...formValues, Advanced: { ...formValues.Advanced, [name]: selectedOption.value } });
    setValidateForm({ ...formValues, Advanced: { ...formValues.Advanced, [name]: true } });
  }

  useEffect(() => {
    if (location?.state?.objec) {
      setFormValues(location.state.objec)
    }
  }, [initApp]);

  const notifySuccess = (message) => SuccessAlert(message)
  const notifyError = (message) => ErrorAlert(message)

  return (
    <Fragment>
      <ToastContainer
      />
      <div className="page-header">
        <div>
          {dataa ? <h2 className="main-content-title tx-24 mg-b-5"> Edit Getway</h2> : <h2 className="main-content-title tx-24 mg-b-5"> Add Getway</h2>}
          <Breadcrumb >
            <Link to={`/dashboard`}>
              <Breadcrumb.Item active>
                Home /
              </Breadcrumb.Item></Link>
            <Link to={`/accounts/getways`}>
              <Breadcrumb.Item active>
                Getways
              </Breadcrumb.Item></Link>
          </Breadcrumb>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              variant="white"
              type="button"
              className="btn-icon-text my-2 me-2"
            >
              <i className="typcn typcn-plus"></i> ADD
            </Button>
          </div>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12} md={12}>
          <Card className="custom-card">
            <Card.Body>
              <div>
                <h6 className="main-content-label mb-1">Getway</h6>
                <p className="text-muted card-sub-title">
                  A form control with success, warning and error state.
                </p>
              </div>
              <form>
                <Row className="row-sm">
                  <Col md={6} >
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Username</p>
                      <div className="input-group">
                        <Form.Control
                          type="text" name="Username" placeholder="Name" defaultValue={dataa?.Username} onChange={handleChange} required />
                      </div>
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Getway</p>
                      <Form.Control
                        className={check != null && !check.Email ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text" name="Getways" placeholder="Getway" defaultValue={dataa?.Getways} onChange={handleChange} required
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Password</p>
                      <Form.Control
                        className={check != null && !check.Email ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text" name="Password" placeholder="Password" defaultValue={dataa?.Password} onChange={handleChange} required
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">From User</p>
                      <Form.Control
                        className={check != null && !check.Email ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text" name="From_User" placeholder="Getway" defaultValue={dataa?.From_User} onChange={handleChange} required
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">From Domain</p>
                      <Form.Control
                        className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="From_Domain"
                        placeholder="From Domain"
                        onChange={handleChange}
                        defaultValue={dataa?.From_Domain}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Proxy</p>
                      <Form.Control
                        className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Proxy"
                        placeholder="Proxy"
                        onChange={handleChange}
                        defaultValue={dataa?.Proxy}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Realm</p>
                      <Form.Control
                        className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Realm"
                        placeholder="Realm"
                        onChange={handleChange}
                        defaultValue={dataa?.Realm}
                      />
                    </Form.Group>

                  </Col>


                  <Col md={6}>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Expire Second</p>
                      <Form.Control
                        className={check != null && !check.Password ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Expire_Second"
                        placeholder="Expire Second"
                        onChange={handleChange}
                        defaultValue={dataa?.Expire_Second}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Register</p>
                      <Multiselect classNamePrefix="Select2"
                        name="Register" singleSelect displayValue="key"
                        options={RegisterOptions}
                        onChange={handleSelectChange}
                        defaultValue={RegisterOptions.find(lang => lang.value == dataa?.Register)} />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Retry Second</p>
                      <Form.Control
                        className={check != null && !check.First_name ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Retry_Second"
                        placeholder="Retry Second"
                        onChange={handleChange}
                        defaultValue={dataa?.Retry_Second}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Context</p>
                      <Form.Control
                        className={check != null && !check.First_name ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Context"
                        placeholder="First Name"
                        onChange={handleChange}
                        defaultValue={dataa?.Context}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Profile</p>
                      <Multiselect
                        name="Profile"
                        singleSelect displayValue="key"
                        options={ProfileOptions}
                        onChange={handleSelectChange}
                        defaultValue={ProfileOptions.find(lang => lang.value == dataa?.Profile)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Enabled</p>
                      <Multiselect
                        name="Enabled"
                        singleSelect displayValue="key"
                        options={EnabledOptions}
                        onChange={handleSelectChange}
                        defaultValue={EnabledOptions.find(lang => lang.value == dataa?.Enabled)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Description</p>
                      <Form.Control
                        className={check != null && !check.Organization ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Description"
                        placeholder="Description"
                        onChange={handleChange}
                        defaultValue={dataa?.Description}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col >
                    <div className="col-md-8 ps-md-2 btn-list" style={{ marginBottom: "10px" }}>
                      <Button className="btn ripple btn-secondary pd-x-30 mg-r-5" type='button'
                        onClick={() => setShowForm(!showForm)}
                      >
                        <FontAwesomeIcon icon={showForm ? faMinus : faPlus} />
                      </Button>
                    </div>
                  </Col>
                </Row>
                {showForm && (
                  <Row>
                    <Col>
                      <form>
                        <Row className="row-sm">
                          <Col md={6} >

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Distinct To </p>
                              <Multiselect
                                name="Distinct_To"
                                singleSelect displayValue="key"
                                options={DistinctToOptions}
                                onChange={handleSelectChangeAdvanced}
                                defaultValue={DistinctToOptions.find(lang => lang.value == dataa?.Advanced.DistinctToOptions)}

                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Auth Username</p>
                              <Form.Control
                                className={check != null && !check.Auth_Username ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text" name="Auth_Username" placeholder="Auth Username" defaultValue={dataa?.Advanced.Auth_Username} onChange={handleChangeAdvanced} required
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Extension</p>
                              <Form.Control
                                className={check != null && !check.Email ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text" name="Extension" placeholder="Extension" defaultValue={dataa?.Advanced.Extension} onChange={handleChangeAdvanced} required
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Register Transport </p>
                              <Multiselect
                                name="Register_Transport"
                                singleSelect displayValue="key"
                                options={RegisterTransportOptions}
                                onChange={handleSelectChangeAdvanced}
                                defaultValue={RegisterTransportOptions.find(lang => lang.value == dataa?.Advanced.Register_Transport)}
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Register Proxy</p>
                              <Form.Control
                                className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text"
                                name="Register_Proxy"
                                placeholder="Register Proxy"
                                onChange={handleChangeAdvanced}
                                defaultValue={dataa?.Advanced.Register_Proxy}
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Outbound Proxy</p>
                              <Form.Control
                                className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text"
                                name="Outbound_Proxy"
                                placeholder="Outbound Proxy"
                                onChange={handleChangeAdvanced}
                                defaultValue={dataa?.Advanced.Outbound_Proxy}
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Caller Id In Frorm </p>
                              <Multiselect classNamePrefix="Select2"
                                name="Caller_Id_In_Frorm"
                                singleSelect displayValue="key"
                                options={CallerIdOptions}
                                onChange={handleSelectChangeAdvanced}
                                defaultValue={CallerIdOptions.find(lang => lang.value == dataa?.Advanced.Caller_Id_In_Frorm)}
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Hostname</p>
                              <Form.Control
                                className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text"
                                name="Hostname"
                                placeholder="Hostname"
                                onChange={handleChangeAdvanced}
                                defaultValue={dataa?.Advanced.Hostname}

                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Domain</p>
                              <Form.Control
                                className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text"
                                name="Domain"
                                placeholder="Domain"
                                onChange={handleChangeAdvanced}
                                defaultValue={dataa?.Advanced.Domain}
                              />
                            </Form.Group>



                          </Col>


                          <Col md={6}>
                            <Form.Group className="form-group">
                              <p className="mg-b-10">Supress CNG </p>
                              <Multiselect classNamePrefix="Select2"
                                name="Supress_CNG"
                                singleSelect displayValue="key"
                                options={SupressCngOptions}
                                onChange={handleSelectChangeAdvanced}
                                defaultValue={SupressCngOptions.find(lang => lang.value == dataa?.Advanced.Supress_CNG)} />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Sip Cid Type</p>
                              <Form.Control
                                className={check != null && !check.First_name ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text"
                                name="Sip_Cid_Type"
                                placeholder="Sip Cid Type"
                                onChange={handleChangeAdvanced}
                                defaultValue={dataa?.Advanced.Sip_Cid_Type}

                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Codec Preferences</p>
                              <Form.Control
                                className={check != null && !check.First_name ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text"
                                name="Codec_Preferences"
                                placeholder="Codec Preferences"
                                onChange={handleChangeAdvanced}
                                defaultValue={dataa?.Advanced.Codec_Preferences}
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Extension In contact </p>
                              <Multiselect classNamePrefix="Select2"
                                name="Extension_In_contact"
                                singleSelect displayValue="key"
                                options={ExtensionInOptions}
                                onChange={handleSelectChangeAdvanced}
                                defaultValue={ExtensionInOptions.find(lang => lang.value == dataa?.Advanced.Extension_In_contact)} />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Ping </p>
                              <Form.Control
                                className={check != null && !check.Organization ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text"
                                name="Ping"
                                placeholder="Organization"
                                onChange={handleChangeAdvanced}
                                defaultValue={dataa?.Advanced.Ping}
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Ping Min</p>
                              <Form.Control
                                className={check != null && !check.Organization ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text"
                                name="Ping_Min"
                                placeholder="Organization"
                                onChange={handleChangeAdvanced}
                                defaultValue={dataa?.Advanced.Ping_Min}
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Ping Max</p>
                              <Form.Control
                                className={check != null && !check.Organization ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text"
                                name="Ping_Max"
                                placeholder="Organization"
                                onChange={handleChangeAdvanced}
                                defaultValue={dataa?.Advanced.Ping_Max}
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Contact In Ping</p>
                              <Multiselect
                                name="Contact_In_Ping"
                                singleSelect displayValue="key"
                                options={ContactOptions}
                                onChange={handleSelectChangeAdvanced}
                                defaultValue={ContactOptions.find(lang => lang.value == dataa?.Advanced.Contact_In_Ping)}

                              />
                            </Form.Group>


                            <Form.Group className="form-group">
                              <p className="mg-b-10">Channels</p>
                              <Form.Control
                                className={check != null && !check.Organization ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text"
                                name="Channels"
                                placeholder="Organization"
                                onChange={handleChangeAdvanced}
                                defaultValue={dataa?.Advanced.Channels}
                              />
                            </Form.Group>


                          </Col>

                        </Row>
                      </form>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col><div className="col-md-8 ps-md-2 btn-list">
                    <Link to={`/accounts/getways`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' >
                      Previous
                    </Button></Link>
                  </div></Col>
                  <Col md={2}> <div className="col-md-8 ps-md-9 btn-list" style={{ left: '40%' }}>
                    {dataa ? <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' onClick={onFinish}>
                      update
                    </Button> : <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' onClick={onFinish}>
                      Submit
                    </Button>}
                  </div>
                  </Col>
                </Row>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}


AddEditGetways.propTypes = {};

AddEditGetways.defaultProps = {};

export default AddEditGetways;