import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import {
    Button, Card, CardBody, Col, FormGroup, Label, Row
} from 'reactstrap'
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../data/extensions/alerte_extensions/ErrorAlert";
import { useLocation } from 'react-router-dom';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { Breadcrumb } from "react-bootstrap";
import Select from 'react-select'
import { selectThemeColors } from '../data/Utils'
import { DirectionOptions, ExtensionOptions, ActionOptions } from "../util/FormAdvancedCallBlock";
import { ToastContainer } from 'react-toastify';
import CallBlockApiService from "../util/services/CallBlockApiService";

const AddEditCallBlock = () => {
    const [initApp, setInitApp] = useState(true);
    const [enabled, setEnabled] = useState('Y');
    const [direction, setDirection] = useState(null);
    const [extension, setExtension] = useState(null);
    const [action, setAction] = useState(null);

    let navigate = useNavigate();
    const location = useLocation();
    const dataa = location?.state?.object;

    const onFinish = (event, values) => {
   
        event.preventDefault();
        values.Enabled = enabled
        values.Direction = direction?.value
        values.Extension = extension?.value
        values.Number = {
            country_code: values.country_code,
            caller_id_number: values.caller_id_number
        }
        values.Action = action?.value
        if (location?.state?.object) {
            values.callBlok_id = dataa.callBlok_id
            CallBlockApiService.update(values).then(res => {
                setTimeout(() => {
                    navigate(`/application/callBlocks`);
                }, 3000);
                notifySuccess(" Call Block has been updated");
            }).catch(err => {
                notifyError("Error to update Call Block " + err);
            });
        }
        else {
            CallBlockApiService.save(values).then(res => {
                setTimeout(() => {
                    navigate(`/application/callBlocks`);
                }, 3000);
                notifySuccess("Call Block  has been added")
            }).catch(err => {
                notifyError("error ");
            });
        }
    }

    useEffect(() => {
        const Direction = DirectionOptions.find(item => item.value == dataa?.Direction)
        setDirection(Direction)
        const Extension = ExtensionOptions.find(item => item.value == dataa?.Extension)
        setExtension(Extension)
        const Action = ActionOptions.find(item => item.value == dataa?.Action)
        setAction(Action)
    }, [initApp]);

    const notifySuccess = (message) => SuccessAlert(message)
    const notifyError = (message) => ErrorAlert(message)

    return (
        <Fragment>
            <ToastContainer
            />
            <div className="page-header">
                <div>
                    {dataa ? <h2 className="main-content-title tx-24 mg-b-5"> Edit Call Block</h2> : <h2 className="main-content-title tx-24 mg-b-5"> Add Call Block </h2>}
                    <Breadcrumb >
                        <Link to={`/dashboard`}>
                            <Breadcrumb.Item active>
                                Home /
                            </Breadcrumb.Item></Link>
                        <Link to={`/application/callBlocks`}>
                            <Breadcrumb.Item active>
                                call Blocks
                            </Breadcrumb.Item></Link>
                    </Breadcrumb>
                </div>
            </div>
            <AvForm onValidSubmit={onFinish}>
                <Row>
                    <Col md='12'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Direction</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={direction}
                                                options={DirectionOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setDirection(obj)}
                                                name='Direction'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Extension</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={extension}
                                                options={ExtensionOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setExtension(obj)}
                                                name='Extension'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <AvInput type='text' name='Name'
                                                value={dataa?.Name} />
                                        </FormGroup>
                                    </Col>

                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Number</Label>
                                            <AvInput type='text' name='country_code'
                                                value={dataa?.Number?.country_code} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label ></Label>
                                            <AvInput type='text' name='caller_id_number'
                                                value={dataa?.Number?.caller_id_number} />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Action</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={action}
                                                options={ActionOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setAction(obj)}
                                                name='Action'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <AvInput type='text' name='Description'
                                                value={dataa?.Description} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col><div className="col-md-8 ps-md-2 btn-list">
                                <Link to={`/application/callBlocks`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' >
                                    Previous
                                </Button></Link>
                            </div></Col>
                            <Col md={2}> <div className="col-md-8 ps-md-9 btn-list" style={{ left: '40%' }}>
                                <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='submit' >
                                    Submit
                                </Button>
                            </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </AvForm>
        </Fragment>
    )
}

AddEditCallBlock.propTypes = {};
AddEditCallBlock.defaultProps = {};
export default AddEditCallBlock;