import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap'
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../data/extensions/alerte_extensions/ErrorAlert";
import { useLocation } from 'react-router-dom';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { Breadcrumb } from "react-bootstrap";
import Select from 'react-select'
import { selectThemeColors } from '../data/Utils'
import { GreetingOptions } from "../util/FormAdvancedCallFlow";
import { ToastContainer } from 'react-toastify';
import ConferenceCenterApiService from "../util/services/ConferenceCenterApiService";

const AddEditConferenceCenter = () => {
    const [initApp, setInitApp] = useState(true);
    const [enabled, setEnabled] = useState('Y');
    const [greeting, setGreeting] = useState(null);

    let navigate = useNavigate();
    const location = useLocation();
    const dataa = location?.state?.object;

    const onFinish = (event, values) => {
        event.preventDefault();
        values.Enabled = enabled
        values.Greeting = greeting?.value
        if (location?.state?.object) {
            values.ConCenter_id = dataa.ConCenter_id
            ConferenceCenterApiService.update(values).then(res => {
                setTimeout(() => {
                    navigate(`/application/conferenceCenters`);
                }, 3000);
                notifySuccess(" Conference center has been updated");
            }).catch(err => {
                notifyError("Error to update Conference center " + err);
            });
        }
        else {
            ConferenceCenterApiService.save(values).then(res => {
                setTimeout(() => {
                    navigate(`/application/conferenceCenters`);
                }, 3000);
                notifySuccess("Conference center  has been added")
            }).catch(err => {
                notifyError("error ");
            });
        }
    }

    useEffect(() => {
        const Greeting = GreetingOptions.find(item => item.value == dataa?.Greeting)
        setGreeting(Greeting)
    }, [initApp]);

    const notifySuccess = (message) => SuccessAlert(message)
    const notifyError = (message) => ErrorAlert(message)

    return (
        <Fragment>
            <ToastContainer
            />
            <div className="page-header">
                <div>
                    {dataa ? <h2 className="main-content-title tx-24 mg-b-5"> Edit Conference Center</h2> : <h2 className="main-content-title tx-24 mg-b-5"> Add Conference Center </h2>}
                    <Breadcrumb >
                        <Link to={`/dashboard`}>
                            <Breadcrumb.Item active>
                                Home /
                            </Breadcrumb.Item></Link>
                        <Link to={`/application/conferenceCenters`}>
                            <Breadcrumb.Item active>
                                Conference Centers
                            </Breadcrumb.Item></Link>
                    </Breadcrumb>
                </div>
            </div>
            <AvForm onValidSubmit={onFinish}>
                <Row>
                    <Col md='12'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <AvInput type='text' name='Name'
                                                value={dataa?.Name} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Extension</Label>
                                            <AvInput type='text' name='Extension'
                                                value={dataa?.Extension} />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>PIN Length	</Label>
                                            <AvInput type='text' name='PIN_Length'
                                                value={dataa?.PIN_Length} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Greeting</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={greeting}
                                                options={GreetingOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setGreeting(obj)}
                                                name='Greeting'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <AvInput type='text' name='Description'
                                                value={dataa?.Description} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col><div className="col-md-8 ps-md-2 btn-list">
                                <Link to={`/application/conferenceCenters`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' >
                                    Previous
                                </Button></Link>
                            </div></Col>
                            <Col md={2}> <div className="col-md-8 ps-md-9 btn-list" style={{ left: '40%' }}>
                                <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='submit' >
                                    Submit
                                </Button>
                            </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </AvForm>
        </Fragment>
    )
}

AddEditConferenceCenter.propTypes = {};
AddEditConferenceCenter.defaultProps = {};
export default AddEditConferenceCenter;