import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap'
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../data/extensions/alerte_extensions/ErrorAlert";
import { useLocation } from 'react-router-dom';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { Breadcrumb } from "react-bootstrap";
import Select from 'react-select'
import { selectThemeColors } from '../data/Utils'
import {
    TypeOtherOptions, LabelNumberOptions, PrimaryNumberOptions, TypeAdresseOptions,
    LabelAdressOptions, PrimaryAdresseOptions, PrimaryEmailOptions, LabelURLOptions,
    PrimaryURLOptions, RelationOptions, OrderOptions, EnabledOptions
} from "../util/FormContact";
import { ToastContainer } from 'react-toastify';
import ContactApiService from "../util/services/ContactApiService";
import TimezoneSelect from 'react-timezone-select';

const AddEditContact = () => {
    const [initApp, setInitApp] = useState(true);
    const [type, setType] = useState(null);
    const [time_Zone, setTime_Zone] = useState('');
    const [label, setLabel] = useState(null);
    const [primary, setPrimary] = useState(null);
    const [voice, setVoice] = useState(false);
    const [fax, setFax] = useState(false);
    const [text, setText] = useState(false);
    const [video, setVideo] = useState(false);
    const [typeAdress, setTypeAdress] = useState(null);
    const [labelAdress, setLabelAdress] = useState(null);
    const [primaryAdress, setPrimaryAdress] = useState(null);
    const [primaryEmail, setPrimaryEmail] = useState(null);
    const [labelURL, setLabelUrl] = useState(null);
    const [primaryUrl, setPrimaryUrl] = useState(null);
    const [relation, setRelation] = useState(null);
    const [contact, setContact] = useState(null);
    const [contact_info, setContact_info] = useState(null);
    const [order, setOrder] = useState(null);
    const [enabled, setEnabled] = useState(null);

    let navigate = useNavigate();
    const location = useLocation();
    const dataa = location?.state?.object;

    const onFinish = (event, values) => {
        event.preventDefault();
        values.Name = {
            Organization: values.Organization,
            Prefix: values.Prefix,
            First_name: values.First_name,
            Middle: values.Middle,
            Last_Name: values.Last_Name,
            Suffix: values.Suffix,
            Nickname: values.Nickname
        }
        values.Other = {
            Type: type?.value,
            Title: values.Title,
            Role: values.Role,
            Category: values.Category,
            Time_Zone: time_Zone?.value,
            Note: values.Note
        }
        values.Numbers = {
            Label: label?.value,
            Type: {
                Voice: voice,
                Fax: fax,
                Text: text,
                Video: video
            },
            Speed_Dial: values.Speed_Dial,
            Country_Code: values.Country_Code,
            Number: values.Number,
            Extension: values.Extension,
            Primary: primary?.value,
            Description: values.Description
        }
        values.Adresses = {
            Type_Adress: typeAdress?.value,
            Label_Adress: labelAdress?.value,
            Adress: {
                Adresse_1: values?.Adresse_1,
                Adresse_2: values?.Adresse_2
            },
            City: values.City,
            Region: values.Region,
            Postal_code: values.Postal_code,
            Country: values.Country,
            Primary_Adress: primaryAdress?.value,
            Description: values.Description
        }
        values.Emails = {
            Label_Email: values.Label_Email,
            Adress: values.Adress,
            Primary_Email: primaryEmail?.value,
            Description: values.Description
        }
        values.Url = {
            Label_URL: labelURL?.value,
            Adress_Url: values.Adress_Url,
            Primary_Url: primaryUrl?.value,
            Description: values.Description
        }
        values.Relation = {
            Relation: relation?.value,
            Contact: contact_info?.value,
        }
        values.Settings = {
            Category: values.Category,
            Subcategory: values.Subcategory,
            Name_settings: values.Name_settings,
            Order: order?.value,
            Enabled: enabled?.value

        }
        if (!values.Name.Organization) {
            notifyError("Name is required");
            return;
        }
        if (location?.state?.object) {
            values.contact_id = dataa.contact_id
            ContactApiService.update(values).then(res => {

                setTimeout(() => {
                    navigate(`/application/contacts`);
                }, 3000);
                notifySuccess(" contacts has been updated");
            }).catch(err => {
                notifyError("Error to update Conference room " + err);
            });
        }
        else {
            ContactApiService.save(values).then(res => {
                setTimeout(() => {
                    navigate(`/application/contacts`);
                }, 3000);
                notifySuccess("contacts has been added")
            }).catch(err => {
                notifyError("error ");
            });
        }
    }
    const getAllContact = () => {
        ContactApiService.find().then(res => {
            const ContactObject = []
            const contacts = res.data.data
            contacts.forEach(element => {
                if (element.contact_id !== undefined && element.contact_id !== null) {
                    let contact_data = {
                        value: element.contact_id,
                        label: element.Name.Organization
                    }
                    ContactObject.push(contact_data)
                    if (dataa && element && dataa.contact_id === element.contact_id) {
                        setContact_info(contact_data);
                    }
                }
            });
            setContact(ContactObject);
        });
    };

    useEffect(() => {
        const TypeOther = TypeOtherOptions.find(item => item.value == dataa?.Other.Type)
        setType(TypeOther)
        const LabelNumber = LabelNumberOptions.find(item => item.value == dataa?.Numbers.Label)
        setLabel(LabelNumber)
        const PrimaryNumber = PrimaryNumberOptions.find(item => item.value == dataa?.Numbers.Primary)
        setPrimary(PrimaryNumber)
        if (dataa?.Numbers?.Type?.Voice == true) {
            setVoice(true)
        }
        if (dataa?.Numbers?.Type?.Fax == true) {
            setFax(true)
        }
        if (dataa?.Numbers?.Type?.Text == true) {
            setText(true)
        }
        if (dataa?.Numbers?.Type?.Video == true) {
            setVideo(true)
        }
        const TypeAdress = TypeAdresseOptions.find(item => item.value == dataa?.Adresses.Type_Adress)
        setTypeAdress(TypeAdress)
        const LabelAdress = LabelAdressOptions.find(item => item.value == dataa?.Adresses.Label_Adress)
        setLabelAdress(LabelAdress)
        const PrimaryAdress = PrimaryAdresseOptions.find(item => item.value == dataa?.Adresses.Primary_Adress)
        setPrimaryAdress(PrimaryAdress)
        const PrimaryEmail = PrimaryEmailOptions.find(item => item.value == dataa?.Emails.Primary_Email)
        setPrimaryEmail(PrimaryEmail)
        const LabelUrl = LabelURLOptions.find(item => item.value == dataa?.Url.Label_URL)
        setLabelUrl(LabelUrl)
        const PrimaryUrl = PrimaryURLOptions.find(item => item.value == dataa?.Url.Primary_Url)
        setPrimaryUrl(PrimaryUrl)
        const Relation = RelationOptions.find(item => item.value == dataa?.Relation.Relation)
        setRelation(Relation)

        getAllContact()
    }, [initApp]);

    const notifySuccess = (message) => SuccessAlert(message)
    const notifyError = (message) => ErrorAlert(message)

    return (
        <Fragment>
            <ToastContainer
            />
            <div className="page-header">
                <div>
                    {dataa ? <h2 className="main-content-title tx-24 mg-b-5"> Edit Contact</h2> : <h2 className="main-content-title tx-24 mg-b-5"> Add Contact </h2>}
                    <Breadcrumb >
                        <Link to={`/dashboard`}>
                            <Breadcrumb.Item active>
                                Home /
                            </Breadcrumb.Item></Link>
                        <Link to={`/application/contacts`}>
                            <Breadcrumb.Item active>
                                Contacts
                            </Breadcrumb.Item></Link>
                    </Breadcrumb>
                </div>

            </div>
            <AvForm onValidSubmit={onFinish}>
                <Row>
                    <Col md='12'>
                        <Card>
                            <CardBody>

                                <Row>
                                    <Col md='4'>
                                        <Label md='3' className='col-form-label-lg'>Name</Label>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Organization</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Organization' value={dataa?.Name.Organization} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Prefix</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Prefix' value={dataa?.Name.Prefix} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>First_Name</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='First_name' value={dataa?.Name.First_name} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Middle</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Middle' value={dataa?.Name.Middle} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Last_Name</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Last_Name' value={dataa?.Name.Last_Name} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Suffix</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Suffix' value={dataa?.Name.Suffix} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Nickname</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Nickname' value={dataa?.Name.Nickname} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                    </Col>
                                    <Col md='4' >
                                        <Label md='3' className='col-form-label-lg'>Other</Label>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Type</Label>
                                                <Col md='9'>
                                                    <Select
                                                        theme={selectThemeColors}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        value={type}
                                                        options={TypeOtherOptions}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        onChange={(obj) => setType(obj)}
                                                        name='Type'
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Title</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Title' value={dataa?.Other.Title} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Role</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Role' value={dataa?.Other.Role} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Category</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Category' value={dataa?.Other.Category} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Time Zone</Label>
                                                <Col md='9'>
                                                    <TimezoneSelect
                                                        value={time_Zone}
                                                        onChange={(obj) => setTime_Zone(obj)}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        name="Time_Zone"
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Note</Label>
                                                <Col md='9'>
                                                    <AvInput type='textarea' name='Note' value={dataa?.Other.Note} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                    </Col>
                                    <Col md='4'>
                                        <Label md='3' className='col-form-label-lg'>Numbers</Label>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Label</Label>
                                                <Col md='9'>
                                                    <Select
                                                        theme={selectThemeColors}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        value={label}
                                                        options={LabelNumberOptions}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        onChange={(obj) => setLabel(obj)}
                                                        name='Label'
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Type</Label>
                                                <Col md='9'>
                                                    <div className="d-flex">
                                                        <div className="col-2">
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <AvInput type="checkbox" name="Voice" checked={voice} onChange={(obj) => setVoice(!voice)} />
                                                                    Voice
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-2">
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <AvInput type="checkbox" name="Fax" checked={fax} onChange={(obj) => setFax(!fax)} />
                                                                    Fax
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-2">
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <AvInput type="checkbox" name="Text" checked={text} onChange={(obj) => setText(!text)} />
                                                                    Text
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-2">
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <AvInput type="checkbox" name="Video" checked={video} onChange={(obj) => setVideo(!video)} />
                                                                    Video
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Speed Dial</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Speed_Dial' value={dataa?.Numbers.Speed_Dial} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Country Code</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Country_Code' value={dataa?.Numbers.Country_Code} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Number</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Number' value={dataa?.Numbers.Number} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Extension</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Extension' value={dataa?.Numbers.Extension} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Primary</Label>
                                                <Col md='9'>
                                                    <Select
                                                        theme={selectThemeColors}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        value={primary}
                                                        options={PrimaryNumberOptions}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        onChange={(obj) => setPrimary(obj)}
                                                        name='Primary'
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Description</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Description' value={dataa?.Numbers.Description} />
                                                </Col>
                                            </FormGroup>
                                        </Row>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='4'>
                                        <Label md='3' className='col-form-label-lg'>Addresses</Label>
                                        <Row >
                                            <FormGroup row>
                                                <Label md='3'>Type</Label>
                                                <Col md='9'>
                                                    <Select
                                                        theme={selectThemeColors}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        value={typeAdress}
                                                        options={TypeAdresseOptions}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        onChange={(obj) => setTypeAdress(obj)}
                                                        name='Type_Adress'
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Label</Label>
                                                <Col md='9'>
                                                    <Select
                                                        theme={selectThemeColors}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        value={labelAdress}
                                                        options={LabelAdressOptions}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        onChange={(obj) => setLabelAdress(obj)}
                                                        name='Label_Adress'
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Adress</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' placeholder='Adresse_1' name='Adresse_1' value={dataa?.Adresses?.Adresse_1} />
                                                    <AvInput type='text' placeholder='Adresse_2' name='Adresse_2' value={dataa?.Adresses?.Adresse_2} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>City</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' placeholder='City' name='City' value={dataa?.Adresses.City} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Region</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' placeholder='Region' name='Region' value={dataa?.Adresses.Region} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Postal code</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' placeholder='Postal code' name='Postal_code' value={dataa?.Adresses.Postal_code} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Country</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' placeholder='Country' name='Country' value={dataa?.Adresses.Country} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Primary</Label>
                                                <Col md='9'>
                                                    <Select
                                                        theme={selectThemeColors}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        value={primaryAdress}
                                                        options={PrimaryAdresseOptions}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        onChange={(obj) => setPrimaryAdress(obj)}
                                                        name='Primary_Adress'
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Description</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' placeholder='Description' name='Description' value={dataa?.Adresses.Description} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                    </Col>
                                    <Col md='4'>
                                        <Label md='3' className='col-form-label-lg'>Emails</Label>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Label</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Label_Email' value={dataa?.Emails.Label_Email} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Adress</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Adress' value={dataa?.Emails.Adress} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Primary</Label>
                                                <Col md='9'>
                                                    <Select
                                                        theme={selectThemeColors}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        value={primaryEmail}
                                                        options={PrimaryEmailOptions}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        onChange={(obj) => setPrimaryEmail(obj)}
                                                        name='Primary_Email'
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Description</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Description' value={dataa?.Emails.Description} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <Label md='3' className='col-form-label-lg'>Settings</Label>
                                            <Row>
                                                <FormGroup row>
                                                    <Label md='3'>Category</Label>
                                                    <Col md='9'>
                                                        <AvInput type='text' name='Category' value={dataa?.Settings.Category} />
                                                    </Col>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup row>
                                                    <Label md='3'>Subcategory</Label>
                                                    <Col md='9'>
                                                        <AvInput type='text' name='Subcategory' value={dataa?.Settings.Subcategory} />
                                                    </Col>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup row>
                                                    <Label md='3'>Name</Label>
                                                    <Col md='9'>
                                                        <AvInput type='text' name='Name_settings' value={dataa?.Settings.Name_settings} />
                                                    </Col>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup row>
                                                    <Label md='3'>Order</Label>
                                                    <Col md='9'>
                                                        <Select
                                                            theme={selectThemeColors}
                                                            className='react-select'
                                                            classNamePrefix='select'
                                                            value={order}
                                                            options={OrderOptions}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            onChange={(obj) => setOrder(obj)}
                                                            name='Order'
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup row>
                                                    <Label md='3'>Enabled</Label>
                                                    <Col md='9'>
                                                        <Select
                                                            theme={selectThemeColors}
                                                            className='react-select'
                                                            classNamePrefix='select'
                                                            value={enabled}
                                                            options={EnabledOptions}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            onChange={(obj) => setEnabled(obj)}
                                                            name='Enabled'
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Label md='3' className='col-form-label-lg'>URL</Label>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Label</Label>
                                                <Col md='9'>
                                                    <Select
                                                        theme={selectThemeColors}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        value={labelURL}
                                                        options={LabelURLOptions}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        onChange={(obj) => setLabelUrl(obj)}
                                                        name='Label_URL'
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Adress</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Adress_Url' value={dataa?.Url.Adress_Url} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Primary</Label>
                                                <Col md='9'>
                                                    <Select
                                                        theme={selectThemeColors}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        value={primaryUrl}
                                                        options={PrimaryURLOptions}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        onChange={(obj) => setPrimaryUrl(obj)}
                                                        name='Primary_Url'
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Description</Label>
                                                <Col md='9'>
                                                    <AvInput type='text' name='Description' value={dataa?.Url.Description} />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='4'>
                                        <Label md='3' className='col-form-label-lg'>Relation</Label>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Relation</Label>
                                                <Col md='9'>
                                                    <Select
                                                        theme={selectThemeColors}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        value={relation}
                                                        options={RelationOptions}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        onChange={(obj) => setRelation(obj)}
                                                        name='Relation'
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup row>
                                                <Label md='3'>Contact</Label>
                                                <Col md='9'>
                                                    <Select
                                                        theme={selectThemeColors}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        value={contact_info}
                                                        options={contact}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        onChange={(obj) => setContact_info(obj)}
                                                        name='Contact'
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col><div className="col-md-8 ps-md-2 btn-list">
                                <Link to={`/application/conferenceRooms`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' >
                                    Previous
                                </Button></Link>
                            </div></Col>
                            <Col md={2}> <div className="col-md-8 ps-md-9 btn-list" style={{ left: '40%' }}>
                                <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='submit' >
                                    Submit
                                </Button>
                            </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </AvForm>
        </Fragment>
    )
}

AddEditContact.propTypes = {};
AddEditContact.defaultProps = {};
export default AddEditContact;