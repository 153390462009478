import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumb, Button, Card, Col, Row, Form } from "react-bootstrap";
import Multiselect from "react-select";
import { languageOptions, StatusOptions, DomainsOptions, EnabledOptions, TimeZoneOptions, GroupsOptions } from "../../util/FormAdvanced";
import accountsApiService from "../../util/services/AccountsApiService";
import { Link, useNavigate } from 'react-router-dom';
import AlertRefresh from "../../data/common/AlertRefresh";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../../data/extensions/alerte_extensions/ErrorAlert";
import { useLocation } from 'react-router-dom';


const AddEditUser = () => {
  const [initApp, setInitApp] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [validateForm, setValidateForm] = useState({});
  const [check, setCheck] = useState(null);
  const [randomNumber, setRandomNumber] = useState();
  const [handleRefrech, setHandleRefrech] = useState(false)
  const [handleRequired, setHandleRequired] = useState(false)

  let navigate = useNavigate();
  const location = useLocation();
  const dataa = location?.state?.objec;

  const onFinish = (event) => {
    event.preventDefault();
    setCheck(formValues)
    if (location?.state?.objec) {
      formValues.id = location?.state?.objec
    }
    let data = JSON.stringify(formValues)
    accountsApiService.addAccount(formValues).then(res => {
      if (res.data.success == false && res.data.Type == "unique") {
        setHandleRefrech(true);
      } else
        if (res.data.success == false && res.data.Type == "required") {
          notifyError("The following fields are required")
        }
        else {
          setTimeout(() => {
            navigate(`/accounts/user`);
          }, 3000);
          notifySuccess("Account has been added")
        }
    }).catch(err => {
      notifyError("error");
    });
  }


  const generate8DigitNumber = () => {
    const newDigits = Math.floor(10000000 + Math.random() * 90000000);
    setRandomNumber(newDigits.toString())
    setFormValues({ ...formValues, Username: newDigits.toString() });
  }

  useEffect(() => {
    generate8DigitNumber()
    if (location?.state?.objec) {
      setFormValues(location.state.objec)
    }
  }, [initApp]);

  const reload = () => {
    generate8DigitNumber()
  }

  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
    setValidateForm({ ...validateForm, [event.target.name]: true });
  }

  const handleSelectChange = (selectedOption, { name }) => {
    setFormValues({ ...formValues, [name]: selectedOption.value });
    setValidateForm({ ...validateForm, [name]: true });
  }

  const OnCancelDelete = () => {
    setHandleRefrech(!handleRefrech);
  }
  const OnCancelRequired = () => {
    setHandleRequired(!handleRequired);
  }

  //Alerts
  const notifySuccess = (message) => SuccessAlert(message)
  const notifyError = (message) => ErrorAlert(message)

  return (
    <Fragment>
      <ToastContainer
      />
      <AlertRefresh message='Username existe , Please refresh the username before adding' handleRefresh={handleRefrech}
        onCancel={OnCancelDelete} />
      <AlertRefresh message='The following fields are required' handleRefresh={handleRequired}
        onCancel={OnCancelRequired} />
      <div className="page-header">
        <div>
          {dataa ? <h2 className="main-content-title tx-24 mg-b-5"> Edit User</h2> : <h2 className="main-content-title tx-24 mg-b-5"> Add User</h2>}
          <Breadcrumb >
            <Link to={`/dashboard`}>
              <Breadcrumb.Item active>
                Home /
              </Breadcrumb.Item></Link>
            <Link to={`/accounts/user`}>
              <Breadcrumb.Item active>
                Accounts
              </Breadcrumb.Item></Link>
          </Breadcrumb>
        </div>

      </div>
      <Row className="row-sm">
        <Col lg={12} md={12}>
          <Card className="custom-card">
            <Card.Body>
              <div>
                <h6 className="main-content-label mb-1">User</h6>
                <p className="text-muted card-sub-title">
                  A form control with success, warning and error state.
                </p>
              </div>
              <form>
                <Row className="row-sm">

                  <Col md={6} >
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Username</p>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><i className="mdi mdi-autorenew" onClick={reload} ></i></span>
                        </div>
                        <Form.Control
                          type="text" name="Username" value={randomNumber} disabled placeholder="Name" onChange={handleChange} required />
                      </div>
                    </Form.Group>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Email</p>
                      <Form.Control
                        className={check != null && !check.Email ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text" name="Email" placeholder="Email" defaultValue={dataa?.Email} onChange={handleChange} required

                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Language</p>
                      <Multiselect classNamePrefix="Select2" name="Language" options={languageOptions} singleSelect displayValue="key" onChange={handleSelectChange} defaultValue={languageOptions.find(lang => lang.value == dataa?.Language)} />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Status</p>
                      <Multiselect classNamePrefix="Select2" name="Status" options={StatusOptions} singleSelect displayValue="key" onChange={handleSelectChange} defaultValue={StatusOptions.find(lang => lang.value == dataa?.Status)} />
                    </Form.Group>
                    <Form.Group className="form-group">

                      <p className="mg-b-10">Last Name</p>
                      <Form.Control
                        className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                        defaultValue={dataa?.Last_name}
                        type="text"
                        name="Last_name"
                        placeholder="Last Name"
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Groups</p>
                      <Multiselect classNamePrefix="Select2" name="Groups" options={GroupsOptions} singleSelect displayValue="key" onChange={handleSelectChange} defaultValue={GroupsOptions.find(lang => lang.value == dataa?.Groups)} />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Enabled</p>
                      <Multiselect classNamePrefix="Select2" name="Enabled" options={EnabledOptions} singleSelect displayValue="key" onChange={handleSelectChange} defaultValue={EnabledOptions.find(lang => lang.value == dataa?.Enabled)} />
                    </Form.Group>


                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Password</p>
                      <Form.Control
                        className={check != null && !check.Password ? "form-control is-invalid state-invalid" : "form-group"}

                        type="text"
                        name="Password"
                        placeholder="Password"
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Confirm Password</p>
                      <Form.Control
                        defaultValue={dataa?.Confirm_Password}
                        className={check != null && !check.Confirm_Password ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Confirm_Password"
                        placeholder="Confirm Password"
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Time Zone</p>
                      <Multiselect classNamePrefix="Select2" name="Time_Zone" options={TimeZoneOptions} singleSelect displayValue="key" onChange={handleSelectChange} defaultValue={TimeZoneOptions.find(lang => lang.value == dataa?.Time_Zone)} />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">First Name</p>
                      <Form.Control
                        defaultValue={dataa?.First_name}
                        className={check != null && !check.First_name ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="First_name"
                        placeholder="First Name"
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Organization</p>
                      <Form.Control
                        defaultValue={dataa?.Organization}
                        className={check != null && !check.Organization ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Organization"
                        placeholder="Organization"
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Domains</p>
                      <Multiselect
                        defaultValue={DomainsOptions.find(lang => lang.value == dataa?.Domains)}

                        name="Domains" options={DomainsOptions}
                        singleSelect displayValue="key"
                        onChange={handleSelectChange} />
                    </Form.Group>

                  </Col>

                </Row>
                <Row>
                  <Col><div className="col-md-8 ps-md-2 btn-list">
                    <Link to={`${process.env.PUBLIC_URL}/components/Accounts/User`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' >
                      Previous
                    </Button></Link>
                  </div></Col>
                  <Col md={2}> <div className="col-md-8 ps-md-9 btn-list" style={{ left: '40%' }}>
                    {dataa ? <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' onClick={onFinish}>
                      update
                    </Button> : <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' onClick={onFinish}>
                      Submit
                    </Button>}
                  </div>
                  </Col>

                </Row>




              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}
AddEditUser.propTypes = {};

AddEditUser.defaultProps = {};

export default AddEditUser;