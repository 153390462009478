import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class ExtensionsApiService extends BaseApiService {

  constructor() {

    super('Extension');
  }
  getcustomer(params) {

  }
 

}

const extensionsApiService = new ExtensionsApiService();

export default extensionsApiService;
