export const StatusOptions = [
    { value: 'true', label: 'true' },
    { value: 'false', label: 'false' },

    // add more options as needed
  ];
  export const SoundOptions = [
    { value: 'say', label: 'say' },
    { value: 'tone_stream', label: 'tone_stream' },

    // add more options as needed
  ];
  export const DestinationOptions = [
    { value: '1001 test', label: '1001 test' },
    { value: '1001 test copy', label: '1001 test copy' },

    // add more options as needed
  ];

  export const Alternate_SoundOptions = [
    { value: '1001 test', label: '1001 test' },
    { value: '1001 test copy', label: '1001 test copy' },

    // add more options as needed
  ];
  export const Alternate_DestinationOptions = [
    { value: '1001 test', label: '1001 test' },
    { value: '1001 test copy', label: '1001 test copy' },

    // add more options as needed
  ];
  export const GreetingOptions = [
    { value: '1001 test', label: '1001 test' },
    { value: '1001 test copy', label: '1001 test copy' },

    // add more options as needed
  ];