import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import SuccessAlert from "../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../data/extensions/alerte_extensions/ErrorAlert";
import CallCenterApiService from "../util/services/CallCenterApiService";
import { Link } from 'react-router-dom';
import ConfirmDeleteModal from "../data/common/ConfirmEnabledModal";
import CallCenterTable from "../data/tables/Aplications/DataFormCallCenter";
import SearchBox from "./searchbox";
import { ToastContainer } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";
import ConfirmCopyModal from "../data/common/ConfirmCopyModel";

const override = {
	display: "block",
	margin: "0 auto",
	borderColor: "#36d7b7",
};

const Index = (props) => {
	const [initApp, setInitApp] = useState(true);
	const [callcenter, setCallcenter] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [agent_id, setAgent_id] = useState(0)
	const [handleDelete, setHandleDelete] = useState(false)
	const [currentPage, setCurrentPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [page, setPage] = useState(1)
	const [totalRows, setTotalRows] = useState();
	const [numberPage, setNumberPage] = useState(0)
	const [meta_key, setMeta_key] = useState('')
	const [filter, setFilter] = useState([])
	const [fieldsSearchMetas, setFieldsSearchMetas] = useState([])
	const [data, setData] = useState([])
	const [handleCopyModel, setHandleCopyModel] = useState(false)
	const [resetCheck, setResetCheck] = useState(false)


	const getAllsetCallcenter = (_numberPage, _currentPage, _meta_key, _filter, _fieldsSearchMetas) => {
		if (!_filter) {
			_filter = [];
		}
		if (!_fieldsSearchMetas) {
			_fieldsSearchMetas = [];
		}
		if (!_meta_key) {
			_meta_key = '';
		}
		let params = {
			limit: _numberPage ? _numberPage : numberPage,
			page: _currentPage ? _currentPage : currentPage,
			meta_key: _meta_key,
		};

		CallCenterApiService.find(params).then(res => {
			console.log("callcenter", res.data)
			console.log("params", params)
			console.log(res.data)
			setCallcenter(res.data.data || []);
			//setPage(res.data.attributes.start);
			setTotalRows(res.data.attributes.count);


		});
	};

	const handleRowsPerPageChange = (newRowsPerPage) => {
		setRowsPerPage(newRowsPerPage);
		getAllsetCallcenter(newRowsPerPage, page);
	};

	const handleChangePage = async (newPage) => {
		setPage(newPage);
		getAllsetCallcenter(rowsPerPage, newPage);
	};

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			getAllsetCallcenter(rowsPerPage, page);
			setTimeout(() => {
				setIsLoading(false);
			}, 3000);
		};
		fetchData();
	}, [handlecheck]);

	const OnConfirmDelete = () => {
		setHandleDelete(!handleDelete);
		CallCenterApiService.delete(agent_id).then(res => {
			getAllsetCallcenter();
			setHandleDelete(!handleDelete);
			notifySuccess("Call Center has been deleted");
		}).catch(err => {
			notifyError("Error to delete Call Center");
		})
	}

	const OnCancelDelete = () => {
		setHandleDelete(!handleDelete);
	}

	const handleModelConfirmDeleteAccount = (item_id) => {
		setAgent_id(item_id);
		setHandleDelete(true);

	};

	const handleFilter = (filter, meta_key) => {
		setFilter(filter);
		setMeta_key(meta_key);
		getAllsetCallcenter(numberPage, currentPage, meta_key, filter, fieldsSearchMetas)
	}


	const handlecheck = (id) => {
		const index = data.indexOf(id);
		if (index === -1) {
			setData([...data, id])
		}
		else {
			const newData = [...data];
			newData.splice(index, 1);
			setData(newData);
		}
	}

	const handleCopy = () => {
		setHandleCopyModel(true)
	}

	const OnCancelCopy = () => {
		setHandleCopyModel(false)
	}

	const OnConfirmCopy = () => {
		if (!data || data.length === 0) {
			notifyError("We need to check a call center");
			return;
		}

		CallCenterApiService.copy({ ids: data }).then(res => {
			console.log("call", data)
			notifySuccess("Call center  has been copy ")
			getAllsetCallcenter()

		}).catch(err => {
			notifyError(" error ");
		});
		setHandleCopyModel(false)
		setResetCheck(true)
		setTimeout(() => {
			setResetCheck(false)
		}, 1000);

		setData([])
	}

	//Alerts
	const notifySuccess = (message) => SuccessAlert(message)
	const notifyError = (message) => ErrorAlert(message)

	return (
		<Fragment>
			<div>
				<ToastContainer
				/>
				{ /* <!-- Page Header --> */}
				<div className="page-header">
					<div>
						<h2 className="main-content-title tx-24 mg-b-5">Home</h2>
					</div>
					<div className="d-flex">
						<p></p>

						<div className="justify-content-center">
							<Link to={`/application/call_center/addEditCallcenter`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='submit' >
								<i className="typcn typcn-plus"></i> ADD
							</Button></Link>
						</div>
						{data.length !== 0 ? <div className="justify-content-center">
							<Button className="btn ripple btn-primary pd-x-30 mg-r-5"
								type='submit'
								onClick={() => handleCopy()} >
								<i className="typcn typcn-plus"></i> Copy
							</Button>
						</div> : null}
					</div>
				</div>
				<ConfirmDeleteModal message='Are you sure you want to delete this Call Center' handleConfirm={handleDelete}
					onCancel={OnCancelDelete} onConfirm={OnConfirmDelete} />
				<ConfirmCopyModal message='Are you sure you want to copy this Call Center'
					handleConfirm={handleCopyModel}
					onCancel={OnCancelCopy}
					onConfirm={OnConfirmCopy} />
				<Row className="row-sm">
					<Col lg={12}>
						<Card className="custom-card overflow-hidden">
							<Card.Body>
								<div>
									<h6 className="main-content-label mb-1" >List call centers</h6>
									<p className="text-muted card-sub-title">
										A simple example with no frills.
									</p>
								</div>
								<Row>
									<Col md='12'>
										<SearchBox filter={filter} fieldsSearchMetas={meta_key} meta_key={meta_key}
											onHandleFilter={handleFilter} />
									</Col>
								</Row>
								<div className="responsive">
									{callcenter.length > 0 ?
										<CallCenterTable callcenter={callcenter}
											enableDelete={handleModelConfirmDeleteAccount}
											handleRowsPerPageChange={handleRowsPerPageChange}
											handleChangePage={handleChangePage}
											handlecheck={handlecheck}
											resetCheck={resetCheck}
											totalRows={totalRows} /> : <ClipLoader
											loading={isLoading}
											cssOverride={override}
											size={30}
											aria-label="Loading Spinner"
											data-testid="loader"
										/>}
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		</Fragment>
	)
}

Index.propTypes = {};
Index.defaultProps = {};
export default Index;
