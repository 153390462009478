import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class OutboundsApiService extends BaseApiService {

  constructor() {

    super('outbounds');
  }
  getcustomer(params) {

  }
 

}

const outboundsApiService = new OutboundsApiService();

export default outboundsApiService;
