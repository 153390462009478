
export const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'es', label: 'Spanish' },
    { value: 'it', label: 'Italian' },
    { value: 'pt', label: 'Portuguese' },
    { value: 'ar', label: 'Arabic' },
    { value: 'zh', label: 'Chinese' },
    { value: 'hi', label: 'Hindi' },
    { value: 'ru', label: 'Russian' },
    { value: 'ja', label: 'Japanese' },
    { value: 'ko', label: 'Korean' },
    { value: 'tr', label: 'Turkish' },
        // add more options as needed
      ];



      export const TimeZoneOptions = [
        { value: "UTC", label: "UTC (Coordinated Universal Time)" },
  { value: "EST", label: "EST (Eastern Standard Time)" },
  { value: "CST", label: "CST (Central Standard Time)" },
  { value: "MST", label: "MST (Mountain Standard Time)" },
  { value: "PST", label: "PST (Pacific Standard Time)" },
  { value: "AKST", label: "AKST (Alaska Standard Time)" },
  { value: "HST", label: "HST (Hawaii-Aleutian Standard Time)" },
  { value: "CET", label: "CET (Central European Time)" },
  { value: "EET", label: "EET (Eastern European Time)" },
  { value: "MSK", label: "MSK (Moscow Standard Time)" },
  { value: "JST", label: "JST (Japan Standard Time)" },
        // add more options as needed
      ];


      export const StatusOptions = [
        { value: 'Available', label: 'Available' },
        { value: 'Available (on demand) ', label: 'Available (on demand)' },
        { value: 'Logged out', label: 'Logged out' },
        { value: 'On break', label: 'On break' },
        { value: 'Do not diturb', label: 'Do not diturb' },
        // add more options as needed
      ];

      export const GroupsOptions = [
        { value: 'Admin', label: 'Admin' },
        { value: 'Agent', label: 'Agent' },
        { value: 'Public', label: 'Public' },
        { value: 'SuperAdmin', label: 'SuperAdmin' },
        { value: 'User', label: 'User' },
        // add more options as needed
      ];

  

      export const DomainsOptions = [
        { value: '92.333.333.333', label: '92.333.333.333' },

        // add more options as needed
      ];

      export const EnabledOptions = [
        { value: 'True', label: 'True' },
        { value: 'False', label: 'False' },
       
        // add more options as needed
      ];
   

