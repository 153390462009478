import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {
    CustomInput,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap'
import "react-data-table-component-extensions/dist/index.css";
import { Archive, MoreVertical, Trash2 } from "react-feather";
import { useNavigate } from 'react-router-dom'

const CallCenterTable = ({ callcenter, enableDelete, handleRowsPerPageChange, handleChangePage, totalRows, handlecheck, resetCheck }) => {
    const [account_id, setAccount_id] = useState(0)
    const [handleDelete, setHandleDelete] = useState(false)
    const [data, setData] = useState([])
    let navigate = useNavigate();

    const OnDeleteAccountItem = (callCenter_id) => {
        setAccount_id(callCenter_id);
        setHandleDelete(!handleDelete);
        enableDelete(callCenter_id)
    };

    const handleEdit = (id) => {
        const object = callcenter.find(obj => obj.callCenter_id === id)
        let path = `/application/call_center/addEditCallcenter`;
        navigate(path, { state: { object } });
    }

    const columns = [
        {
            name: "",
            selector: row => row.selected,
            cell: (row) => (
                <CustomInput
                    type="checkbox"
                    id={`custom-checkbox-${row.callCenter_id}`}
                    checked={resetCheck ? false : row.selected}
                    onChange={() =>
                        handlecheck(row.callCenter_id)
                    }
                />
            ),
            width: '50px'
        },
        {
            name: "Queue Name",
            selector: row => [row.Queue_Name],

            sortable: true
        },
        {
            name: "Extension",
            selector: row => [row.Extension],
            sortable: true
        },
        {
            name: "Strategy",
            selector: row => [row.Strategy],
            sortable: true,

        },
        {
            name: "Record",
            selector: row => [row.Record],
            sortable: true
        },
        {
            name: "Description",
            selector: row => [row.Description],
            sortable: true
        },

        {
            name: 'Actions',
            minWidth: '100px',
            cell: row => (
                <UncontrolledDropdown>
                    <DropdownToggle tag='div' className='btn btn-sm'>
                        <MoreVertical size={14} className='cursor-pointer' />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => handleEdit(row.callCenter_id)} className='w-100'>
                            <Archive size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }} >Edit</span>
                        </DropdownItem>
                        <DropdownItem className='w-100' onClick={() => OnDeleteAccountItem(row.callCenter_id)} >
                            <Trash2 size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }} >Delete</span>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )
        }
    ];
    const tableDatas = {
        columns,
        data: callcenter
    };

    return (
        <Fragment>
            <DataTableExtensions {...tableDatas} >
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onChangePage={handleChangePage}
                    paginationServer
                    paginationTotalRows={totalRows}
                />

            </DataTableExtensions>
        </Fragment>
    )
}
export default CallCenterTable
