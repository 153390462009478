import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import {
    Button, Card, CardBody, Col, FormGroup, Label, Row
} from 'reactstrap'
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../data/extensions/alerte_extensions/ErrorAlert";
import { useLocation } from 'react-router-dom';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { Breadcrumb } from "react-bootstrap";
import Select from 'react-select'
import { selectThemeColors } from '../data/Utils'
import {
    RecordOptions, GreetingsOptions, StrategyOptions,
    Hold_MusicOptions, Time_Base_ScoreOptions, Time_ActionOptions,
    TierApplyOptions, Tier_Rules_Wait_Multiply_LevelOptions,
    Tier_Rule_No_Agent_No_waitOptions, Abandoned_Resume_AllowedOptions,
    Announce_SoundOptions
} from "../util/FormCallCenter";
import { ToastContainer } from 'react-toastify';
import AgentApiService from "../util/services/AgentApiService";
import CallCenterApiService from "../util/services/CallCenterApiService";

const AddEditCallcenter = () => {
    const [initApp, setInitApp] = useState(true);
    const [greeting, setGreeting] = useState(null);
    const [strategy, setStrategy] = useState(null);
    const [hold_music, setHold_Music] = useState(null);
    const [record, setRecord] = useState(null);
    const [time_Base_Score, setTime_Base_Score] = useState(null);
    const [time_Action, setTime_Action] = useState(null);
    const [tier_Apply, setTier_Apply] = useState(null);
    const [tier_Rules_Wait_Multiply_Level, setTier_Rules_Wait_Multiply_Level] = useState(null);
    const [tier_Rule_No_Agent_No_wait, setTier_Rule_No_Agent_No_wait] = useState(null);
    const [abandoned_Resume_Allowed, setAbandoned_Resume_Allowed] = useState(null);
    const [announce_Sound, setAnnounce_Sound] = useState(null);
    const [agent, setAgent] = useState([]);
    const [agent_info, setAgent_info] = useState([]);

    let navigate = useNavigate();
    const location = useLocation();
    const dataa = location?.state?.object;

    const onFinish = (event, values) => {
        event.preventDefault();
        values.Greeting = greeting?.value
        values.Strategy = strategy?.value
        values.Music_On_Hold = hold_music?.value
        values.Record = record?.value
        values.Time_Base_Score = time_Base_Score?.value
        values.Time_Action = time_Action?.value
        values.Tier_Rules_Apply = tier_Apply?.value
        values.Tier_Rule_No_Agent_No_Wait = tier_Rule_No_Agent_No_wait?.value
        values.Abandoned_Resume_Allowed = abandoned_Resume_Allowed?.value
        values.Announce_Sound = announce_Sound?.value
        values.Tier_Rules_Wait_Multiply_Level = tier_Rules_Wait_Multiply_Level?.value
        values.Agent_ids = agent_info?.map((item => item.value))

        if (location?.state?.object) {
            values.callCenter_id = dataa.callCenter_id
            CallCenterApiService.update(values).then(res => {
                setTimeout(() => {
                    navigate(`/application/call_center`);
                }, 3000);
                notifySuccess(" Call center  has been updated");
            }).catch(err => {
                notifyError("Error to update Call center " + err);
            });
        }
        else {
            CallCenterApiService.save(values).then(res => {
                setTimeout(() => {
                    navigate(`/application/call_center`);
                }, 3000);
                notifySuccess("Call center  has been added")
            }).catch(err => {
                notifyError(" error ");
            });
        }
    }
    const getAllAgents = () => {
        AgentApiService.find().then(res => {
            const Agent = []
            const agents = res.data.data
            let defaultOptions = []
            agents.forEach(element => {
                if (element.agent_id !== undefined && element.agent_id !== null) {
                    let agent_data = {
                        value: element.agent_id,
                        label: element.Agent_Name
                    }
                    Agent.push(agent_data)
                    if (dataa && element && dataa.Agent_ids.includes(element.agent_id)) {
                        defaultOptions.push(agent_data)
                        setAgent_info(defaultOptions);
                    }
                }
            });
            setAgent(Agent);
        });
    };

    useEffect(() => {
        const Greeting = GreetingsOptions.find(item => item.value == dataa?.Greeting)
        setGreeting(Greeting)
        const Strategy = StrategyOptions.find(item => item.value == dataa?.Strategy)
        setStrategy(Strategy)
        const Music_Hold = Hold_MusicOptions.find(item => item.value == dataa?.Music_On_Hold)
        setHold_Music(Music_Hold)
        const Record = RecordOptions.find(item => item.value == dataa?.Record)
        setRecord(Record)
        const Time_Base_Score = Time_Base_ScoreOptions.find(item => item.value == dataa?.Time_Base_Score)
        setTime_Base_Score(Time_Base_Score)
        const Time_Action = dataa?.Time_Action && Time_ActionOptions.find(item => item.value == dataa?.Time_Action)
        setTime_Action(Time_Action)
        const Tier_Rules_Apply = TierApplyOptions.find(item => item.value == dataa?.Tier_Rules_Apply)
        setTier_Apply(Tier_Rules_Apply)
        const Tier_Rule_No_Agent_No_wait = Tier_Rule_No_Agent_No_waitOptions.find(item => item.value == dataa?.Tier_Rule_No_Agent_No_Wait)
        setTier_Rule_No_Agent_No_wait(Tier_Rule_No_Agent_No_wait)
        const Announce_Sound = Announce_SoundOptions.find(item => item.value == dataa?.Announce_Sound)
        setAnnounce_Sound(Announce_Sound)
        const Tier_Rules_Wait_Multiply_Level = Tier_Rules_Wait_Multiply_LevelOptions.find(item => item.value == dataa?.Tier_Rules_Wait_Multiply_Level)
        setTier_Rules_Wait_Multiply_Level(Tier_Rules_Wait_Multiply_Level)
        const Abandoned_Resume_Allowed = Abandoned_Resume_AllowedOptions.find(item => item.value == dataa?.Abandoned_Resume_Allowed)
        setAbandoned_Resume_Allowed(Abandoned_Resume_Allowed)
        getAllAgents()
    }, [initApp]);

    const notifySuccess = (message) => SuccessAlert(message)
    const notifyError = (message) => ErrorAlert(message)

    return (
        <Fragment>
            <ToastContainer
            />
            <div className="page-header">
                <div>
                    {dataa ? <h2 className="main-content-title tx-24 mg-b-5"> Edit Call Center</h2> : <h2 className="main-content-title tx-24 mg-b-5"> Add Call Center</h2>}
                    <Breadcrumb >
                        <Link to={`/dashboard`}>
                            <Breadcrumb.Item active>
                                Home /
                            </Breadcrumb.Item></Link>
                        <Link to={`/application/call_center`}>
                            <Breadcrumb.Item active>
                                CallCenter
                            </Breadcrumb.Item></Link>
                    </Breadcrumb>
                </div>
            </div>
            <AvForm onValidSubmit={onFinish}>
                <Row>
                    <Col md='12'>
                        <Card>
                            <CardBody>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Queue Name</Label>
                                            <AvInput type='text' name='Queue_Name'
                                                value={dataa?.Queue_Name} />
                                        </FormGroup>
                                    </Col>

                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Extension</Label>
                                            <AvInput type='text' name='Extension'
                                                value={dataa?.Extension} />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Greeting</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={greeting}
                                                options={GreetingsOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setGreeting(7)}
                                                name='Greeting'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Strategy</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={strategy}
                                                options={StrategyOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setStrategy(obj)}
                                                name='Strategy'
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Music Hold</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={hold_music}
                                                options={Hold_MusicOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setHold_Music(obj)}
                                                name='Music_On_Hold'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Record</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={record}
                                                options={RecordOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setRecord(obj)}
                                                name='Record'
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Time Base Score Second</Label>
                                            <AvInput type='text' name='Time_Base_Score_Second'
                                                value={dataa?.Time_Base_Score_Second} />
                                        </FormGroup>
                                    </Col>

                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Time Base Score</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={time_Base_Score}
                                                options={Time_Base_ScoreOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setTime_Base_Score(obj)}
                                                name='Time_Base_Score'
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Max Wait Time</Label>
                                            <AvInput type='text' name='Max_Wait_Time'
                                                value={dataa?.Max_Wait_Time} />
                                        </FormGroup>
                                    </Col>

                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Max Wait Time With No Agent</Label>
                                            <AvInput type='text' name='Max_Wait_Time_With_No_Agent'
                                                value={dataa?.Max_Wait_Time_With_No_Agent} />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Max_Wait Time With No Agent Time Reached</Label>
                                            <AvInput type='text' name='Max_Wait_Time_With_No_Agent_Time_Reached'
                                                value={dataa?.Max_Wait_Time_With_No_Agent_Time_Reached} />
                                        </FormGroup>
                                    </Col>

                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Time Action</Label>
                                            <Select

                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={time_Action}
                                                options={Time_ActionOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => { setTime_Action(obj); console.log(obj) }}
                                                name='Time_Action'
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Tier Rules Wait Second</Label>
                                            <AvInput type='text' name='Tier_Rules_Wait_Second'
                                                value={dataa?.Tier_Rules_Wait_Second} />
                                        </FormGroup>
                                    </Col>

                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Tier Rules Apply</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={tier_Apply}
                                                options={TierApplyOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setTier_Apply(obj)}
                                                name='Tier_Rules_Apply'
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Discard Abandoned After </Label>
                                            <AvInput type='text' name='Discord_Abandoned_After'
                                                value={dataa?.Discord_Abandoned_After} />
                                        </FormGroup>
                                    </Col>

                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Tier Rules Wait Multiply Level</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={tier_Rules_Wait_Multiply_Level}
                                                options={Tier_Rules_Wait_Multiply_LevelOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setTier_Rules_Wait_Multiply_Level(obj)}
                                                name='Tier_Rules_Wait_Multiply_Level'
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Tier Rule No Agent No wait</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={tier_Rule_No_Agent_No_wait}
                                                options={Tier_Rule_No_Agent_No_waitOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setTier_Rule_No_Agent_No_wait(obj)}
                                                name='Tier_Rule_No_Agent_No_Wait'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Abandoned Resume Allowed</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={abandoned_Resume_Allowed}
                                                options={Abandoned_Resume_AllowedOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setAbandoned_Resume_Allowed(obj)}
                                                name='Abandoned_Resume_Allowed'
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Caller Id Name Prefix </Label>
                                            <AvInput type='text' name='Caller_Id_Name_Prefix'
                                                value={dataa?.Caller_Id_Name_Prefix} />
                                        </FormGroup>
                                    </Col>

                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Announce Sound</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={announce_Sound}
                                                options={Announce_SoundOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setAnnounce_Sound(obj)}
                                                name='Announce_Sound'
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Announce Frequency</Label>
                                            <AvInput type='text' name='Announce_Frequency'
                                                value={dataa?.Announce_Frequency} />
                                        </FormGroup>
                                    </Col>

                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Exit Key</Label>
                                            <AvInput type='text' name='Exit_Key'
                                                value={dataa?.Exit_Key} />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <AvInput type='text' name='Description'
                                                value={dataa?.Description} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Agent</Label>
                                            <Select
                                                isMulti
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={agent_info}
                                                options={agent}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setAgent_info(obj)}
                                                name='Agent_ids'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col><div className="col-md-8 ps-md-2 btn-list">
                                <Link to={`/application/call_center`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' >
                                    Previous
                                </Button></Link>
                            </div></Col>
                            <Col md={2}> <div className="col-md-8 ps-md-9 btn-list" style={{ left: '40%' }}>
                                <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='submit' >
                                    Submit
                                </Button>
                            </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </AvForm>
        </Fragment>
    )
}

AddEditCallcenter.propTypes = {};
AddEditCallcenter.defaultProps = {};
export default AddEditCallcenter;