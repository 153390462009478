import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class ConferenceCenterApiService extends BaseApiService {

  constructor() {

    super('ConferenceCenters');
  }
  getcustomer(params) {

  }
 

}

const conferenceCenterApiService = new ConferenceCenterApiService();

export default conferenceCenterApiService;
