import React, { Fragment, useEffect, useState } from "react";
import { Button, Breadcrumb, Card, Row, Col } from "react-bootstrap";
import SuccessAlert from "../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../data/extensions/alerte_extensions/ErrorAlert";
import DestinationsApiService from "../util/services/DestinationsApiService";
import { Link } from 'react-router-dom';
import ConfirmDeleteModal from "../data/common/ConfirmEnabledModal";
import DestinationTable from "../data/tables/Dialplan/DataTableDestination";
import SearchBox from "./searchbox";
import { ToastContainer } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";
import ConfirmEnabledModal from "../data/common/ConfirmEnabledModal";

const override = {
	display: "block",
	margin: "0 auto",
	borderColor: "#36d7b7",
};

const IndexDestination = (props) => {
	const [initApp, setInitApp] = useState(true);
	const [destinations, setDestinations] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [updateEnabled, setDataEnabled] = useState({ dis_id: '', enabled: '' });
	const [Destinations_id, setDestinations_id] = useState(0)
	const [handleDelete, setHandleDelete] = useState(false)
	const [handleEnabled, setHandleEnabled] = useState(false)
	const [currentPage, setCurrentPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [page, setPage] = useState(1)
	const [totalRows, setTotalRows] = useState();
	const [numberPage, setNumberPage] = useState(0)
	const [meta_key, setMeta_key] = useState('')
	const [filter, setFilter] = useState([])
	const [fieldsSearchMetas, setFieldsSearchMetas] = useState([])

	const getAllDestinations = (_numberPage, _currentPage, _meta_key, _filter, _fieldsSearchMetas) => {
		if (!_filter) {
			_filter = [];
		}
		if (!_fieldsSearchMetas) {
			_fieldsSearchMetas = [];
		}
		if (!_meta_key) {
			_meta_key = '';
		}
		let params = {
			limit: _numberPage ? _numberPage : numberPage,
			page: _currentPage ? _currentPage : currentPage,
			meta_key: _meta_key,
			includes: ['accounts'],
		};
		DestinationsApiService.find(params).then(res => {
			setDestinations(res.data.data || []);
			setTotalRows(res.data.attributes.count);
		});
	};

	const handleRowsPerPageChange = (newRowsPerPage) => {
		setRowsPerPage(newRowsPerPage);
		getAllDestinations(newRowsPerPage, page);
	};

	const handleChangePage = async (newPage) => {
		setPage(newPage);
		getAllDestinations(rowsPerPage, newPage);
	};

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			getAllDestinations(rowsPerPage, page);
			setTimeout(() => {
				setIsLoading(false);
			}, 3000);
		};
		fetchData();
	}, [initApp]);

	const OnConfirmDelete = () => {
		setHandleDelete(!handleDelete);
		DestinationsApiService.delete(Destinations_id).then(res => {
			getAllDestinations();
			setHandleDelete(!handleDelete);
			notifySuccess("Destination has been deleted");
		}).catch(err => {
			notifyError("Error to delete destination");
		})
	}
	const OnCancelDelete = () => {
		setHandleDelete(!handleDelete);
	}

	const handleModelConfirmDeleteAccount = (item_id) => {
		setDestinations_id(item_id);
		setHandleDelete(true);
	};

	const handleFilter = (filter, meta_key) => {
		setFilter(filter);
		setMeta_key(meta_key);
		getAllDestinations(numberPage, currentPage, meta_key, filter, fieldsSearchMetas)
	}

	const showEnabledModal = (Destinations_id, enabled) => {
		setDataEnabled({ dis_id: Destinations_id, enabled: enabled })
		setHandleEnabled(true)
	}

	const handleConfirmEnable = () => {
		setHandleEnabled(false)
		const updateEnable = updateEnabled.enabled == 'Y' ? 'N' : updateEnabled.enabled == 'N' ? 'Y' : null
		console.log(updateEnable)
		DestinationsApiService.update({ Destinations_id: updateEnabled.dis_id, Enabled: updateEnable }).then(res => {
			getAllDestinations();
			notifySuccess(" Destination has been updated");
		}).catch(err => {
			notifyError("Error to update Destination" + err);
		});
	}

	const handleCancelEnable = () => {
		setHandleEnabled(false)
	}
	//Alerts
	const notifySuccess = (message) => SuccessAlert(message)
	const notifyError = (message) => ErrorAlert(message)

	return (
		<Fragment>
			<div>
				<ToastContainer
				/>
				{ /* <!-- Page Header --> */}
				<div className="page-header">
					<div>
						<h2 className="main-content-title tx-24 mg-b-5">Home</h2>
					</div>
					<div className="d-flex">
						<p></p>

						<div className="justify-content-center">
							<Link to={`/dialplan/destinations/addEditDestination`}>	<Button
								variant="white"
								type="button"
								className="btn-icon-text my-2 me-2"
							>
								<i className="typcn typcn-plus"></i> ADD
							</Button></Link>
						</div>
					</div>
				</div>
				<ConfirmDeleteModal message='Are you sure you want to delete this destination' handleConfirm={handleDelete}
					onCancel={OnCancelDelete} onConfirm={OnConfirmDelete} />
				<ConfirmEnabledModal
					message="Are you sure you want to confirm?"
					handleConfirm={handleEnabled}
					onConfirm={handleConfirmEnable}
					onCancel={handleCancelEnable}
				/>
				<Row className="row-sm">
					<Col lg={12}>
						<Card className="custom-card overflow-hidden">
							<Card.Body>
								<div>
									<h6 className="main-content-label mb-1" >List Destinations</h6>
									<p className="text-muted card-sub-title">
										A simple example with no frills.
									</p>
								</div>

								<Row>
									<Col md='12'>
										<SearchBox filter={filter} fieldsSearchMetas={meta_key} meta_key={meta_key}
											onHandleFilter={handleFilter} />
									</Col>
								</Row>
								<div className="responsive">
									{destinations.length > 0 ?
										<DestinationTable destinations={destinations}  //ken hedhe mazal majabch donner bech yloawdi louta bla li dere gpt momken li dara gpt fi paget o5rin hna deja 3malna test
											enableDelete={handleModelConfirmDeleteAccount}
											handleRowsPerPageChange={handleRowsPerPageChange}
											handleChangePage={handleChangePage}
											OnEnabledDestinationItem={showEnabledModal}
											totalRows={totalRows} /> : <ClipLoader
											loading={isLoading}
											cssOverride={override}
											size={30}
											aria-label="Loading Spinner"
											data-testid="loader"
										/>}
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		</Fragment>
	)
}

IndexDestination.propTypes = {};
IndexDestination.defaultProps = {};
export default IndexDestination;
