import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import SuccessAlert from "../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../data/extensions/alerte_extensions/ErrorAlert";
import ConferenceRoomsApiService from "../util/services/ConferenceRoomsApiService";
import { Link } from 'react-router-dom';
import ConfirmDeleteModal from "../data/common/ConfirmEnabledModal";
import ConferenceRoomsTable from "../data/tables/Aplications/DataFormConRooms";
import SearchBox from "./searchbox";
import { ToastContainer } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";
import ConfirmEnabledModal from "../data/common/ConfirmEnabledModal";
const override = {
	display: "block",
	margin: "0 auto",
	borderColor: "#36d7b7",
};

const Index = (props) => {
	const [initApp, setInitApp] = useState(true);
	const [conferenceRooms, setConferenceRooms] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [callFlow_id, setCallFlow_id] = useState(0)
	const [handleDelete, setHandleDelete] = useState(false)
	const [handleEnabled, setHandleEnabled] = useState(false)
	const [updateEnabled, setDataEnabled] = useState({ callFlo_id: '', enabled: '' });
	const [currentPage, setCurrentPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [page, setPage] = useState(1)
	const [totalRows, setTotalRows] = useState();
	const [numberPage, setNumberPage] = useState(0)
	const [meta_key, setMeta_key] = useState('')
	const [filter, setFilter] = useState([])
	const [fieldsSearchMetas, setFieldsSearchMetas] = useState([])

	const getAllCallFlow = (_numberPage, _currentPage, _meta_key, _filter, _fieldsSearchMetas) => {
		if (!_filter) {
			_filter = [];
		}
		if (!_fieldsSearchMetas) {
			_fieldsSearchMetas = [];
		}
		if (!_meta_key) {
			_meta_key = '';
		}
		let params = {
			limit: _numberPage ? _numberPage : numberPage,
			page: _currentPage ? _currentPage : currentPage,
			meta_key: _meta_key,
			includes: ['ConferenceCenters', 'accounts'],
		};
		ConferenceRoomsApiService.find(params).then(res => {
			setConferenceRooms(res.data.data || []);
			setTotalRows(res.data.attributes.count);
		});
	};

	const handleRowsPerPageChange = (newRowsPerPage) => {
		setRowsPerPage(newRowsPerPage);
		getAllCallFlow(newRowsPerPage, page);
	};

	const handleChangePage = async (newPage) => {
		setPage(newPage);
		getAllCallFlow(rowsPerPage, newPage);
	};

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			getAllCallFlow(rowsPerPage, page);
			setTimeout(() => {
				setIsLoading(false);
			}, 3000);
		};

		fetchData();
	}, [initApp]);

	const OnConfirmDelete = () => {
		setHandleDelete(!handleDelete);
		ConferenceRoomsApiService.delete(callFlow_id).then(res => {
			getAllCallFlow();
			setHandleDelete(!handleDelete);
			notifySuccess("Conference center has been deleted");
		}).catch(err => {
			notifyError("Error to delete Conference center");
		})
	}

	const OnCancelDelete = () => {
		setHandleDelete(!handleDelete);
	}

	const handleModelConfirmDeleteAccount = (item_id) => {
		setCallFlow_id(item_id);
		setHandleDelete(true);
	};

	const handleFilter = (filter, meta_key) => {
		setFilter(filter);
		setMeta_key(meta_key);
		getAllCallFlow(numberPage, currentPage, meta_key, filter, fieldsSearchMetas)
	}

	const handleConfirmEnable = () => {
		setHandleEnabled(false)
		const updateEnable = updateEnabled.enabled == 'Y' ? 'N' : updateEnabled.enabled == 'N' ? 'Y' : null
		ConferenceRoomsApiService.update({ callFlow_id: updateEnabled.callFlo_id, Enabled: updateEnable }).then(res => {
			getAllCallFlow();
			notifySuccess(" Call Flow has been updated");
		}).catch(err => {
			notifyError("Error to update Call Flow" + err);
		});
	}

	const showEnabledModal = (callFlow_id, enabled) => {
		setDataEnabled({ callFlo_id: callFlow_id, enabled: enabled })
		setHandleEnabled(true)
	}

	const notifySuccess = (message) => SuccessAlert(message)
	const notifyError = (message) => ErrorAlert(message)

	return (
		<Fragment>

			<div>
				<ToastContainer
				/>
				<div className="page-header">
					<div>
						<h2 className="main-content-title tx-24 mg-b-5">Home</h2>
					</div>
					<div className="d-flex">
						<p></p>
						<div className="justify-content-center">
							<Link to={`/application/conferenceRooms/addEditConferenceRoom`}>	<Button
								variant="white"
								type="button"
								className="btn-icon-text my-2 me-2"
							>
								<i className="typcn typcn-plus"></i> ADD
							</Button></Link>
						</div>
					</div>
				</div>
				<ConfirmDeleteModal message='Are you sure you want to delete this Flow' handleConfirm={handleDelete}
					onCancel={OnCancelDelete} onConfirm={OnConfirmDelete} />
				<ConfirmEnabledModal
					message="Are you sure you want to confirm?"
					handleConfirm={handleEnabled}
					onConfirm={handleConfirmEnable}
					onCancel={OnCancelDelete}
				/>
				<Row className="row-sm">
					<Col lg={12}>
						<Card className="custom-card overflow-hidden">
							<Card.Body>
								<div>
									<h6 className="main-content-label mb-1" >List Conference Rooms</h6>
									<p className="text-muted card-sub-title">
										A simple example with no frills.
									</p>
								</div>
								<Row>
									<Col md='12'>
										<SearchBox filter={filter} fieldsSearchMetas={meta_key} meta_key={meta_key}
											onHandleFilter={handleFilter} />
									</Col>
								</Row>
								<div className="responsive">
									{conferenceRooms.length > 0 ?
										<ConferenceRoomsTable conferenceRooms={conferenceRooms}
											enableDelete={handleModelConfirmDeleteAccount}
											handleRowsPerPageChange={handleRowsPerPageChange}
											handleChangePage={handleChangePage}
											OnEnabledDestinationItem={showEnabledModal}
											totalRows={totalRows} /> : <ClipLoader
											loading={isLoading}
											cssOverride={override}
											size={30}
											aria-label="Loading Spinner"
											data-testid="loader"
										/>}
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		</Fragment>
	)
}

Index.propTypes = {};
Index.defaultProps = {};
export default Index;
