import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class CallBlockApiService extends BaseApiService {

  constructor() {

    super('callBlock');
  }
  getcustomer(params) {

  }
 

}

const callBlockApiService = new CallBlockApiService();

export default callBlockApiService;
