export const ProfileOptions = [
    { value: 'wait-mode', label: 'wait-mode' },
    { value: 'wideband', label: 'wideband' },
    { value: 'ultrawidband', label: 'ultrawidband' },
    
   
    // add more options as needed
  ];
  export const UsersOptions = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Agent', label: 'Agent' },
    { value: 'Public', label: 'Public' },
    { value: 'SuperAdmin', label: 'SuperAdmin' },
    { value: 'User', label: 'User' },
    // add more options as needed
  ];

  export const RecordOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
    // add more options as needed
  ];
  export const Conference_NmaeOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
    // add more options as needed
  ];
  export const Wait_For_ModeratorOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
    // add more options as needed
  ];
  export const Moderator_endconfOption = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
    // add more options as needed
  ];
  export const Announce_NameOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
    // add more options as needed
  ];
  export const Announce_CountOption = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
    // add more options as needed
  ];
  export const Announce_RecordingOption = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
    // add more options as needed
  ];
  export const MuteOption = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
    // add more options as needed
  ];
  export const SoundsOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
    // add more options as needed
  ];
  
