export const OrderOptions = [];
for (let i = 0; i <= 999; i++) {
    const value = i.toString().padStart(3, '0');
 
    OrderOptions.push({ value, label: value });
}

export const GetwayOptions = [
    { value: 'enum', label: 'enum' },
    { value: 'freetdm', label: 'freetdm' },
    { value: 'transer', label: 'transer' },
    { value: 'xmpp', label: 'xmpp' },
   
    // add more options as needed
  ];

  export const Alternate_1Options = [
    { value: 'enum', label: 'enum' },
    { value: 'freetdm', label: 'freetdm' },
    { value: 'transer', label: 'transer' },
    { value: 'xmpp', label: 'xmpp' },
   
    // add more options as needed
  ];

  export const Alternate_2Options = [
    { value: 'enum', label: 'enum' },
    { value: 'freetdm', label: 'freetdm' },
    { value: 'transer', label: 'transer' },
    { value: 'xmpp', label: 'xmpp' },
   
    // add more options as needed
  ];

  export const DialplanOptions = [
    { value: '3 Digits', label: '3 Digits' },
    { value: '4 Digits', label: '4 Digits' },
    { value: '5 Digits', label: '5 Digits' },
    { value: '6 Digits', label: '6 Digits' },
    { value: '7 Digits', label: '7 Digits' },
    { value: '8 Digits', label: '8 Digits' },
    { value: '9 Digits', label: '9 Digits' },
   
    // add more options as needed
  ];