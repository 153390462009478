export const RecordOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
    // add more options as needed
  ];

  export const StatusOptions = [
    { value: 'Available', label: 'Available' },
    { value: 'Available (on demand) ', label: 'Available (on demand)' },
    { value: 'Logged out', label: 'Logged out' },
    { value: 'On break', label: 'On break' },
    { value: 'Do not diturb', label: 'Do not diturb' },
    // add more options as needed
  ];

  export const ContactOptions = [
    { value: '12', label: '12' },

    // add more options as needed
  ];