import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class CallCenterApiService extends BaseApiService {

  constructor() {

    super('callCenter');
  }
  getcustomer(params) {

  }
  copy(data) {
    return this.setHeaderToken().post(apiUrl  + "/" +  this.entity_name + '/copy', data);
}

}

const callCenterApiService = new CallCenterApiService();

export default callCenterApiService;
