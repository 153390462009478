import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import {
    Button, Card, CardBody, Col, FormGroup, Label, Row
} from 'reactstrap'
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../data/extensions/alerte_extensions/ErrorAlert";
import { useLocation } from 'react-router-dom';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { Breadcrumb } from "react-bootstrap";
import Select from 'react-select'
import { selectThemeColors } from '../data/Utils'
import {
    StatusOptions, SoundOptions, DestinationOptions, Alternate_SoundOptions,
    Alternate_DestinationOptions
} from "../util/FormAdvancedCallFlow";
import { ToastContainer } from 'react-toastify';
import CallFlowApiService from "../util/services/CallFlowApiService";

const AddEditCallFlow = () => {
    const [initApp, setInitApp] = useState(true);
    const [enabled, setEnabled] = useState('Y');
    const [status, setStatus] = useState(null);
    const [sound, setSound] = useState(null);
    const [destination, setDestination] = useState(null);
    const [alternate_Sound, setAlternate_Sound] = useState(null);
    const [alternate_Destination, setAlternate_Destination] = useState(null);

    let navigate = useNavigate();
    const location = useLocation();
    const dataa = location?.state?.object;

    const onFinish = (event, values) => {
        event.preventDefault()
        values.Enabled = enabled
        values.Status = status?.value
        values.Sound = sound?.value
        values.Sound = sound?.value
        values.Destination = destination?.value
        values.Alternate_Sound = alternate_Sound?.value
        values.Alternate_Destination = alternate_Destination?.value
        if (location?.state?.object) {
            values.callFlow_id = dataa.callFlow_id
            CallFlowApiService.update(values).then(res => {
                setTimeout(() => {
                    navigate(`/application/callFlows`);
                }, 3000);
                notifySuccess(" Call Flow has been updated");
            }).catch(err => {
                notifyError("Error to update Call Flow " + err);
            });
        }
        else {
            CallFlowApiService.save(values).then(res => {
                setTimeout(() => {
                    navigate(`/application/callFlows`);
                }, 3000);
                notifySuccess("Call Flow  has been added")
            }).catch(err => {
                notifyError("error ");
            });
        }
    }


    useEffect(() => {
        const Status = StatusOptions.find(item => item.value == dataa?.Status)
        setStatus(Status)
        const Sound = SoundOptions.find(item => item.value == dataa?.Sound)
        setSound(Sound)
        const Destination = DestinationOptions.find(item => item.value == dataa?.Destination)
        setDestination(Destination)
        const Alternate_Sound = Alternate_SoundOptions.find(item => item.value == dataa?.Alternate_Sound)
        setAlternate_Sound(Alternate_Sound)
        const Alternate_Destination = Alternate_DestinationOptions.find(item => item.value == dataa?.Alternate_Destination)
        setAlternate_Destination(Alternate_Destination)
    }, [initApp]);

    const notifySuccess = (message) => SuccessAlert(message)
    const notifyError = (message) => ErrorAlert(message)

    return (
        <Fragment>
            <ToastContainer
            />
            <div className="page-header">
                <div>
                    {dataa ? <h2 className="main-content-title tx-24 mg-b-5"> Edit Call Flow</h2> : <h2 className="main-content-title tx-24 mg-b-5"> Add Call Flow </h2>}
                    <Breadcrumb >
                        <Link to={`/dashboard`}>
                            <Breadcrumb.Item active>
                                Home /
                            </Breadcrumb.Item></Link>
                        <Link to={`/application/callFlows`}>
                            <Breadcrumb.Item active>
                                Call Flows
                            </Breadcrumb.Item></Link>
                    </Breadcrumb>
                </div>
            </div>
            <AvForm onValidSubmit={onFinish}>
                <Row>
                    <Col md='12'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <AvInput type='text' name='Name'
                                                value={dataa?.Name} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Extension</Label>
                                            <AvInput type='text' name='Extension'
                                                value={dataa?.Extension} />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Feature code</Label>
                                            <AvInput type='text' name='Feature_Code'
                                                value={dataa?.Feature_Code} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Status</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={status}
                                                options={StatusOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setStatus(obj)}
                                                name='Status'
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>

                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>PIN Number</Label>
                                            <AvInput type='text' name='PIN_Number'
                                                value={dataa?.PIN_Number} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Destination Label</Label>
                                            <AvInput type='text' name='Destination_Label'
                                                value={dataa?.Destination_Label} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Sound</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={sound}
                                                options={SoundOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setSound(obj)}
                                                name='Sound'
                                            />
                                        </FormGroup>
                                    </Col>


                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Destination</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={destination}
                                                options={DestinationOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setDestination(obj)}
                                                name='Destination'
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>

                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Alternate Label</Label>
                                            <AvInput type='text' name='Alternate_Label'
                                                value={dataa?.Alternate_Label} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Alternate Sound</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={alternate_Sound}
                                                options={Alternate_SoundOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setAlternate_Sound(obj)}
                                                name='Alternate_Sound'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>

                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Alternate Destination</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={alternate_Destination}
                                                options={Alternate_DestinationOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setAlternate_Destination(obj)}
                                                name='Alternate_Destination'
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Context</Label>
                                            <AvInput type='text' name='Context'
                                                value={dataa?.Context} />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <AvInput type='text' name='Description'
                                                value={dataa?.Description} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col><div className="col-md-8 ps-md-2 btn-list">
                                <Link to={`/application/callFlows`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' >
                                    Previous
                                </Button></Link>
                            </div></Col>
                            <Col md={2}> <div className="col-md-8 ps-md-9 btn-list" style={{ left: '40%' }}>
                                <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='submit' >
                                    Submit
                                </Button>
                            </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </AvForm>
        </Fragment>
    )
}

AddEditCallFlow.propTypes = {};
AddEditCallFlow.defaultProps = {};
export default AddEditCallFlow;