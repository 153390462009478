import { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap'


const AlertRefresh = ({ message, handleRefresh, onConfirm , onCancel}) => {
    const [enableModal, setEnableModal] = useState(handleRefresh)

   

    const handleCancelAction = () => {
        setEnableModal(!enableModal);
        onCancel();
    }

    return (

        <Modal isOpen={handleRefresh} toggle={handleCancelAction} className='modal-dialog-centered'>
            <ModalHeader >Info</ModalHeader>
            <ModalBody>
                {message}
            </ModalBody>
            <ModalFooter>
         
                <Button  onClick={() => handleCancelAction()}>
                   OK
                </Button>

            </ModalFooter>
        </Modal>

    )
}

export default AlertRefresh
