export const OrderOptions = [];
for (let i = 0; i <= 999; i++) {
    const value = i.toString().padStart(3, '0');
 
    OrderOptions.push({ value, label: value });
}



export const Parent_MenuOptions = [
    { value: '', label: ' ' },
  ];

  export const LanguageOptions = [
    { value: '', label: '' },
    { value: 'en us callie', label: 'en us callie' },
    // add more options as needed
  ];

  export const GreetLongOptions = [
    { value: 'say', label: 'say' , title:'misc'},
    { value: 'tone_stream', label: 'False' },
    { value: '', label: '' ,title:'Recording'},
    { value: '', label: '' ,title:'Phrases'},
    // add more options as needed
  ];

  export const GreetShortOptions = [
    { value: 'say', label: 'say' , title:'misc'},
    { value: 'tone_stream', label: 'False' },
    { value: '', label: '' ,title:'Recording'},
    { value: '', label: '' ,title:'Phrases'},
    // add more options as needed
  ];
  export const DirectDialOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
 
  ];

  export const RingBackOptions = [
    { value: 'default', label: 'default' ,title :'Music on Hold'},
    { value: 'au-ring', label: 'au-ring', title :'Ringtones'},
    { value: 'be-ring', label: 'be-ring', },
    { value: 'bong-ring', label: 'bong-ring', },
    { value: 'ca-ring', label: 'ca-ring', },
    { value: 'bong-us-tone', label: 'bong-us-tone', title:'Tones'},
    { value: 'bong-au-tone', label: 'bong-au-tone'},
    { value: 'busy-us-tone', label: 'busy-us-tone'},
    { value: 'vacant-uk-tone', label: 'vacant-uk-tone'},
    { value: 'vacant-us-tone', label: 'vacant-us-tone'},


    // add more options as needed
  ];
  export const GroupsOptions = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Agent', label: 'Agent' },
    { value: 'Public', label: 'Public' },
    { value: 'SuperAdmin', label: 'SuperAdmin' },
    { value: 'User', label: 'User' },
    // add more options as needed
  ];
  export const ActionsOptions = [
    { value: 'bong-us-tone', label: 'bong-us-tone' },
    { value: 'busy-au-tone', label: 'busy-au-tone' },
    { value: 'busy-us-tone', label: 'busy-us-tone' },

    // add more options as needed
  ];