import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class DevicesApiService extends BaseApiService {

  constructor() {

    super('Devices');
  }
  getcustomer(params) {

  }
 

}

const devicesApiService = new DevicesApiService();

export default devicesApiService;
