export const LineOptions = [];
for (let i = 1; i <= 99; i++) {
    LineOptions.push({ value: i.toString(), label: i.toString() });
}

export const KeyOptions = [];
for (let i = 1; i <= 255; i++) {
    KeyOptions.push({ value: i.toString(), label: i.toString() });
}

export const TransportOptions = [
    { value: 'TCP', label: 'TCP' },
    { value: 'UDP', label: 'UDP' },
    { value: 'TLS', label: 'TLS' },
    { value: 'DNS SRV', label: 'DNS SRV' },
   
    // add more options as needed
  ];

  export const EnabledOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
 
   
    // add more options as needed
  ];

  export const CategoryOptions = [
    { value: 'Line', label: 'Line' },
    { value: 'Memory', label: 'Memory' },
    { value: 'Programmable', label: 'Programmable' },
    { value: 'Expansion 1', label: ' Expansion 1' },
    { value: 'Expansion 2', label: ' Expansion 2' },
    { value: 'Expansion 3', label: ' Expansion 3' },
    { value: 'Expansion 4', label: ' Expansion 4' },
    { value: 'Expansion 5', label: ' Expansion 5' },
    { value: 'Expansion 6', label: ' Expansion 6' },
  ];

  export const DomainsOptions = [
    { value: '92.333.333.333', label: '92.333.333.333' },
    { value: 'Global', label: 'Global' },

    // add more options as needed
  ];

  export const GroupsOptions = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Agent', label: 'Agent' },
    { value: 'Public', label: 'Public' },
    { value: 'SuperAdmin', label: 'SuperAdmin' },
    { value: 'User', label: 'User' },
    // add more options as needed
  ];