import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import {
    Button, Card, CardBody, Col, FormGroup, Label, Row
} from 'reactstrap'
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../data/extensions/alerte_extensions/ErrorAlert";
import { useLocation } from 'react-router-dom';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { Breadcrumb } from "react-bootstrap";
import Select from 'react-select'
import { selectThemeColors } from '../data/Utils'
import { OrderOptions, TypeOptions, ConditionOptions, RecordOptions, DomainsOptions, GroupsOptions } from "../util/FormDestinations";
import DestinationApiService from "../util/services/DestinationsApiService";
import accountsApiService from "../util/services/AccountsApiService";
import { ToastContainer } from 'react-toastify';

const AddEditDestination = () => {
    const [account, setAccounts] = useState({});
    const [initApp, setInitApp] = useState(true);
    const [domain, setDomain] = useState(null);
    const [enabled, setEnabled] = useState('Y');
    const [type, setType] = useState(null);
    const [condition, setConditions] = useState(null);
    const [action, setActions] = useState(null);
    const [user_info, setUser_info] = useState(null);
    const [group, setGroup] = useState(null);
    const [record, setRecord] = useState(null);
    const [hold_music, setHoldMusic] = useState(null);
    const [order, setOrder] = useState(null);
    const [voice, setVoice] = useState(false);
    const [fax, setFax] = useState(false);
    const [text, setText] = useState(false);
    const [emergency, setEmergency] = useState(false);

    let navigate = useNavigate();
    const location = useLocation();
    const dataa = location?.state?.object;
    let Groups = localStorage.getItem('Groups');
    console.log("role",Groups)
    let account_id = localStorage.getItem('account_id');
    console.log("id",account_id)

    const onFinish = (event, values) => {
        event.preventDefault();
        values.Type = type?.value
        values.Domain = domain?.value
        values.Enabled = enabled
        values.Action = action?.value
        values.account_id = user_info?.value
        values.Group = group?.value
        values.Record = record?.value
        values.Hold_Music = hold_music
        values.Order = order?.value
        values.Condition = {
            Conditions: condition?.value,
            condition_1: values.Conditions_1
        }
        values.Usage = {
            Voice: voice,
            Fax: fax,
            Text: text,
            Emergency: emergency
        }
        if(Groups=='Admin' || Groups=='User'){
            values.account_id=account_id}
        if (location?.state?.object) {
            values.Destinations_id = dataa.Destinations_id
            DestinationApiService.update(values).then(res => {
                console.log("upd", values)
                setTimeout(() => {
                    navigate(`/dialplan/destinations`);
                }, 3000);
                notifySuccess(" Destination has been updated");
            }).catch(err => {
                notifyError("Error to update Destination" + err);
            });
        }
        else {
            DestinationApiService.save(values).then(res => {
                setTimeout(() => {
                    navigate(`/dialplan/destinations`);
                }, 3000);
                notifySuccess("Destination has been added")
            }).catch(err => {
                notifyError(" error ");
            });
        }
    
    }

    const getAllAccounts = () => {
        accountsApiService.find().then(res => {
            const Useer = []
            const accounts = res.data.data
            accounts.forEach(element => {
                if (element.account_id !== undefined && element.account_id !== null) {
                    let user_data = {
                        value: element.account_id,
                        label: element.Username
                    }
                    Useer.push(user_data)

                    if (dataa && element && dataa.account_id === element.account_id) {
                        setUser_info(user_data);
                    }
                }
            });
            setAccounts(Useer);
        });
    };

    useEffect(() => {
        const Type = TypeOptions.find(item => item.value == dataa?.Type)
        setType(Type)
        console.log("test", Type)
        const Domain = DomainsOptions.find(item => item.value == dataa?.Domain)
        setDomain(Domain)
        const Group = GroupsOptions.find(item => item.value == dataa?.Group)
        setGroup(Group)
        const Record = RecordOptions.find(item => item.value == dataa?.Record)
        setRecord(Record)
        const Condition = ConditionOptions.find(item => item.value == dataa?.Condition?.Conditions)
        setConditions(Condition)
        const Order = OrderOptions.find(item => item.value == dataa?.Order)
        setOrder(Order)
        const Action = OrderOptions.find(item => item.value == dataa?.Order)
        setActions(Action)
        const Hold_Music = OrderOptions.find(item => item.value == dataa?.Order)
        setActions(Hold_Music)
        
        if (dataa?.Usage.Voice == true) {
            setVoice(true)
        }
        if (dataa?.Usage.Fax == true) {
            setFax(true)
        }
        if (dataa?.Usage.Text == true) {
            setText(true)
        }
        if (dataa?.Usage.Emergency == true) {
            setEmergency(true)
        }
        getAllAccounts()

    }, [initApp]);

    const notifySuccess = (message) => SuccessAlert(message)
    const notifyError = (message) => ErrorAlert(message)

    return (
        <Fragment>
            <ToastContainer
            />
            <div className="page-header">
                <div>
                    {dataa ? <h2 className="main-content-title tx-24 mg-b-5"> Edit Destination</h2> : <h2 className="main-content-title tx-24 mg-b-5"> Add Destination</h2>}
                    <Breadcrumb >
                        <Link to={`/dashboard`}>
                            <Breadcrumb.Item active>
                                Home /
                            </Breadcrumb.Item></Link>
                        <Link to={`/dialplan/destinations`}>
                            <Breadcrumb.Item active>
                                Destination
                            </Breadcrumb.Item></Link>
                    </Breadcrumb>
                </div>

            </div>
            <AvForm onValidSubmit={onFinish}>
                <Row>
                    <Col md='12'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Country Code</Label>
                                            <AvInput type='text' name='Country_Code'
                                                value={dataa?.Country_Code} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Type</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                // defaultValue={dataa ? TypeOptions.find(item => item.value == dataa.Type) :''}
                                                value={type}
                                                options={TypeOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setType(obj)}
                                                name='Type'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md='6'>
                                        <FormGroup>
                                            <Label>Destination</Label>
                                            <AvInput type='text' name='Destination'
                                                defaultValue={dataa?.Destination} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Caller Id Name </Label>
                                            <AvInput type='text' name='Caller_Id_Name'
                                                defaultValue={dataa?.Caller_Id_Name} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label for='txtCountry'>Caller Id Number</Label>
                                            <AvInput type='text' name='Caller_Id_Number'
                                                defaultValue={dataa?.Caller_Id_Number} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Context</Label>
                                            <AvInput type='text' name='Context'
                                                defaultValue={dataa?.Context} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Conditions</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                //defaultValue={dataa ? ConditionOptions.find(item => item.value == dataa.Conditions) : null}
                                                options={ConditionOptions}
                                                value={condition}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setConditions(obj)}
                                                name='Conditions'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'  >
                                        <FormGroup>
                                            <Label></Label>
                                            <AvInput type='text' name='Conditions_1'
                                                value={dataa?.Condition?.condition_1} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Actions</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={"accounts"}
                                                value={action}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setActions(obj)}
                                                name='Actions'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                {Groups == 'SuperAdmin' ?   <Col sm='6'>
                                      <FormGroup>
                                            <Label>User</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={account}
                                                isLoading={false}
                                                isClearable={true}
                                                value={user_info}
                                                onChange={(obj) => setUser_info(obj)}
                                                name='account_id'
                                            />
                                        </FormGroup> 
                                    </Col>: null }
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Group</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                //defaultValue={dataa ? GroupsOptions.find(item => item.value == dataa.Group) : null}
                                                options={GroupsOptions}
                                                value={group}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setGroup(obj)}
                                                name='Group'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Caller Id Name Prefix </Label>
                                            <AvInput type='text' name='Caller_Id_Name_Prefix'
                                                defaultValue={dataa?.Caller_Id_Name_Prefix} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Record</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={RecordOptions}
                                                value={record}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setRecord(obj)}
                                                name='Record'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Hold Music</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                defaultValue={"account_info"}
                                                options={"DomainsOptions"}
                                                value={hold_music}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setHoldMusic(obj)}
                                                name='Hold_Music'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Distinctive Ring </Label>
                                            <AvInput type='text' name='Distinctive_Ring'
                                                defaultValue={dataa?.Distinctive_Ring} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>

                                    <Col sm='6' >
                                        <FormGroup>
                                            <Label>Account Code </Label>
                                            <AvInput type='text' name='Account_Code'
                                                defaultValue={dataa?.Account_Code} />
                                        </FormGroup>
                                    </Col>


                                    <Col sm="6">
                                        <FormGroup>
                                            <Label>Usage</Label>
                                            <div className="d-flex">
                                                <div className="col-2">
                                                    <FormGroup check>
                                                        <Label check>
                                                            <AvInput type="checkbox" name="Voice" checked={voice} onChange={(obj) => setVoice(!voice)} />
                                                            Voice
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-2">
                                                    <FormGroup check>
                                                        <Label check>
                                                            <AvInput type="checkbox" name="Fax" checked={fax} onChange={(obj) => setFax(!fax)} />
                                                            Fax
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-2">
                                                    <FormGroup check>
                                                        <Label check>
                                                            <AvInput type="checkbox" name="Text" checked={text} onChange={(obj) => setText(!text)} />
                                                            Text
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-2">
                                                    <FormGroup check>
                                                        <Label check>
                                                            <AvInput type="checkbox" name="Emergency" checked={emergency} onChange={(obj) => setEmergency(!emergency)} />
                                                            Emergency
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Domain</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={DomainsOptions}
                                                value={domain}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setDomain(obj)}
                                                name='Domain'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Order</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={OrderOptions}
                                                value={order}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setOrder(obj)}
                                                name='Order'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>

                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label> Description </Label>
                                            <AvInput type='text' name='Description'
                                                defaultValue={dataa?.Description} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col><div className="col-md-8 ps-md-2 btn-list">
                                <Link to={`/dialplan/destinations`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' >
                                    Previous
                                </Button></Link>
                            </div></Col>
                            <Col md={2}> <div className="col-md-8 ps-md-9 btn-list" style={{ left: '40%' }}>
                                <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='submit' >
                                    Submit
                                </Button>
                            </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </AvForm>
        </Fragment>
    )
}

AddEditDestination.propTypes = {};
AddEditDestination.defaultProps = {};
export default AddEditDestination;