import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap'
import "react-data-table-component-extensions/dist/index.css";
import { Archive, MoreVertical, Trash2 } from "react-feather";
import { useNavigate } from 'react-router-dom'

const DevicesTable = ({ devices, enableDelete, handleRowsPerPageChange, handleChangePage, totalRows }) => {
    const [Devices_id, setDevices_id] = useState(0)
    const [handleDelete, setHandleDelete] = useState(false)
    const [data, setData] = useState([])

    let navigate = useNavigate()

    const OnDeleteGetwaysItem = (Devices_id) => {
        setDevices_id(Devices_id);
        setHandleDelete(!handleDelete);
        enableDelete(Devices_id)
    };

    const handleEdit = (id) => {
        const object = devices.find(obj => obj.Devices_id === id)
        let path = `/accounts/devices/addEditDevices`;
        navigate(path, { state: { object } });
    }

    const columns = [
        {
            name: "MAC ADRESS",
            selector: row => [row.Mac_Adress],
            sortable: true
        },
        {
            name: "LABEL",
            selector: row => [row.Label],
            sortable: true
        },
        {
            name: "VENDOR",
            selector: row => [row.Vendor],
            sortable: true,

        },

        {
            name: " TEMPLATE",
            selector: row => [row.Template],
            sortable: true
        },
        {
            name: "ENABLED",
            selector: row => [row.Enabled],
            sortable: true
        },
        {
            name: 'Actions',
            minWidth: '100px',
            cell: row => (
                <UncontrolledDropdown>
                    <DropdownToggle tag='div' className='btn btn-sm'>
                        <MoreVertical size={14} className='cursor-pointer' />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => handleEdit(row.Devices_id)} className='w-100'>
                            <Archive size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }}>Edit</span>
                        </DropdownItem>
                        <DropdownItem className='w-100' onClick={() => OnDeleteGetwaysItem(row.Devices_id)}  >
                            <Trash2 size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }}>Delete</span>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )
        }
    ];
    const tableDatas = {
        columns,
        data: devices
    };

    return (
        <Fragment>
            <DataTableExtensions {...tableDatas}  >
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onChangePage={handleChangePage}
                    paginationServer
                    paginationTotalRows={totalRows}
                    noHeader={false}
                />
            </DataTableExtensions>
        </Fragment>
    )
}
export default DevicesTable
