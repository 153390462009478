import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class DestinationsApiService extends BaseApiService {

  constructor() {

    super('Destinations');
  }
  getcustomer(params) {

  }
 

}

const destinationsApiService = new DestinationsApiService();

export default destinationsApiService;
