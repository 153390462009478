
export const GreetingsOptions = [
    { value: 'say', label: 'say' },
    { value: 'tone_stream', label: 'tone_stream' },
    
    // add more options as needed
  ];
  export const StrategyOptions = [
    { value: 'Ring all', label: 'Ring all' },
    { value: 'Round Robin', label: 'Round Robin' },
    
    // add more options as needed
  ];
  export const Hold_MusicOptions = [
    { value: 'Default', label: 'Default' },
    { value: 'au-ring', label: 'au-ring' },
    { value: 'be-ring', label: 'be-ring' },
    { value: 'bon-ring', label: 'bon-ring' },
    
    // add more options as needed
  ];
  export const Time_Base_ScoreOptions = [
    { value: 'system', label: 'system' },
    { value: 'queue', label: 'queue' },
   
    // add more options as needed
  ];
  export const Time_ActionOptions = [
    { value: 'bong-us-tone', label: 'bong-us-tone' },
    { value: 'busy-au-tone', label: 'busy-au-tone' },
    { value: 'busy-us-tone', label: 'busy-us-tone' },
   
    // add more options as needed
  ];
  export const RecordOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
    // add more options as needed
  ];

  export const TierApplyOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
    // add more options as needed
  ];

  export const Tier_Rules_Wait_Multiply_LevelOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
    // add more options as needed
  ];
  export const Tier_Rule_No_Agent_No_waitOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
    // add more options as needed
  ];
  export const Abandoned_Resume_AllowedOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
    // add more options as needed
  ];
  export const Announce_SoundOptions = [
    { value: '', label: '' },
    
   
    // add more options as needed
  ];