import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap'
import "react-data-table-component-extensions/dist/index.css";
import { Archive, MoreVertical, Trash2 } from "react-feather";
import { useNavigate } from 'react-router-dom'

const AccountTable = ({ accounts, enableDelete, handleRowsPerPageChange, handleChangePage, totalRows, }) => {
    const [account_id, setAccount_id] = useState(0)
    const [handleDelete, setHandleDelete] = useState(false)
    const [data, setData] = useState([])

    let navigate = useNavigate();

    const OnDeleteAccountItem = (account_id) => {
        setAccount_id(account_id);
        setHandleDelete(!handleDelete);
        enableDelete(account_id)
    };

    const OnCancelDelete = () => {
        setHandleDelete(!handleDelete);
    }

    const handleEdit = (id) => {
        const objec = accounts.find(obj => obj.account_id === id)
        let path = `/accounts/user/addEditUser`;
        navigate(path, { state: { objec } });
    }

    const Badge = ({ status }) => {
        let color = status === `Available (on demand) ` ? `#33FF5E` :
            status === `On break` ? `#f0ad4e` :
                status === `Available` ? `#ACFF33` :
                    status === `Logged out` ? `#33F9FF` :
                        status === `Do not diturb` ? `#33F9FF` : null

        return <span style={{ backgroundColor: color }} className="badge">{status}</span>;
    }

    const columns = [

        {
            name: "USERNAME",
            selector: row => [row.Username],
            sortable: true
        },
        {
            name: "LAST NAME",
            selector: row => [row.Last_name],
            sortable: true
        },
        {
            name: "First NAME",
            selector: row => [row.First_name],
            sortable: true,

        },
        {
            name: "EMAIL",
            selector: row => [row.Email],
            sortable: true
        },
        {
            name: " STATUS",
            cell: row => <Badge status={row.Status} />,
            sortable: true
        },
        {
            name: 'Actions',
            minWidth: '100px',
            cell: row => (
                <UncontrolledDropdown>
                    <DropdownToggle tag='div' className='btn btn-sm'>
                        <MoreVertical size={14} className='cursor-pointer' />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => handleEdit(row.account_id)} className='w-100'>
                            <Archive size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }} >Edit</span>
                        </DropdownItem>
                        <DropdownItem className='w-100' onClick={() => OnDeleteAccountItem(row.account_id)} >
                            <Trash2 size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }} >Delete</span>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )
        }
    ];
    const tableDatas = {
        columns,
        data: accounts
    };

    return (
        <Fragment>
            <DataTableExtensions {...tableDatas} >
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onChangePage={handleChangePage}
                    paginationServer
                    paginationTotalRows={totalRows} />
            </DataTableExtensions>
        </Fragment>
    )
}
export default AccountTable
