import React, { Fragment, useState, useEffect } from "react";
import DevicesApiService from "../../util/services/DevicesApiService";
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, FormGroup, Label, Row} from 'reactstrap'
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../../data/extensions/alerte_extensions/ErrorAlert";
import { useLocation } from 'react-router-dom';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { Breadcrumb } from "react-bootstrap";
import Select from 'react-select'
import { selectThemeColors } from '../../data/Utils'
import { LineOptions, TransportOptions, EnabledOptions, CategoryOptions, KeyOptions, DomainsOptions ,GroupsOptions} from "../../util/FormAdvancedDevices";
import accountsApiService from "../../util/services/AccountsApiService";
import { ToastContainer } from 'react-toastify';
import { TypeOptions } from "../../util/FormDestinations";

const AddEditDevices = () => {
    const [account, setAccounts] = useState({});
    const [initApp, setInitApp] = useState(true);
    const [domain, setDomain] = useState(null);
    const [enabled, setEnabled] = useState(null);
    const [enableds, setEnableds] = useState(null);
    const [category, setCategory] = useState(null);
    const [key, setKey] = useState(null);
    const [type, setType] = useState(null);
    const [line, setLine] = useState(null);
    const [lines, setLines] = useState(null);
    const [transport, setTransport] = useState(null);
    const [template, setTemplate] = useState(null);
    const [user_info, setUser_info] = useState(null);
    

    let navigate = useNavigate();
    const location = useLocation();
    const dataa = location?.state?.object;
    let Groups = localStorage.getItem('Groups');
    console.log("role",Groups)
    let Account_id = localStorage.getItem('account_id');
    console.log("id",Account_id)


    const onFinish = (event, values) => {
        event.preventDefault();
        values.Settings = {
            Name: values.Name,
            Value: values.Value,
            Description: values.Description,
            Enabled: enabled?.value
        }
         values.Keys = {
            Category: category?.value,
            Key: key?.value,
            Type: type?.value,
            Line: line?.value,
            Values: values.Values,
            label: values.label
        }
       
        values.Lines = {
            line: lines?.value,
            Server_Adress: values.Server_Adress,
            Label: values.Label,
            Display_Name: values.Display_Name,
            User_Id: values.User_Id,
            Auth_Id: values.Password,
            Port: values.Port,
            Transport: transport?.value,
            Register_Expires: values.Register_Expires,
            Shared_Line: values.Shared_Line,
            Password: values.Password,
            enabled: enableds?.value
        }
         values.Devices = {
            Username: values.Username,
            password: values.password
        }
        values.template = template?.value
        values.account_id = user_info?.value
        values.Domains = domain?.value
        values.Enabled = enabled.value 
 
       console.log("Form",values)
       if(Groups=='Admin' || Groups=='User'){
        values.account_id=Account_id}
        if (location?.state?.object) {
            values.Devices_id = dataa.Devices_id
            DevicesApiService.update(values).then(res => {
                notifySuccess(" Device has been updated");
                navigate(`/accounts/devices`);
            }).catch(err => {
                notifyError("Error to update Device" + err);
            });
        }
        else {
            DevicesApiService.save(values).then(res => {
                console.log("test", res)
                notifySuccess("Device has been added")
                navigate(`/accounts/devices`);
            }).catch(err => {
                notifyError(" error ");
            });
        }
    }
     const getAllAccounts = () => {
        accountsApiService.find().then(res => {
            const group = []
            const accounts = res.data.data
            accounts.forEach(element => {
                if (accounts !== undefined && accounts !== null) {
                    let user_data = {
                        value: element.account_id,
                        label: element.Username
                    }
                    group.push(user_data)   
                    if (dataa && element && dataa.account_id === element.account_id) {
                        setUser_info(user_data);
                    }
                }
            });
            setAccounts(group);
        });
    };
 
    useEffect(() => {
        const Category = CategoryOptions.find(item => item.value == dataa?.Keys?.Category)
        setCategory(Category)
        const key = KeyOptions.find(item => item.value == dataa?.Keys?.Key)
        setKey(key)
        const Type = TypeOptions.find(item => item.value == dataa?.Keys?.Type)
        setType(Type)
        const Line = LineOptions.find(item => item.value == dataa?.Keys?.Line)
        setLine(Line) 
        const line = LineOptions.find(item => item.value == dataa?.Lines?.line)
        setLines(line) 
        const Enabled = EnabledOptions.find(item => item.value == dataa?.Settings?.Enabled)
        setEnabled(Enabled)
        const enabled = EnabledOptions.find(item => item.value == dataa?.Lines?.enabled)
        setEnableds(enabled)
        const Transport = TransportOptions.find(item => item.value == dataa?.Lines.Transport)
        setTransport(Transport)
        const Domains = DomainsOptions.find(item => item.value == dataa?.Domains)
        setDomain(Domains)
         getAllAccounts() 
    }, [initApp]);

    const notifySuccess = (message) => SuccessAlert(message)
    const notifyError = (message) => ErrorAlert(message)

    return (
        <Fragment>
            <ToastContainer
            />
            <div className="page-header">
                <div>
                    {dataa ? <h2 className="main-content-title tx-24 mg-b-5"> Edit Device</h2> : <h2 className="main-content-title tx-24 mg-b-5"> Add Device</h2>}
                    <Breadcrumb >
                        <Link to={`/dashboard`}>
                            <Breadcrumb.Item active>
                                Home /
                            </Breadcrumb.Item></Link>
                        <Link to={`/accounts/devices`}>
                            <Breadcrumb.Item active>
                                Devices
                            </Breadcrumb.Item></Link>
                    </Breadcrumb>
                </div>
            </div>
            <AvForm onValidSubmit={onFinish}>
                <Row>
                    <Col md='12'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Mac Adress</Label>
                                            <AvInput type='text' name='Mac_Adress'
                                                value={dataa?.Mac_Adress} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Label</Label>
                                            <AvInput type='text' name='Label'
                                                value={dataa?.Label} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md='6'>
                                        <FormGroup>
                                            <Label>Template</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                defaultValue={"account_info"}
                                                options={"accounts"}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(value) => setTemplate(value)}
                                                name='Template'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                    {Groups =='SuperAdmin' ?  <FormGroup>
                                            <Label>User</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={account}
                                                isLoading={false}
                                                isClearable={true}
                                                vaLue={user_info}
                                                onChange={(obj) => setUser_info(obj)}
                                                name='account_id'
                                            />
                                        </FormGroup> : null }
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label for='txtCountry'>Location</Label>
                                            <AvInput type='text' name='Location'
                                                value={dataa?.Location} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <AvInput type='text' name='Description'
                                                value={dataa?.Description} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Domain</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={domain}
                                                options={DomainsOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setDomain(obj)}
                                                name='Domains'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Enabled</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={enabled}
                                                options={EnabledOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setEnabled(obj)}
                                                name='Enabled'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label> Username</Label>
                                            <AvInput type='text' name='Username'
                                                value={dataa?.Devices.Username} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label> Password</Label>
                                            <AvInput type='text' name='password'
                                                value={dataa?.Devices.password} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Vendor</Label>
                                            <AvInput type='text' name='Vendor'
                                                value={dataa?.Vendor} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {/*  //Settings */}
                                <Row>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label> Name</Label>
                                            <AvInput type='text' name='Name'
                                                value={dataa?.Settings.Name} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Value</Label>
                                            <AvInput type='text' name='Value'
                                                value={dataa?.Settings.Value} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <AvInput type='text' name='Description'
                                                value={dataa?.Settings.Description} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Enabled</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={enabled}
                                                options={EnabledOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setEnabled(obj)}
                                                name='Enabled'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {/*  //Keys */}
                                <Row>
                                    <Col sm='2'>
                                        <FormGroup>
                                            <Label>Category</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={category}
                                                options={CategoryOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setCategory(obj)}
                                                name='Category'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='2'>
                                        <FormGroup>
                                            <Label>Key</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={key}
                                                options={KeyOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setKey(obj)}
                                                name='Key'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='2'>
                                        <FormGroup>
                                            <Label>Type</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={type}
                                                options={"accounts"}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setType(obj)}
                                                name='Type'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='2'>
                                        <FormGroup>
                                            <Label>Line</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={line}
                                                options={LineOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setLine(obj)}
                                                name='Line'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='2'>
                                        <FormGroup>
                                            <Label>Value</Label>
                                            <AvInput type='text' name='Values'
                                                value={dataa?.Keys?.Values} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='2'>
                                        <FormGroup>
                                            <Label>Label</Label>
                                            <AvInput type='text' name='label'
                                                value={dataa?.Keys.label} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {/*   //Lines */}
                                <Row>
                                    <Col sm='1'>
                                        <FormGroup>
                                            <Label>Line</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={lines}
                                                options={LineOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setLines(obj)}
                                                name='line'
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm='1'>
                                        <FormGroup>
                                            <Label>Server Adress</Label>
                                            <AvInput type='text' name='Server_Adress'
                                                value={dataa?.Lines?.Server_Adress} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='1'>
                                        <FormGroup>
                                            <Label>Label</Label>
                                            <AvInput type='text' name='Label'
                                                value={dataa?.Lines?.Label} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='1'>
                                        <FormGroup>
                                            <Label>Display Name</Label>
                                            <AvInput type='text' name='Display_Name'
                                                defaultValue={dataa?.Lines?.Display_Name} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='1'>
                                        <FormGroup>
                                            <Label>User ID</Label>
                                            <AvInput type='text' name='User_Id'
                                                defaultValue={dataa?.Lines?.User_Id} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='1'>
                                        <FormGroup>
                                            <Label>Auth Id</Label>
                                            <AvInput type='text' name='Auth_Id'
                                                defaultValue={dataa?.Lines.Auth_Id} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='1'>
                                        <FormGroup>
                                            <Label>Password</Label>
                                            <AvInput type='text' name='Password'
                                                defaultValue={dataa?.Lines.Password} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='1'>
                                        <FormGroup>
                                            <Label>Port</Label>
                                            <AvInput type='text' name='Port'
                                                defaultValue={dataa?.Lines.Port} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='1'>
                                        <FormGroup>
                                            <Label>Transport</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={transport}
                                                options={TransportOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setTransport(obj)}
                                                name='Transport'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='1'>
                                        <FormGroup>
                                            <Label>Register Expires</Label>
                                            <AvInput type='text' name='Register_Expires'
                                                defaultValue={dataa?.Lines.Register_Expires} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='1'>
                                        <FormGroup>
                                            <Label>Shared Line</Label>
                                            <AvInput type='text' name='Shared_Line'
                                                defaultValue={dataa?.Lines.Shared_Line} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='1'>
                                        <FormGroup>
                                            <Label>Enabled</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                defaultValue={enableds}
                                                options={EnabledOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setEnableds(obj)}
                                                name='enabled'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col><div className="col-md-8 ps-md-2 btn-list">
                                <Link to={`/accounts/devices`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' >
                                    Previous
                                </Button></Link>
                            </div></Col>
                            <Col md={2}> <div className="col-md-8 ps-md-9 btn-list" style={{ left: '40%' }}>
                                <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='submit' >
                                    Submit
                                </Button>
                            </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </AvForm>
        </Fragment>
    )
}


AddEditDevices.propTypes = {};

AddEditDevices.defaultProps = {};

export default AddEditDevices;