import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap'
import "react-data-table-component-extensions/dist/index.css";
import { Archive, MoreVertical, Trash2 } from "react-feather";
import { useNavigate } from 'react-router-dom'


const GetwaysTable = ({ getways, enableDelete, handleRowsPerPageChange, handleChangePage, totalRows }) => {
    const [Getways_id, setGetways_id] = useState(0)
    const [handleDelete, setHandleDelete] = useState(false)
    const [data, setData] = useState([])

    let navigate = useNavigate();

    const OnDeleteGetwaysItem = (Getways_id) => {
        setGetways_id(Getways_id);
        setHandleDelete(!handleDelete);
        enableDelete(Getways_id)
    };

    const handleEdit = (id) => {
        const objec = getways.find(obj => obj.Getways_id === id)
        let path = `/accounts/getways/addEditGetways`;
        navigate(path, { state: { objec } });
    }
    
    const columns = [
        {
            name: "USERNAME",
            selector: row => [row.Username],
            sortable: true
        },
        {
            name: "PROXY",
            selector: row => [row.Proxy],
            sortable: true
        },
        {
            name: "CONTEXT",
            selector: row => [row.Context],
            sortable: true,

        },

        {
            name: " PROFILE",
            selector: row => [row.Profile],
            sortable: true
        },
        {
            name: "ENABLED",
            selector: row => [row.Enabled],
            sortable: true
        },
        {
            name: 'Actions',
            minWidth: '100px',
            cell: row => (
                <UncontrolledDropdown>
                    <DropdownToggle tag='div' className='btn btn-sm'>
                        <MoreVertical size={14} className='cursor-pointer' />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => handleEdit(row.Getways_id)} className='w-100'>
                            <Archive size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }}>Edit</span>
                        </DropdownItem>
                        <DropdownItem className='w-100' onClick={() => OnDeleteGetwaysItem(row.Getways_id)}  >
                            <Trash2 size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }}>Delete</span>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )
        }
    ];

    const tableDatas = {
        columns,
        data: getways
    };

    return (
        <Fragment>
            <DataTableExtensions {...tableDatas} >
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onChangePage={handleChangePage}
                    paginationServer
                    paginationTotalRows={totalRows} />
            </DataTableExtensions>
        </Fragment>
    )
}
export default GetwaysTable
