import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class FaxServersApiService extends BaseApiService {

  constructor() {

    super('FaxServers');
  }
  getcustomer(params) {

  }
 

}

const faxServersApiService = new FaxServersApiService();

export default faxServersApiService
;
