export const DirectionOptions = [
    { value: 'inbound', label: 'inbound' },
    { value: 'outbound', label: 'outbound' },
 
    // add more options as needed
  ];
  export const ExtensionOptions = [
    { value: 'All', label: 'All' },
   
 
    // add more options as needed
  ];
  export const ActionOptions = [
    { value: 'Rejet', label: 'Rejet' },
    { value: 'Busy', label: 'Busy' },
    { value: 'Hold', label: 'Hold' },
 
    // add more options as needed
  ];