export const OrderOptions = [];
for (let i = 0; i <= 999; i++) {
    const value = i.toString().padStart(3, '0');
 
    OrderOptions.push({ value, label: value });
}



export const TypeOptions = [
    { value: 'Inbound', label: 'Inbound' },
    { value: 'Outbound', label: 'Outbound'},
    { value: 'Local', label: 'Local' },
   
    // add more options as needed
  ];

  export const ConditionOptions = [
    { value: 'Caller Id Name', label: 'Caller Id Name' },
    { value: '', label: '' },
   
   
    // add more options as needed
  ];

  export const EnabledOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
 
   
    // add more options as needed
  ];

  export const RecordOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
 
  ];

  export const DomainsOptions = [
    { value: '92.333.333.333', label: '92.333.333.333' },
    { value: 'Global', label: 'Global' },

    // add more options as needed
  ];
  export const GroupsOptions = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Agent', label: 'Agent' },
    { value: 'Public', label: 'Public' },
    { value: 'SuperAdmin', label: 'SuperAdmin' },
    { value: 'User', label: 'User' },
    // add more options as needed
  ];
  export const ActionsOptions = [
    { value: 'bong-us-tone', label: 'bong-us-tone' },
    { value: 'busy-au-tone', label: 'busy-au-tone' },
    { value: 'busy-us-tone', label: 'busy-us-tone' },

    // add more options as needed
  ];