import { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap'


const ConfirmModal = ({ message, handleConfirm, onConfirm , onCancel}) => {
    const [enableModal, setEnableModal] = useState(handleConfirm)

    const handleConfirmAction = () => {
        setEnableModal(!enableModal);
        onConfirm();
    }

    const handleCancelAction = () => {
        setEnableModal(!enableModal);
        onCancel();
    }

    return (

        <Modal isOpen={handleConfirm} toggle={handleCancelAction} className='modal-dialog-centered'>
            <ModalHeader >Confirm</ModalHeader>
            <ModalBody>
                {message}
            </ModalBody>
            <ModalFooter>
            <Button color='primary' onClick={() => handleConfirmAction()}>
                    Confirm
                </Button>
                <Button  onClick={() => handleCancelAction()}>
                    Cancel
                </Button>

            </ModalFooter>
        </Modal>

    )
}

export default ConfirmModal
