import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class CallFlowApiService extends BaseApiService {

  constructor() {

    super('callFlow');
  }
  getcustomer(params) {

  }
 

}

const callFlowApiService = new CallFlowApiService();

export default callFlowApiService;
