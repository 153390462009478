
     export const RegisterOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
   
        // add more options as needed
      ];

      export const GroupsOptions = [
        { value: 'Admin', label: 'Admin' },
        { value: 'Agent', label: 'Agent' },
        { value: 'Public', label: 'Public' },
        { value: 'SuperAdmin', label: 'SuperAdmin' },
        { value: 'User', label: 'User' },
        // add more options as needed
      ];

      export const ProfileOptions = [
        { value: "External", label: "External" },
        { value: "External-ipv6", label: "External-ipv6" },
        { value: "Internal", label: "Internal" },
        { value: "Internal-ipv6", label: "Internal-ipv6" },

        // add more options as needed
      ];


      export const DistinctToOptions = [
        { value: 'True', label: 'True' },
        { value: 'False', label: 'False' },
        // add more options as needed
      ];

      export const RegisterTransportOptions = [
        { value: 'Udp', label: 'Udp' },
        { value: 'Tcp', label: 'Tcp' },
        { value: 'Tls', label: 'Tls' },
       
        // add more options as needed
      ];


      export const CallerIdOptions = [
        { value: 'True', label: 'True' },
        { value: 'False', label: 'False' },
       
        // add more options as needed
      ];
  
      export const SupressCngOptions = [
        { value: 'True', label: 'True' },
        { value: 'False', label: 'False' },
       
        // add more options as needed
      ];

      export const DomainsOptions = [
        { value: '92.333.333.333', label: '92.333.333.333' },
        { value: 'Global', label: 'Global' },

        // add more options as needed
      ];

      export const EnabledOptions = [
        { value: 'True', label: 'True' },
        { value: 'False', label: 'False' },
       
        // add more options as needed
      ];
      export const ExtensionInOptions = [
        { value: 'True', label: 'True' },
        { value: 'False', label: 'False' },
       
        // add more options as needed
      ];
      export const ContactOptions = [
        { value: 'True', label: 'True' },
        { value: 'False', label: 'False' },
       
        // add more options as needed
      ];

   

