import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap'
import "react-data-table-component-extensions/dist/index.css";
import { Archive, MoreVertical, Trash2 } from "react-feather";
import { useNavigate } from 'react-router-dom'

const CallFlowTable = ({ OnEnabledDestinationItem, callFlow, enableDelete, handleRowsPerPageChange, handleChangePage, totalRows }) => {
    const [callFlow_id, setCallFlow_id] = useState(0)
    const [handleDelete, setHandleDelete] = useState(false)
    const [data, setData] = useState([])

    let navigate = useNavigate();

    const OnDeleteDestinationItem = (callFlow_id) => {
        setCallFlow_id(callFlow_id);
        setHandleDelete(!handleDelete);
        enableDelete(callFlow_id)
    };

    const handleEdit = (id) => {
        const object = callFlow.find(obj => obj.callFlow_id === id)
        let path = `/application/callFlows/addEditCallFlow`;
        navigate(path, { state: { object } });
    }

    const Badge = ({ enabled }) => {
        const color = enabled === 'active' ? `#48FF00` :
            enabled === 'disabled' ? `#FE4245` :
                null
        return <span style={{ backgroundColor: color }} className="badge">{enabled}</span>;
    }

    const columns = [
        {
            name: "NAME",
            selector: row => [row.Name],
            sortable: true
        },
        {
            name: "Extension",
            selector: row => [row.Extension],
            sortable: true
        },

        {
            name: " Destination",
            selector: row => [row.Destination],
            sortable: true
        },

        {
            name: "ENABLED",
            cell: row => <Badge enabled={row.Enabled == 'Y' ? 'active' : row.Enabled == 'N' ? 'disabled' : null} />,
            sortable: true,

        },
        {
            name: 'Actions',
            minWidth: '100px',
            cell: row => (
                <UncontrolledDropdown>
                    <DropdownToggle tag='div' className='btn btn-sm'>
                        <MoreVertical size={14} className='cursor-pointer' />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => handleEdit(row.callFlow_id)} className='w-100'>
                            <Archive size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }}>Edit</span>
                        </DropdownItem>
                        <DropdownItem className='w-100' onClick={() => OnDeleteDestinationItem(row.callFlow_id)}  >
                            <Trash2 size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }}>Delete</span>
                        </DropdownItem>
                        <DropdownItem className='w-100' onClick={() => OnEnabledDestinationItem(row.callFlow_id, row.Enabled)}  >
                            <Trash2 size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }}> Enabled / Disabled </span>
                        </DropdownItem>

                    </DropdownMenu>
                </UncontrolledDropdown>
            )
        }
    ];
    const tableDatas = {
        columns,
        data: callFlow
    };

    return (
        <Fragment>
            <DataTableExtensions {...tableDatas}  >
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onChangePage={handleChangePage}
                    paginationServer
                    paginationTotalRows={totalRows}
                    noHeader={false}
                />
            </DataTableExtensions>
        </Fragment>
    )
}
export default CallFlowTable
