import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap'
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../data/extensions/alerte_extensions/ErrorAlert";
import { useLocation } from 'react-router-dom';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { Breadcrumb } from "react-bootstrap";
import { ToastContainer } from 'react-toastify';
import FaxServersApiService from "../util/services/FaxServerApiService";

const AddEditFaxServer = () => {
    const [initApp, setInitApp] = useState(true);

    let navigate = useNavigate();
    const location = useLocation();
    const dataa = location?.state?.object;

    const onFinish = (event, values) => {
        event.preventDefault();
        if (location?.state?.object) {
            values.faxServer_id = dataa.faxServer_id
            FaxServersApiService.update(values).then(res => {
                setTimeout(() => {
                    navigate(`/application/faxServers`);
                }, 3000);
                notifySuccess(" Fax Server has been updated");
            }).catch(err => {
                notifyError("Error to update Fax Server " + err);
            });
        }
        else {
            FaxServersApiService.save(values).then(res => {
                setTimeout(() => {
                    navigate(`/application/faxServers`);
                }, 3000);
                notifySuccess("Fax Server has been added")
            }).catch(err => {
                notifyError("error ");
            });
        }
    }

    useEffect(() => {
    }, [initApp]);

    const notifySuccess = (message) => SuccessAlert(message)
    const notifyError = (message) => ErrorAlert(message)

    return (
        <Fragment>
            <ToastContainer
            />
            <div className="page-header">
                <div>
                    {dataa ? <h2 className="main-content-title tx-24 mg-b-5"> Edit Fax Server</h2> : <h2 className="main-content-title tx-24 mg-b-5"> Add Fax Server </h2>}
                    <Breadcrumb >
                        <Link to={`/dashboard`}>
                            <Breadcrumb.Item active>
                                Home /
                            </Breadcrumb.Item></Link>
                        <Link to={`/application/faxServers`}>
                            <Breadcrumb.Item active>
                            Fax Servers
                            </Breadcrumb.Item></Link>
                    </Breadcrumb>
                </div>
            </div>
            <AvForm onValidSubmit={onFinish}>
                <Row>
                    <Col md='12'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <AvInput type='text' name='Name'
                                                value={dataa?.Name} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Extension</Label>
                                            <AvInput type='text' name='Extension'
                                                value={dataa?.Extension} />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Account_Code	</Label>
                                            <AvInput type='text' name='Account_Code'
                                                value={dataa?.Account_Code} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                    <FormGroup>
                                            <Label>Destination_Number</Label>
                                            <AvInput type='text' name='Destination_Number'
                                                value={dataa?.Destination_Number} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Prefix</Label>
                                            <AvInput type='text' name='Prefix'
                                                value={dataa?.Prefix} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Email</Label>
                                            <AvInput type='text' name='Email'
                                                value={dataa?.Email} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Caller ID Name</Label>
                                            <AvInput type='text' name='Caller_Id_Name'
                                                value={dataa?.Caller_Id_Name} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Caller ID Number</Label>
                                            <AvInput type='text' name='Caller_Id_Number'
                                                value={dataa?.Caller_Id_Number} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Forward Number</Label>
                                            <AvInput type='text' name='Forward_Number'
                                                value={dataa?.Forward_Number} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Toll Allow</Label>
                                            <AvInput type='text' name='Toll_Allow'
                                                value={dataa?.Toll_Allow} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Number of channels</Label>
                                            <AvInput type='text' name='Number_of_Channels'
                                                value={dataa?.Number_of_Channels} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <AvInput type='text' name='Description'
                                                value={dataa?.Description} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col><div className="col-md-8 ps-md-2 btn-list">
                                <Link to={`/application/conferenceCenters`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' >
                                    Previous
                                </Button></Link>
                            </div></Col>
                            <Col md={2}> <div className="col-md-8 ps-md-9 btn-list" style={{ left: '40%' }}>
                                <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='submit' >
                                    Submit
                                </Button>
                            </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </AvForm>
        </Fragment>
    )
}

AddEditFaxServer.propTypes = {};
AddEditFaxServer.defaultProps = {};
export default AddEditFaxServer;