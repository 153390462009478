import React, { Fragment, useEffect, useState } from "react";

import { Button, Breadcrumb, Card, Row, Col } from "react-bootstrap";
import ExtensionsApiService from "../../util/services/ExtensionsApiService";
import { Link } from 'react-router-dom';
import ConfirmDeleteModal from "../../data/common/ConfirmModal";
import ExtensionTable from "../../data/tables/ExtensionTable";
import SuccessAlert from "../../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../../data/extensions/alerte_extensions/ErrorAlert";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchBox from "./searchbox";

const IndexExtension = (props) => {
	const [initApp, setInitApp] = useState(true);
	const [extensions, setExtensions] = useState([]);
	const [Extension_id, setExtension_id] = useState(0)
	const [handleDelete, setHandleDelete] = useState(false)
	const [currentPage, setCurrentPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [page, setPage] = useState(1)
	const [totalRows, setTotalRows] = useState();
	const [numberPage, setNumberPage] = useState(0)
	const [meta_key, setMeta_key] = useState('')
	const [filter, setFilter] = useState([])
	const [fieldsSearchMetas, setFieldsSearchMetas] = useState([])

	const getAllExtensions = (_numberPage, _currentPage, _meta_key, _filter, _fieldsSearchMetas) => {
		if (!_filter) {
			_filter = [];
		}
		if (!_fieldsSearchMetas) {
			_fieldsSearchMetas = [];
		}
		if (!_meta_key) {
			_meta_key = '';
		}
		let params = {
			limit: _numberPage ? _numberPage : numberPage,
			page: _currentPage ? _currentPage : currentPage,
			meta_key: _meta_key,
		};
		ExtensionsApiService.find(params).then(res => {
			setExtensions(res.data.data || []);
			setTotalRows(res.data.attributes.count);
		});
	};

	const handleRowsPerPageChange = (newRowsPerPage) => {
		setRowsPerPage(newRowsPerPage);
		getAllExtensions(newRowsPerPage, page);
	};
	const handleChangePage = async (newPage) => {
		setPage(newPage);
		getAllExtensions(rowsPerPage, newPage);
	};

	const OnConfirmDelete = () => {
		setHandleDelete(!handleDelete);
		ExtensionsApiService.delete(Extension_id).then(res => {
			getAllExtensions();
			setHandleDelete(!handleDelete);
			notifySuccess(" Extension has been deleted");
		}).catch(err => {
			notifyError("Error to delete Getway");
		})
	}

	const handleModelConfirmDeleteAccount = (item_id) => {
		setExtension_id(item_id);
		setHandleDelete(true);
	};

	const OnCancelDelete = () => {
		setHandleDelete(!handleDelete);
	}

	useEffect(() => {
		getAllExtensions(rowsPerPage, page)
	}, [initApp]);

	const handleFilter = (filter, meta_key) => {
		setFilter(filter);
		setMeta_key(meta_key);
		getAllExtensions(numberPage, currentPage, meta_key, filter, fieldsSearchMetas)
	}

	const notifySuccess = (message) => SuccessAlert(message)
	const notifyError = (message) => ErrorAlert(message)

	return (
		<Fragment>
			<ToastContainer
			/>
			<div className="page-header">
				<div>
					<h2 className="main-content-title tx-24 mg-b-5">Home</h2>
				</div>
				<div className="d-flex">
					<p></p>

					<div className="justify-content-center">
						<Link to={`/accounts/extensions/addEditExtension`}>	<Button
							variant="white"
							type="button"
							className="btn-icon-text my-2 me-2"
						>
							<i className="typcn typcn-plus"></i> ADD
						</Button></Link>

					</div>
				</div>
			</div>
			<ConfirmDeleteModal message='Are you sure you want to delete this Extension' handleConfirm={handleDelete}
				onCancel={OnCancelDelete} onConfirm={OnConfirmDelete} />
			<Row className="row-sm">
				<Col lg={12}>
					<Card className="custom-card overflow-hidden">
						<Card.Body>
							<div>
								<h6 className="main-content-label mb-1">List Extensions</h6>
								<p className="text-muted card-sub-title">
									A simple example with no frills.
								</p>
							</div>
							<Row>
								<Col md='12'>
									<SearchBox filter={filter} fieldsSearchMetas={meta_key} meta_key={meta_key}
										onHandleFilter={handleFilter} />
								</Col>
							</Row>
							<div className="responsive">
								{extensions.length > 0 ? <ExtensionTable extensions={extensions} enableDelete={handleModelConfirmDeleteAccount} handleRowsPerPageChange={handleRowsPerPageChange} handleChangePage={handleChangePage} totalRows={totalRows} /> : null}
							</div>

						</Card.Body>
					</Card>
				</Col>
			</Row>
			\

		</Fragment>
	)
}



IndexExtension.propTypes = {};

IndexExtension.defaultProps = {};

export default IndexExtension;
