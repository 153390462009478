import React, { Fragment, useEffect, useState } from "react";

import { Button, Card, Row, Col } from "react-bootstrap";
import accountsApiService from "../../util/services/AccountsApiService";
import { Link } from 'react-router-dom';
import ConfirmDeleteModal from "../../data/common/ConfirmModal";
import AccountTable from "../../data/tables/AccountTable";
import SearchBox from "./searchbox";


const User = (props) => {


	const [initApp, setInitApp] = useState(true);
	const [accounts, setAccounts] = useState([]);
	const [account_id, setAccount_id] = useState(0)
	const [handleDelete, setHandleDelete] = useState(false)
	const [currentPage, setCurrentPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [page, setPage] = useState(1)
	const [totalRows, setTotalRows] = useState();
	const [numberPage, setNumberPage] = useState(0)
	const [meta_key, setMeta_key] = useState('')
	const [filter, setFilter] = useState([])
	const [fieldsSearchMetas, setFieldsSearchMetas] = useState([])


	const getAllAccounts = (_numberPage, _currentPage, _meta_key, _filter, _fieldsSearchMetas) => {

		if (!_filter) {
			_filter = [];
		}
		if (!_fieldsSearchMetas) {
			_fieldsSearchMetas = [];
		}
		if (!_meta_key) {
			_meta_key = '';
		}
		let params = {
			limit: _numberPage ? _numberPage : numberPage,
			page: _currentPage ? _currentPage : currentPage,
			meta_key: _meta_key,
		};
		accountsApiService.find(params).then(res => {
			console.log(res.data)
			setAccounts(res.data.data || []);
			setTotalRows(res.data.attributes.count);
		});
	};

	const OnConfirmDelete = () => {
		setHandleDelete(!handleDelete);
		accountsApiService.delete(account_id).then(res => {
			getAllAccounts();
			setHandleDelete(!handleDelete);
			console.log("Account has been deleted");
		}).catch(err => {
			console.log("Error to delete account");
		})
	}
	const OnCancelDelete = () => {
		setHandleDelete(!handleDelete);
	}

	const handleModelConfirmDeleteAccount = (item_id) => {
		console.log(item_id)
		setAccount_id(item_id);
		setHandleDelete(true);
	};

	const handleFilter = (filter, meta_key) => {
		setFilter(filter);
		setMeta_key(meta_key);
		console.log("test", meta_key)
		getAllAccounts(numberPage, currentPage, meta_key, filter, fieldsSearchMetas)
	}

	const handleRowsPerPageChange = (newRowsPerPage) => {
		setRowsPerPage(newRowsPerPage);
		getAllAccounts(newRowsPerPage, page);
	};

	const handleChangePage = async (newPage) => {
		setPage(newPage);
		getAllAccounts(rowsPerPage, newPage);
	};

	useEffect(() => {
		getAllAccounts(rowsPerPage, page)
	}, [initApp]);




	return (
		<Fragment>

			{/* <!-- Page Header --> */}
			<div className="page-header">
				<div>
					<h2 className="main-content-title tx-24 mg-b-5">Home</h2>
				</div>
				<div className="d-flex">
					<p></p>
					<div className="justify-content-center">
						<Link to={`/accounts/user/addEditUser`}>	<Button
							variant="white"
							type="button"
							className="btn-icon-text my-2 me-2"
						>
							<i className="typcn typcn-plus"></i> ADD
						</Button></Link>
					</div>
				</div>
			</div>
			{/* <!-- End Page Header --> */}

			{/* <!-- Row --> */}
			<ConfirmDeleteModal message='Are you sure you want to delete this accoun' handleConfirm={handleDelete}
				onCancel={OnCancelDelete} onConfirm={OnConfirmDelete} />
			<Row className="row-sm">
				<Col lg={12}>
					<Card className="custom-card overflow-hidden">
						<Card.Body>
							<div>
								<h6 className="main-content-label mb-1">List accounts</h6>
								<p className="text-muted card-sub-title">
									A simple example with no frills.
								</p>
							</div>
							<Row>
								<Col md='12'>
									<SearchBox filter={filter} fieldsSearchMetas={meta_key} meta_key={meta_key}
										onHandleFilter={handleFilter} />
								</Col>
							</Row>
							<div className="responsive">
								{accounts.length > 0 ? <AccountTable handleRowsPerPageChange={handleRowsPerPageChange} handleChangePage={handleChangePage} accounts={accounts} enableDelete={handleModelConfirmDeleteAccount} totalRows={totalRows} /> : null}
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	)
}




User.propTypes = {};

User.defaultProps = {};

export default User;
