export const TypeOtherOptions = [
    { value: 'customer', label: 'customer' },
    { value: 'Contractor', label: 'Contractor' },
    { value: 'Friend', label: 'Friend' },
    { value: 'Lead', label: 'Lead' },
    { value: 'Member', label: 'Member' },
    { value: 'Family', label: 'Family' },
    { value: 'Subbscriber', label: 'Subbscriber' },
    { value: 'Supplier', label: 'Supplier' },
    { value: 'Provider', label: 'Provider' },
    { value: 'User', label: 'User' },
    { value: 'Volunteer', label: 'Volunteer' },
    // add more options as needed
  ];

  export const LabelNumberOptions = [
    { value: 'Work', label: 'Work' },
    { value: 'Home', label: 'Home' },
    { value: 'Mobile', label: 'Mobile' },
    { value: 'main', label: 'main' },
    { value: 'Billing', label: 'Billing' },
    { value: 'Fax', label: 'Fax' },
    { value: 'Voicemail', label: 'Voicemail' },
    { value: 'Text', label: 'Text' },
    { value: 'Other', label: 'Other' },
    { value: 'User', label: 'User' },
    { value: 'Volunteer', label: 'Volunteer' },
    // add more options as needed
  ];
  export const PrimaryNumberOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
    
    // add more options as needed
  ];
  export const LabelAdressOptions = [
    { value: 'Work', label: 'Work' },
    { value: 'Home', label: 'Home' },
    { value: 'Mobile', label: 'Mobile' },
    { value: 'main', label: 'main' },
    { value: 'Billing', label: 'Billing' },
    { value: 'Fax', label: 'Fax' },
    { value: 'Voicemail', label: 'Voicemail' },
    { value: 'Text', label: 'Text' },
    { value: 'Other', label: 'Other' },
    { value: 'User', label: 'User' },
    { value: 'Volunteer', label: 'Volunteer' },
    // add more options as needed
  ];
  export const TypeAdresseOptions = [
    { value: 'customer', label: 'customer' },
    { value: 'Contractor', label: 'Contractor' },
    { value: 'Friend', label: 'Friend' },
    { value: 'Lead', label: 'Lead' },
    { value: 'Member', label: 'Member' },
    { value: 'Family', label: 'Family' },
    { value: 'Subbscriber', label: 'Subbscriber' },
    { value: 'Supplier', label: 'Supplier' },
    { value: 'Provider', label: 'Provider' },
    { value: 'User', label: 'User' },
    { value: 'Volunteer', label: 'Volunteer' },
    // add more options as needed
  ];
  export const PrimaryAdresseOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
    
    // add more options as needed
  ];

  export const PrimaryEmailOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
    
    // add more options as needed
  ];
  export const PrimaryURLOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
    
    // add more options as needed
  ];
  export const RelationOptions = [
    { value: 'Associate', label: 'Associate' },
    { value: 'Child', label: 'Child' },
    { value: 'Employe', label: 'Employe' },
    { value: 'Member', label: 'Member' },
    { value: 'Other', label: 'Other' },
    { value: 'Parent', label: 'Parent' },
    
    // add more options as needed
  ];
  export const EnabledOptions = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
    
    // add more options as needed
  ];
  export const OrderOptions = [];
for (let i = 0; i <= 999; i++) {
    const value = i.toString().padStart(3, '0');
 
    OrderOptions.push({ value, label: value });
}

export const LabelURLOptions = [
    { value: 'Other', label: 'Other' },
    { value: 'Personal', label: 'Personal' },
    { value: 'Work', label: 'Work' },

    // add more options as needed
  ];
