import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap'
import "react-data-table-component-extensions/dist/index.css";
import { Archive, MoreVertical, Trash2 } from "react-feather";
import { useNavigate } from 'react-router-dom'

const ExtensionTable = ({ extensions, enableDelete, handleRowsPerPageChange, handleChangePage, totalRows }) => {
    const [Getways_id, setGetways_id] = useState(0)
    const [handleDelete, setHandleDelete] = useState(false)
    const [data, setData] = useState([])

    let navigate = useNavigate();

    const OnDeleteGetwaysItem = (Extension_id) => {
        setGetways_id(Extension_id);
        setHandleDelete(!handleDelete);
        enableDelete(Extension_id)
    };

    const handleEdit = (id) => {
        const objec = extensions.find(obj => obj.Extension_id === id)
        let path = `/accounts/extensions/addEditExtension`;
        navigate(path, { state: { objec } });
    }

    const columns = [
        {
            name: "EXTENSION",
            selector: row => [row.Extension],
            sortable: true
        },
        {
            name: "EFFECTIVE CID NAME",
            selector: row => [row.Effective_Caller_Id_Name],
            sortable: true
        },
        {
            name: "OUTBOUND CID NAME",
            selector: row => [row.Outbound_Caller_Id_Name],
            sortable: true,

        },

        {
            name: " CALL GROUP",
            selector: row => [row.Caller_Group],
            sortable: true
        },
        {
            name: "ENABLED",
            selector: row => [row.Enabled],
            sortable: true
        },
        {
            name: 'Actions',
            minWidth: '100px',
            cell: row => (
                <UncontrolledDropdown>
                    <DropdownToggle tag='div' className='btn btn-sm'>
                        <MoreVertical size={14} className='cursor-pointer' />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => handleEdit(row.Extension_id)} className='w-100'>
                            <Archive size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }}>Edit</span>
                        </DropdownItem>
                        <DropdownItem className='w-100' onClick={() => OnDeleteGetwaysItem(row.Extension_id)}  >
                            <Trash2 size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }}>Delete</span>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )
        }
    ];
    const tableDatas = {
        columns,
        data: extensions
    };

    return (
        <Fragment>
            <DataTableExtensions {...tableDatas} >
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onChangePage={handleChangePage}
                    paginationServer
                    paginationTotalRows={totalRows} />
            </DataTableExtensions>
        </Fragment>
    )
}
export default ExtensionTable
