import axios from "axios";
import {apiUrl,currentToken} from "../configs/site.config";

class BaseApiService {

    constructor(entity_name = '') {

        this.entity_name = entity_name;
    }

    find(params = {}) {
            return this.setHeaderToken().post(apiUrl  + "/" +  this.entity_name + '/find', params)
    }

    findById(entity_id) {
        return this.setHeaderToken().get(apiUrl  + "/" +  this.entity_name + '/findById/' + entity_id);
    }

    save(data) {
        return this.setHeaderToken().post(apiUrl  + "/" +  this.entity_name + '/save', data);
    }

    update(data) {
        return this.setHeaderToken().put(apiUrl  + "/" +   this.entity_name + '/update', data);
    }

    delete(id) {
        // const params_encoded = (params && Object.keys(params).length) ? encodeURIComponent(JSON.stringify(params)) : '';
        return this.setHeaderToken().delete(apiUrl + '/' + this.entity_name + '/delete/' + id);
    }
    verifyToken(params) {
        return this.setHeaderToken().post(apiUrl + '/verifyToken', params);
    }

    setHeaderToken() {
       // let currentToken = localStorage.getItem('token');
        return axios.create({
            headers: { 'Authorization': 'Bearer ' + currentToken}
        });
    };

}

export default BaseApiService;
