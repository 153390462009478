import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class AccountsApiService extends BaseApiService {

  constructor() {

    super('account');
  }
  getcustomer(params) {

  }

  Signin(credetials) {
    return axios.create().post(apiUrl + "/account/signin", credetials, {
      headers :{ 'Content-Type': 'application/json' }} );
  }

  addAccount(data) {
    return this.setHeaderToken().post(apiUrl + "/account/save", data);
  }

}

const accountsApiService = new AccountsApiService();

export default accountsApiService;
