import React, { Fragment, useState, useEffect,useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import {
    Button, Card, CardBody, Col, FormGroup, Label, Row
} from 'reactstrap'
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../data/extensions/alerte_extensions/ErrorAlert";
import { useLocation } from 'react-router-dom';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { Breadcrumb } from "react-bootstrap";
import Select from 'react-select'
import { selectThemeColors } from '../data/Utils'
import { OrderOptions, Parent_MenuOptions, LanguageOptions, GreetLongOptions, GreetShortOptions, DirectDialOptions, RingBackOptions } from "../util/FormIVR_Menu";
import { ToastContainer } from 'react-toastify';
import IVR_MenusApiService from "../util/services/IVR_MenusApiService";
import CallCenterApiService from "../util/services/CallCenterApiService";
import { valuesIn } from "lodash";
import { color } from "@mui/system";

const AddEditIvrMenus = (props) => {
    const [initApp, setInitApp] = useState(true);
    const [enabled, setEnabled] = useState('Y');
    const [order_1, setOrder_1] = useState(null);
    const [order_2, setOrder_2] = useState(null);
    const [order_3, setOrder_3] = useState(null);
    const [order_4, setOrder_4] = useState(null);
    const [order_5, setOrder_5] = useState(null);
    const [parent_Menu, setPrent_Menu] = useState(null);
    const [language, setLanguage] = useState(null);
    const [greet_long, setGreet_long] = useState(null);
    const [greet_short, setGreet_short] = useState(null);
    const [direct_Dial, setDirect_Dial] = useState(null);
    const [ring_Back, setRingBack] = useState(null);
    const [destination_info_1, setDestination_info_1] = useState(null);
    const [destination_info_2, setDestination_info_2] = useState(null);
    const [destination_info_3, setDestination_info_3] = useState(null);
    const [destination_info_4, setDestination_info_4] = useState(null);
    const [destination_info_5, setDestination_info_5] = useState(null);
    const [destination_1, setDestination_1] = useState(null);
    const [destination_2, setDestination_2] = useState(null);
    const [destination_3, setDestination_3] = useState(null);
    const [destination_4, setDestination_4] = useState(null);
    const [destination_5, setDestination_5] = useState(null);
    const [exit_action, setExitAction] = useState(null);
    const [exit_action_info, setExitAction_info] = useState(null);

   
    let navigate = useNavigate();
    const location = useLocation();
    const dataa = location?.state?.object;
    console.log("upd", dataa)

    const onFinish = (event, values) => {
        event.preventDefault();
        values.Enabled = enabled
        values.Parent_Menu = parent_Menu?.value
        values.Language = language?.value
        values.Greet_Long = greet_long?.value
        values.Greet_Short = greet_short?.value
        values.Direct_Dial = direct_Dial?.value
        values.Ring_Back = ring_Back?.value
        values.Exit_Action = exit_action_info?.value
        values.Option = {
            options_1: {
                Option_1: values.Option_1,
                Destination_1: destination_info_1?.value,
                Order_1: order_1?.value,
                Description_1: values.Description_1
            },
            options_2: {
                Option_2: values.Option_2,
                Destination_2: destination_info_2?.value,
                Order_2: order_2?.value,
                Description_2: values.Description_2
            },
            options_3: {
                Option_3: values.Option_3,
                Destination_3: destination_info_3?.value,
                Order_3: order_3?.value,
                Description_3: values.Description_3
            },
            options_4: {
                Option_4: values.Option_4,
                Destination_4: destination_info_4?.value,
                Order_4: order_4?.value,
                Description_4: values.Description_4
            },
            options_5: {
                Option_5: values.Option_5,
                Destination_5: destination_info_5?.value,
                Order_5: order_5?.value,
                Description_5: values.Description_5
            },
        }
        if (!values.Name) {
            notifyError("ivr name is required");
            return;
        }
        if (!values.Extension) {
            notifyError("ivr Extension is required");
            return;
        }
        if (!values.Caller_ID_Name_Prefix) {
            notifyError("ivr Caller_ID_Name_Prefix is required");
            return;
        }

        if (location?.state?.object) {

            values.ivr_menus_id = dataa.ivr_menus_id
            console.log("update", values)
            IVR_MenusApiService.update(values).then(res => {
                setTimeout(() => {
                    navigate(`/application/ivr_menus`);
                }, 3000);
                notifySuccess(" ivr menus has been updated");
            }).catch(err => {
                notifyError("Error to update ivr menus" + err);
            });
        }
        else {
            IVR_MenusApiService.save(values).then(res => {
                console.log("vv", values)
                setTimeout(() => {
                    navigate(`/application/ivr_menus`);
                }, 3000);
                notifySuccess("ivr menus  has been added")
            }).catch(err => {
                notifyError(" error ");
            });
        }
    }

    const getAllCallCenter = () => {
        CallCenterApiService.find().then(res => {
            const CallCenterObject = []
            const callCenter = res.data.data
            callCenter.forEach(element => {
                if (element.callCenter_id !== undefined && element.callCenter_id !== null) {
                    let CallCenter_data = {
                        value: element.callCenter_id,
                        label: element.Queue_Name + '  ' + element.Extension + ' ' + element.Description,
                    }
                    CallCenterObject.push(CallCenter_data)
                    if (dataa && element && dataa.callCenter_id === element.callCenter_id) {
                        setDestination_info_1(CallCenter_data);
                        setDestination_info_2(CallCenter_data);
                        setDestination_info_3(CallCenter_data);
                        setDestination_info_4(CallCenter_data);
                        setDestination_info_5(CallCenter_data);
                        setExitAction_info(CallCenter_data);
                    }
                }
            });
            const staticOption = [
                { title: 'IVR Menus-Other' },
                { value: 'top', label: 'top' },
                { value: 'Back', label: 'Back', },
                { value: 'exit', label: 'exit', },
                { title: 'Tones' },
                { value: 'bong-us-tone', label: 'bong-us-tone' },
                { value: 'bong-au-tone', label: 'bong-au-tone' },
                { value: 'busy-us-tone', label: 'busy-us-tone' },
                { value: 'vacant-uk-tone', label: 'vacant-uk-tone' },
                { value: 'vacant-us-tone', label: 'vacant-us-tone' },
                { title: 'Other' },
                { value: 'Check Voicemail', label: 'Check Voicemail' },
                { value: 'Company Directory', label: 'Company Directory' },
                { value: 'Hangup', label: 'Hangup' },
                { value: 'Record', label: 'Record' },
            ]
            const options = [
                {
                    label: 'Call Center',
                    options: CallCenterObject.concat(staticOption).map((option) => ({
                        label: option.label,
                        value: option.value,
                        title: option.title,
                    })),
                },
            ];
            setDestination_1(options);
            setDestination_2(options);
            setDestination_3(options);
            setDestination_4(options);
            setDestination_5(options);
            setExitAction(options);
        });
    };

   
    useEffect(() => {
        const Order_1 = OrderOptions.find(item => item.value == dataa?.Option?.options_1?.Order_1)
        setOrder_1(Order_1)
        const Order_2 = OrderOptions.find(item => item.value == dataa?.Option?.options_2?.Order_2)
        setOrder_2(Order_2)
        const Order_3 = OrderOptions.find(item => item.value == dataa?.Option?.options_3?.Order_3)
        setOrder_3(Order_3)
        const Order_4 = OrderOptions.find(item => item.value == dataa?.Option?.options_4?.Order_4)
        setOrder_4(Order_4)
        const Order_5 = OrderOptions.find(item => item.value == dataa?.Option?.options_5?.Order_5)
        setOrder_5(Order_5)
        const Parent_Menu = Parent_MenuOptions.find(item => item.value == dataa?.Parent_Menu)
        setPrent_Menu(Parent_Menu)
        const Language = LanguageOptions.find(item => item.value == dataa?.Language)
        setLanguage(Language)
        const greet_long = GreetLongOptions.find(item => item.value == dataa?.Greet_Long)
        setGreet_long(greet_long)
        const greet_short = GreetShortOptions.find(item => item.value == dataa?.Greet_Short)
        setGreet_short(greet_short)
        const Direct_Dial = DirectDialOptions.find(item => item.value == dataa?.Direct_Dial)
        setDirect_Dial(Direct_Dial)
        const Ring_Back = RingBackOptions.find(item => item.value == dataa?.Ring_Back)
        setRingBack(Ring_Back)

        getAllCallCenter()
   

    }, [initApp]);

    const notifySuccess = (message) => SuccessAlert(message)
    const notifyError = (message) => ErrorAlert(message)
 
    return (
        
        <Fragment>
            
            <ToastContainer
            />
            <div className="page-header">
                <div>
                    {dataa ? <h2 className="main-content-title tx-24 mg-b-5"> Edit IVR Menus</h2> : <h2 className="main-content-title tx-24 mg-b-5"> Add IVR Menus</h2>}
                    <Breadcrumb >
                        <Link to={`/dashboard`}>
                            <Breadcrumb.Item active>
                                Home /
                            </Breadcrumb.Item></Link>
                        <Link to={`/application/ivr_menus`}>
                            <Breadcrumb.Item active>
                                IVR Menus
                            </Breadcrumb.Item></Link>
                    </Breadcrumb>
                </div>
            </div>
          
            <AvForm onValidSubmit={onFinish}>
            
                <Row>
                    <Col md='12'>
                        <Card>
                             
                            <CardBody>
                           
                                <Row>
                                    <Col sm='4'>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <AvInput type='text' name='Name'
                                                value={dataa?.Name} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='4'>
                                        <FormGroup>
                                            <Label>Extension</Label>
                                            <AvInput type='text' name='Extension'
                                                value={dataa?.Extension} />
                                        </FormGroup>
                                    </Col>
                                    <Col md='4'>
                                        <FormGroup>
                                            <Label>Parent Menu</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={Parent_MenuOptions}
                                                getOptionLabel={option => option.label}
                                                getOptionValue={option => option.value}
                                                formatOptionLabel={({ label, title }) => (
                                                    <div>
                                                        {title && <div style={{ fontWeight: 'bold' }}>{title}</div>}
                                                        <div>{label}</div>
                                                    </div>
                                                )}
                                                value={parent_Menu}
                                                onChange={(obj) => setPrent_Menu(obj)}
                                                isClearable={true}
                                                name='Parent_Menu'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm='4'>
                                        <FormGroup>
                                            <Label>Language</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={LanguageOptions}
                                                getOptionLabel={option => option.label}
                                                getOptionValue={option => option.value}
                                                formatOptionLabel={({ label, title }) => (
                                                    <div>
                                                        {title && <div style={{ fontWeight: 'bold' }}>{title}</div>}
                                                        <div>{label}</div>
                                                    </div>
                                                )}
                                                value={language}
                                                onChange={(obj) => setLanguage(obj)}
                                                isClearable={true}
                                                name='Language'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='4'>
                                        <FormGroup>
                                            <Label>Greet Long</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={GreetLongOptions}
                                                getOptionLabel={option => option.label}
                                                getOptionValue={option => option.value}
                                                formatOptionLabel={({ label, title }) => (
                                                    <div>
                                                        {title && <div style={{ fontWeight: 'bold' }}>{title}</div>}
                                                        <div>{label}</div>
                                                    </div>
                                                )}
                                                value={greet_long}
                                                onChange={(obj) => setGreet_long(obj)}
                                                isClearable={true}
                                                name='Greet_Long'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='4'>
                                        <FormGroup>
                                            <Label>Greet Short</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={GreetShortOptions}
                                                getOptionLabel={option => option.label}
                                                getOptionValue={option => option.value}
                                                formatOptionLabel={({ label, title }) => (
                                                    <div>
                                                        {title && <div style={{ fontWeight: 'bold' }}>{title}</div>}
                                                        <div>{label}</div>
                                                    </div>
                                                )}
                                                value={greet_short}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setGreet_short(obj)}
                                                name='Greet_Short'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm='4'>
                                        <FormGroup>
                                            <Label>Timeout</Label>
                                            <AvInput type='text' name='Timeout'
                                                value={dataa?.Timeout} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='4'>
                                        <FormGroup>
                                            <Label>Exit Action</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={exit_action}
                                                formatOptionLabel={({ label, title }) => (
                                                    <div>
                                                        {title && <div style={{ fontWeight: 'bold' }}>{title}</div>}
                                                        <div>{label}</div>
                                                    </div>
                                                )}
                                                formatGroupLabel={(data) => (
                                                    <div>
                                                        {data.label && (
                                                            <div style={{ fontWeight: 'bold', color: 'black' }}>
                                                                {data.label}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                value={exit_action_info}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setExitAction_info(obj)}
                                                name='Exit_Action'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='4'>
                                        <FormGroup>
                                            <Label>Direct Dial</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={DirectDialOptions}
                                                value={direct_Dial}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setDirect_Dial(obj)}
                                                name='Direct_Dial'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Ring Back</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={RingBackOptions}
                                                getOptionLabel={option => option.label}
                                                getOptionValue={option => option.value}
                                                formatOptionLabel={({ label, title }) => (
                                                    <div>
                                                        {title && <div style={{ fontWeight: 'bold' }}>{title}</div>}
                                                        <div>{label}</div>
                                                    </div>
                                                )}

                                                value={ring_Back}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setRingBack(obj)}
                                                name='Ring_Back'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Caller ID Name Prefix</Label>
                                            <AvInput type='text' name='Caller_ID_Name_Prefix'
                                                value={dataa?.Caller_ID_Name_Prefix} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Context</Label>
                                            <AvInput type='text' name='Context'
                                                value={dataa?.Context} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <AvInput type='textarea' name='Description'
                                                value={dataa?.Description} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Label md='12' className='col-form-label-lg'>Options</Label>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Option</Label>
                                            <AvInput type='text' name='Option_1'
                                                value={dataa?.Option?.options_1?.Option_1} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Destination</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={destination_1}
                                                formatOptionLabel={({ label, title }) => (
                                                    <div>
                                                        {title && <div style={{ fontWeight: 'bold' }}>{title}</div>}
                                                        <div>{label}</div>
                                                    </div>
                                                )}
                                                formatGroupLabel={(data) => (
                                                    <div>
                                                        {data.label && (
                                                            <div style={{ fontWeight: 'bold', color: 'black' }}>
                                                                {data.label}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                value={destination_info_1}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setDestination_info_1(obj)}
                                                name='Destination_1'

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Order</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={OrderOptions}
                                                value={order_1}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setOrder_1(obj)}
                                                name='Order_1'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <AvInput type='text' name='Description_1'
                                                value={dataa?.Option?.options_1?.Description_1} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Option</Label>
                                            <AvInput type='text' name='Option_2'
                                                value={dataa?.Option?.options_2?.Option_2} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Destination</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={destination_2}
                                                formatOptionLabel={({ label, title }) => (
                                                    <div>
                                                        {title && <div style={{ fontWeight: 'bold' }}>{title}</div>}
                                                        <div>{label}</div>
                                                    </div>
                                                )}
                                                formatGroupLabel={(data) => (
                                                    <div>
                                                        {data.label && (
                                                            <div style={{ fontWeight: 'bold', color: 'black' }}>
                                                                {data.label}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                value={destination_info_2}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setDestination_info_2(obj)}
                                                name='Destination_2'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Order</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={OrderOptions}
                                                value={order_2}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setOrder_2(obj)}
                                                name='Order_2'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <AvInput type='text' name='Description_2'
                                                value={dataa?.Option?.options_2?.Description_2} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Option</Label>
                                            <AvInput type='text' name='Option_3'
                                                value={dataa?.Option?.options_3?.Option_3} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Destination</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={destination_3}
                                                formatOptionLabel={({ label, title }) => (
                                                    <div>
                                                        {title && <div style={{ fontWeight: 'bold' }}>{title}</div>}
                                                        <div>{label}</div>
                                                    </div>
                                                )}
                                                formatGroupLabel={(data) => (
                                                    <div>
                                                        {data.label && (
                                                            <div style={{ fontWeight: 'bold', color: 'black' }}>
                                                                {data.label}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                value={destination_info_3}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setDestination_info_3(obj)}
                                                name='Destination_3'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Order</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={OrderOptions}
                                                value={order_3}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setOrder_3(obj)}
                                                name='Order_3'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <AvInput type='text' name='Description_3'
                                                value={dataa?.Option?.options_3?.Description_3} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Option</Label>
                                            <AvInput type='text' name='Option_4'
                                                value={dataa?.Option?.options_4?.Option_4} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Destination</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={destination_4}
                                                formatOptionLabel={({ label, title }) => (
                                                    <div>
                                                        {title && <div style={{ fontWeight: 'bold' }}>{title}</div>}
                                                        <div>{label}</div>
                                                    </div>
                                                )}
                                                formatGroupLabel={(data) => (
                                                    <div>
                                                        {data.label && (
                                                            <div style={{ fontWeight: 'bold', color: 'black' }}>
                                                                {data.label}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                value={destination_info_4}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setDestination_info_4(obj)}
                                                name='Destination_4'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Order</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={OrderOptions}
                                                value={order_4}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setOrder_4(obj)}
                                                name='Order_4'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <AvInput type='text' name='Description_4'
                                                value={dataa?.Option?.options_4?.Description_4} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Option</Label>
                                            <AvInput type='text' name='Option_5'
                                                value={dataa?.Option?.options_5?.Option_5} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Destination</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={destination_5}
                                                formatOptionLabel={({ label, title }) => (
                                                    <div>
                                                        {title && <div style={{ fontWeight: 'bold' }}>{title}</div>}
                                                        <div>{label}</div>
                                                    </div>
                                                )}
                                                formatGroupLabel={(data) => (
                                                    <div>
                                                        {data.label && (
                                                            <div style={{ fontWeight: 'bold', color: 'black' }}>
                                                                {data.label}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                value={destination_info_5}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setDestination_info_5(obj)}
                                                name='Destination_5'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Order</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                options={OrderOptions}
                                                value={order_5}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setOrder_5(obj)}
                                                name='Order_5'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='3'>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <AvInput type='text' name='Description_5'
                                                value={dataa?.Option?.options_5?.Description_5} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                               
                            </CardBody>
                           
                        </Card>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col><div className="col-md-8 ps-md-2 btn-list">
                                <Link to={`/dialplan/inbounds`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' >
                                    Previous
                                </Button></Link>
                            </div></Col>
                            <Col md={2}> <div className="col-md-8 ps-md-9 btn-list" style={{ left: '40%' }}>
                                <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='submit' >
                                    Submit
                                </Button>
                            </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
               
            </AvForm>
           
          
        </Fragment>
    )
   
}

AddEditIvrMenus.propTypes = {};
AddEditIvrMenus.defaultProps = {};
export default AddEditIvrMenus;