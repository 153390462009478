import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumb, Button, Card, Col, Row, Form } from "react-bootstrap";
import Multiselect from "react-select";
import {
  RegisterOptions, ProfileOptions, EnabledOptions, RegisterTransportOptions, CallerIdOptions, GroupsOptions
} from "../../util/FormAdvancedGetw";
import ExtensionsApiService from "../../util/services/ExtensionsApiService";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../../data/extensions/alerte_extensions/ErrorAlert";
import { useParams } from "react-router";
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import DevicesApiService from "../../util/services/DevicesApiService";
const AddEditExtension = () => {
  const [initApp, setInitApp] = useState(true);
  const [item_id, setitemid] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [validateForm, setValidateForm] = useState({});
  const [check, setCheck] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [listDevices, setlistDevices] = useState([]);


  let navigate = useNavigate();
  let _useParams = useParams();
  const location = useLocation();
  const dataa = location?.state?.objec;
  let Groups = localStorage.getItem('Groups');
  console.log("role", Groups)
  let account_id = localStorage.getItem('account_id');
  console.log("id", account_id)

  const onFinish = (event) => {
    event.preventDefault();
    console.log("formValues", formValues)
    if (Groups == 'Admin' || Groups == 'User') {
      formValues.User = account_id}
      if (location?.state?.objec) {
        formValues.id = location?.state?.objec
        ExtensionsApiService.update(formValues).then(res => {
          setTimeout(() => {
            navigate(`/accounts/extensions`);
          }, 3000);
          notifySuccess("Extension has been updated");
        }).catch(err => {
          notifyError("Error to update Extension" + err);
        });
      } else {
        ExtensionsApiService.save(formValues).then(res => {
          setTimeout(() => {
            navigate(`/accounts/extensions`);
          }, 3000);
          notifySuccess("Getway has been added")
        }).catch(err => {
          notifyError(" error ");
        });
      }
    
  }

  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
    setValidateForm({ ...validateForm, [event.target.name]: true });
  }

  const handleChangeAdvanced = (event) => {
    setFormValues({ ...formValues, Advanced: { ...formValues.Advanced, [event.target.name]: event.target.value } });
    setValidateForm({ ...formValues, Advanced: { ...formValues.Advanced, [event.target.name]: true } });
  }

  const handleSelectChange = (selectedOption, { name }) => {
    setFormValues({ ...formValues, [name]: selectedOption.value });
    setValidateForm({ ...validateForm, [name]: true });
  }
  const handleSelectChangeAdvanced = (selectedOption, { name }) => {
    setFormValues({ ...formValues, Advanced: { ...formValues.Advanced, [name]: selectedOption.value } });
    setValidateForm({ ...formValues, Advanced: { ...formValues.Advanced, [name]: true } });
  }
  const getAllDevices = () => {
    DevicesApiService.find().then(res => {
      const Device = []
      const devices = res.data.data
      console.log(devices)
       devices.forEach(element => {
         if (element.Devices_id !== undefined && element.Devices_id !== null) {
           let user_data = {
            value: element.Devices_id,
             label: element.Mac_Adress
           }
           Device.push(user_data)
           if (dataa && element && dataa.Devices_id === element.Devices_id) {
             //setUser_info(user_data);
           }
         }
       });
     // setAccounts(Device);
      setlistDevices(Device)
    });
  };  

  useEffect(() => {

    if (location?.state?.objec) {
      setFormValues(location.state.objec)
    }

    getAllDevices()

  }, [initApp]);

  const notifySuccess = (message) => SuccessAlert(message)
  const notifyError = (message) => ErrorAlert(message)

  return (
    <Fragment>
      <ToastContainer
      />
      <div className="page-header">
        <div>
          {dataa ? <h2 className="main-content-title tx-24 mg-b-5"> Edit Extension</h2> : <h2 className="main-content-title tx-24 mg-b-5"> Add Extension</h2>}
          <Breadcrumb >
            <Link to={`/dashboard`}>
              <Breadcrumb.Item active>
                Home /
              </Breadcrumb.Item></Link>
            <Link to={`/accounts/extensions`}>
              <Breadcrumb.Item active>
                Extensions
              </Breadcrumb.Item></Link>
          </Breadcrumb>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              variant="white"
              type="button"
              className="btn-icon-text my-2 me-2"
            >
              <i className="typcn typcn-plus"></i> ADD
            </Button>
          </div>
        </div>
      </div>
      <Row className="row-sm">
        <Col lg={12} md={12}>
          <Card className="custom-card">
            <Card.Body>
              <div>
                <h6 className="main-content-label mb-1">Extension</h6>
                <p className="text-muted card-sub-title">
                  A form control with success, warning and error state.
                </p>
              </div>
              <form >
                <Row className="row-sm">
                  <Col md={6} >

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Extension</p>
                      <div className="input-group">
                        <Form.Control
                          type="text" name="Extension" placeholder="Name" defaultValue={dataa?.Extension} onChange={handleChange} required />
                      </div>
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Range</p>
                      <Multiselect classNamePrefix="Select2"
                        name="Range" singleSelect displayValue="key"
                        options={RegisterOptions}
                        onChange={handleSelectChange}
                        defaultValue={RegisterOptions.find(lang => lang.value == dataa?.Range)} />
                    </Form.Group>
                    {Groups == 'SuperAdmin' ? (<Form.Group className="form-group">
                      <p className="mg-b-10">User</p>
                      <Multiselect classNamePrefix="Select2"
                        name="User" singleSelect displayValue="key"
                        options={GroupsOptions}
                        onChange={handleSelectChange}
                        defaultValue={GroupsOptions.find(lang => lang.value == dataa?.User)} />
                    </Form.Group>) : null}


                    <Form.Group className="form-group">
                      <p className="mg-b-10">Account Code</p>
                      <Form.Control
                        className={check != null && !check.Email ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text" name="Account_Code" placeholder="Account Code" defaultValue={dataa?.Account_Code} onChange={handleChange} required
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Effective Caller Id Name </p>
                      <Form.Control
                        className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Effective_Caller_Id_Name"
                        placeholder="Effective Caller Id Name"
                        onChange={handleChange}
                        defaultValue={dataa?.Effective_Caller_Id_Name}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Effective Caller Id Number</p>
                      <Form.Control
                        className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Effective_Caller_Id_Number"
                        placeholder="Effective Caller Id Number"
                        onChange={handleChange}
                        defaultValue={dataa?.Effective_Caller_Id_Number}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Outbound Caller Id Name</p>
                      <Form.Control
                        className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Outbound_Caller_Id_Name"
                        placeholder="Outbound Caller Id Name"
                        onChange={handleChange}
                        defaultValue={dataa?.Outbound_Caller_Id_Name}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Caller Group</p>
                      <Form.Control
                        className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Caller_Group"
                        placeholder="Caller Group"
                        onChange={handleChange}
                        defaultValue={dataa?.Caller_Group}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10"> Record</p>
                      <Multiselect
                        name="Record"
                        singleSelect displayValue="key"
                        options={EnabledOptions}
                        onChange={handleSelectChange}
                        defaultValue={EnabledOptions.find(lang => lang.value == dataa?.Record)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10"> Hold Music</p>
                      <Multiselect
                        name="Hold_Music"
                        singleSelect displayValue="key"
                        options={EnabledOptions}
                        onChange={handleSelectChange}
                        defaultValue={EnabledOptions.find(lang => lang.value == dataa?.Hold_Music)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10"> Domain</p>
                      <Multiselect
                        name="Domain"
                        singleSelect displayValue="key"
                        options={EnabledOptions}
                        onChange={handleSelectChange}
                        defaultValue={EnabledOptions.find(lang => lang.value == dataa?.Domain)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Context</p>
                      <Form.Control
                        className={check != null && !check.Password ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Context"
                        placeholder="Context"
                        onChange={handleChange}
                        defaultValue={dataa?.Context}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10"> Enabled</p>
                      <Multiselect
                        name="Enabled"
                        singleSelect displayValue="key"
                        options={EnabledOptions}
                        onChange={handleSelectChange}
                        defaultValue={EnabledOptions.find(lang => lang.value == dataa?.Enabled)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Description</p>
                      <Form.Control
                        className={check != null && !check.Password ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Description"
                        placeholder="Description"
                        onChange={handleChange}
                        defaultValue={dataa?.Description}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10"> Directory Extension Visible</p>
                      <Multiselect
                        name="Directory_Extension_Visible"
                        singleSelect displayValue="key"
                        options={EnabledOptions}
                        onChange={handleSelectChange}
                        defaultValue={EnabledOptions.find(lang => lang.value == dataa?.Directory_Extension_Visible)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Voicemail Password</p>
                      <Form.Control
                        className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Voicemail_Password"
                        placeholder="Voicemail Password"
                        onChange={handleChangeAdvanced}
                        defaultValue={dataa?.Voicemail_Password}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <p className="mg-b-10">Outbound Caller Id Number</p>
                      <Form.Control
                        className={check != null && !check.Password ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Outbound_Caller_Id_Number"
                        placeholder="Outbound Caller Id Number"
                        onChange={handleChange}
                        defaultValue={dataa?.Outbound_Caller_Id_Number}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Emergency Caller Id Name</p>
                      <Form.Control
                        className={check != null && !check.First_name ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Emergency_Caller_Id_Name"
                        placeholder="Emergency Caller Id Number"
                        onChange={handleChange}
                        defaultValue={dataa?.Emergency_Caller_Id_Name}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Emergency Caller Id Number</p>
                      <Form.Control
                        className={check != null && !check.First_name ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Emergency_Caller_Id_Number"
                        placeholder="Emergency Caller Id Number"
                        onChange={handleChange}
                        defaultValue={dataa?.Emergency_Caller_Id_Number}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Directory Full Name</p>
                      <Form.Control
                        className={check != null && !check.First_name ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Directory_Full_Name"
                        placeholder="Directory Full Name"
                        onChange={handleChange}
                        defaultValue={dataa?.Directory_Full_Name}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Directory Visible</p>
                      <Multiselect
                        name="Directory_Visible"
                        singleSelect displayValue="key"
                        options={ProfileOptions}
                        onChange={handleSelectChange}
                        defaultValue={ProfileOptions.find(lang => lang.value == dataa?.Directory_Visible)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10"> Voicemail Enabled</p>
                      <Multiselect
                        name="Voicemail_Enabled"
                        singleSelect displayValue="key"
                        options={EnabledOptions}
                        onChange={handleSelectChange}
                        defaultValue={EnabledOptions.find(lang => lang.value == dataa?.Voicemail_Enabled)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Max Registration</p>
                      <Form.Control
                        className={check != null && !check.Organization ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Max_Registration"
                        placeholder="Max Registration"
                        onChange={handleChange}
                        defaultValue={dataa?.Max_Registration}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Limit Max</p>
                      <Form.Control
                        className={check != null && !check.Organization ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Limit_Max"
                        placeholder="Limit Max"
                        onChange={handleChange}
                        defaultValue={dataa?.Limit_Max}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Limit Destination</p>
                      <Form.Control
                        className={check != null && !check.Organization ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Limit_Destination"
                        placeholder="Limit Destination"
                        onChange={handleChange}
                        defaultValue={dataa?.Limit_Destination}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10"> Voicemail File</p>
                      <Multiselect
                        name="Voicemail_File"
                        singleSelect displayValue="key"
                        options={EnabledOptions}
                        onChange={handleSelectChange}
                        defaultValue={EnabledOptions.find(lang => lang.value == dataa?.Voicemail_File)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10"> Missed Call</p>
                      <Multiselect
                        name="Missed_Call"
                        singleSelect displayValue="key"
                        options={EnabledOptions}
                        onChange={handleSelectChange}
                        defaultValue={EnabledOptions.find(lang => lang.value == dataa?.Missed_Call)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10"> Voicemail Keep Local</p>
                      <Multiselect
                        name="Voicemail_Keep_Local"
                        singleSelect displayValue="key"
                        options={EnabledOptions}
                        onChange={handleSelectChange}
                        defaultValue={EnabledOptions.find(lang => lang.value == dataa?.Voicemail_Keep_Local)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Toll Allow</p>
                      <Form.Control
                        className={check != null && !check.Organization ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Toll_Allow"
                        placeholder="Toll Allow"
                        onChange={handleChange}
                        defaultValue={dataa?.Toll_Allow}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10">Call Timeout</p>
                      <Form.Control
                        className={check != null && !check.Organization ? "form-control is-invalid state-invalid" : "form-group"}
                        type="text"
                        name="Call_Timeout"
                        placeholder="Call Timeout"
                        onChange={handleChange}
                        defaultValue={dataa?.Call_Timeout}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <p className="mg-b-10"> Call Screen</p>
                      <Multiselect
                        name="Call_Screen"
                        singleSelect displayValue="key"
                        options={EnabledOptions}
                        onChange={handleSelectChange}
                        defaultValue={EnabledOptions.find(lang => lang.value == dataa?.Call_Screen)}
                      />
                    </Form.Group>

                    <Row>
                      <Col md={4}>
                        <Form.Group>
                          <p className="mg-b-10"> Device Provisioning </p>
                          <Multiselect classNamePrefix="Select2"
                            name="Force_Ping"
                            singleSelect displayValue="key"
                            options={listDevices}
                            onChange={handleSelectChange}
                            defaultValue={EnabledOptions.find(lang => lang.value == dataa?.Call_Screen)}
                          />
                        </Form.Group>
                      </Col>
                      {/*  <Col md={4} >
                        <Form.Group>
                          <p style={{ margin: 30 }}></p>

                          <Multiselect classNamePrefix="Select2"
                            name="Force_Ping"
                            singleSelect displayValue="key"
                          //options={CallerIdOptions}
                          //onChange={handleSelectChangeAdvanced}
                          // defaultValue={CallerIdOptions.find(lang => lang.value == dataa?.Advanced.Force_Ping)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4} >
                        <Form.Group>
                          <p  style={{ margin: 30 }} ></p>
                          <Multiselect classNamePrefix="Select2"
                            name="Force_Ping"
                            singleSelect displayValue="key"
                          // options={CallerIdOptions}
                          // onChange={handleSelectChangeAdvanced}
                          // defaultValue={CallerIdOptions.find(lang => lang.value == dataa?.Advanced.Force_Ping)}
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>



                  </Col>

                </Row>


                <Row>
                  <Col >
                    <div className="col-md-8 ps-md-2 btn-list" style={{ marginBottom: "10px" }}>
                      <Button className="btn ripple btn-secondary pd-x-30 mg-r-5" type='button'
                        onClick={() => setShowForm(!showForm)}
                      >
                        <FontAwesomeIcon icon={showForm ? faMinus : faPlus} />
                      </Button>
                    </div>
                  </Col>
                </Row>
                {showForm && (
                  <Row>
                    <Col>
                      <form>
                        <Row className="row-sm">
                          <Col md={6} >

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Auth ACL</p>
                              <Form.Control
                                className={check != null && !check.Auth_Username ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text" name="Auth_Acl" placeholder="Auth Username" defaultValue={dataa?.Advanced.Auth_Acl} onChange={handleChangeAdvanced} required
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">CIDR</p>
                              <Form.Control
                                className={check != null && !check.CIDR ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text" name="CIDR" placeholder="CIDR" defaultValue={dataa?.Advanced.CIDR} onChange={handleChangeAdvanced} required
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">SIP force Contact </p>
                              <Multiselect
                                name="SIP_force_Contact "
                                singleSelect displayValue="key"
                                options={RegisterTransportOptions}
                                onChange={handleSelectChangeAdvanced}
                                defaultValue={RegisterTransportOptions.find(lang => lang.value == dataa?.Advanced.SIP_force_Contact)}
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">SIP By Pass Media </p>
                              <Multiselect
                                name="SIP_By_Pass_Media"
                                singleSelect displayValue="key"
                                options={RegisterTransportOptions}
                                onChange={handleSelectChangeAdvanced}
                                defaultValue={RegisterTransportOptions.find(lang => lang.value == dataa?.Advanced.SIP_By_Pass_Media)}
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">SIP Force Expires</p>
                              <Form.Control
                                className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text"
                                name="SIP Force Expires"
                                placeholder="Register Proxy"
                                onChange={handleChangeAdvanced}
                                defaultValue={dataa?.Advanced.SIP_Force_Expires}
                              />
                            </Form.Group>

                          </Col>


                          <Col md={6}>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">MWI Account</p>
                              <Form.Control
                                className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text"
                                name="MWI_Account"
                                placeholder="MWI Account"
                                onChange={handleChangeAdvanced}
                                defaultValue={dataa?.Advanced.MWI_Account}
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Force Ping </p>
                              <Multiselect classNamePrefix="Select2"
                                name="Force_Ping"
                                singleSelect displayValue="key"
                                options={CallerIdOptions}
                                onChange={handleSelectChangeAdvanced}
                                defaultValue={CallerIdOptions.find(lang => lang.value == dataa?.Advanced.Force_Ping)}
                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Absolute Codec String</p>
                              <Form.Control
                                className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text"
                                name="Absolute_Codec_String"
                                placeholder="Hostname"
                                onChange={handleChangeAdvanced}
                                defaultValue={dataa?.Advanced.Absolute_Codec_String}

                              />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <p className="mg-b-10">Dial String</p>
                              <Form.Control
                                className={check != null && !check.Last_name ? "form-control is-invalid state-invalid" : "form-group"}
                                type="text"
                                name="Dial_String"
                                placeholder="Dial String"
                                onChange={handleChangeAdvanced}
                                defaultValue={dataa?.Advanced.Dial_String}
                              />
                            </Form.Group>




                          </Col>

                        </Row>
                      </form>
                    </Col>
                  </Row>
                )}


                <Row>
                  <Col><div className="col-md-8 ps-md-2 btn-list">
                    <Link to={`/accounts/extensions`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' >
                      Previous
                    </Button></Link>
                  </div></Col>
                  <Col md={2}> <div className="col-md-8 ps-md-9 btn-list" style={{ left: '40%' }}>
                    {dataa ? <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' onClick={onFinish} >
                      update
                    </Button> : <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' onClick={onFinish} >
                      Submit
                    </Button>}
                  </div>
                  </Col>

                </Row>




              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>


    </Fragment>
  )
}


AddEditExtension.propTypes = {};

AddEditExtension.defaultProps = {};

export default AddEditExtension;