import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class AgentApiService extends BaseApiService {

  constructor() {

    super('agents');
  }
  getcustomer(params) {

  }
 

}

const agentApiService = new AgentApiService();

export default agentApiService;
