import React from 'react'
import { Card, CardHeader, CardTitle, CardBody, Row, Col, Button } from 'reactstrap'
import { AvForm, AvInput } from "availity-reactstrap-validation";
import FormGroup from 'reactstrap/lib/FormGroup';

const SearchBox = ({ filter, onHandleFilter }) => {

  const handleFilter = (events, values) => {
    let meta_key = values.searchvalue;
    console.log("submit",meta_key)
    onHandleFilter(filter, meta_key);
  }

  const handleChange = event => {
    let meta_key = event.target.value;
    console.log("meta",meta_key )
    
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h4'>Search</CardTitle>
      </CardHeader>
      <CardBody>
        <AvForm onValidSubmit={handleFilter}>
          <FormGroup>
            <Row>
              <Col md='12'>
                <AvInput name="searchvalue" type='text' value={filter.searchvalue} onChange={handleChange} />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md='12'>
                <Button color='primary' block type='submit'>
                  Search
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </AvForm>
      </CardBody>
    </Card>
  )
}

export default SearchBox
