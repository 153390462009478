import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes, Redirect, Link, useHistory } from "react-router-dom";

import { Provider, useSelector } from 'react-redux';
import BaseApiService from "../src/util/BaseApiService";
import "./index.scss";
import Loader from "./layouts/Loader/Loader";
import Auth from "./Authentication/auth";
import User from "./components/Accounts/User";
import AddEditUser from "./components/Accounts/AddEditUser";
import Index from "./components/Getways/index";
import AddEditGetways from "./components/Getways/AddEditGetways";
import IndexExtension from "./components/Extensions/indexExtension";
import AddEditExtension from "./components/Extensions/AddEditExtension";
import IndexDevices from "./components/Devices/indexDevices";
import AddEditDevices from "./components/Devices/AddEditDevices";
//Destination
import IndexDestination from "./Destinations/indexDestination";
import AddEditDestination from "./Destinations/AddEditDestination"
//Inbounds
import IndexInbounds from "./inbounds/index"
import AddEditInbound from "./inbounds/AddEditInbound"
//Outbounds
import IndexOutbounds from "./outbounds/index"
import AddEditOutbound from "./outbounds/AddEditOutbound"
//Agents
import IndexAgents from "./agents/index"
import AddEditAgent from "./agents/AddEditAgent"
//Call_Center
import IndexCallCenter from "./call_center/index"
import AddEditCallcenter from "./call_center/AddEditCallcenter"
//Bridges
import IndexBridges from "./bridges/index"
import AddEditBridges from "./bridges/AddEditBridge"
//CallBlocks
import IndexCallBlocks from "./callBlocks/index"
import AddEditCallBlocks from "./callBlocks/AddEditCallBlock"
//CallFlows
import IndexCallFlows from "./callFlows/index"
import AddEditCallFlows from "./callFlows/AddEditCallFlow"
//ConferenceCenter
import IndexConferenceCenter from "./conferenceCenters/index"
import AddEditConferenceCenter from "./conferenceCenters/AddEditConferenceCenter"
//ConferenceRooms
import IndexConferenceRoom from "./conferenceRooms/index"
import AddEditConferenceRoom from "./conferenceRooms/AddEditConferenceRoom"
import store from "./redux/store";
//Contact 
import Contact from "./contacts/index"
import AddEditContact from "./contacts/AddEditContact"
//FaxServer 
import IndexFaxServer from "./FaxServers/index"
import AddEditFaxServer from "./FaxServers/AddEditFaxServer"
//IVR_Menus 
import IndexIVR_Menus from "./IVR_Menus/index"
import AddEditIvrMenus from "./IVR_Menus/AddEditIvrMenus"
//import SideBar from "./layouts/SideBar/SideBar";
//import Dashboard from "./dashboard/Dashboard"
const AuthLogin = React.lazy(() => import("./Authentication/Login"));
const AuthSignup = React.lazy(() => import("./Authentication/Signup"));
const Dashboard = React.lazy(() => import("./dashboard/Dashboard"));
const App = React.lazy(() => import("./components/app"));
const baseApiService = new BaseApiService();

const Root = () => {

  /*  const verifyToken = () => {
     const Token = localStorage.getItem('token');
     if (Token) {
         let params = {
             token: Token
         }
         baseApiService.verifyToken(params).then(res => {
             if (res.data.success) {
 
               <Navigate to="/dashboard" replace={true} />
             } else {
                 localStorage.removeItem('token');
                 return false
             }
         }).catch(err => {
             localStorage.removeItem('token');
            return false
         })
     } else {
         return false
     }
   } */
  const Groups = localStorage.getItem('Groups');
  console.log("role", Groups)

  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter>
          <React.Suspense fallback={<Loader />}>
            <Routes>
              <Route>
                <Route
                  path={`/login`}
                  element={<AuthLogin />}
                />
                {Groups === 'SuperAdmin' ? (
                  <>
                    <Route path={`/`}
                      element={<App />}>
                      <Route path={`/dashboard`} element={<Dashboard />} />

                      {/* //Accounts */}
                      <Route path={`/accounts/user`} element={<User />} />
                      <Route path={`/accounts/user/addEditUser`} element={<AddEditUser />} />
                      {/* //Getway */}
                      <Route
                        path={`/accounts/getways`}
                        element={< Index />}
                      />
                      <Route
                        path={`/accounts/getways/addEditGetways`}
                        element={<AddEditGetways />}
                      />
                      <Route
                        path={`/accounts/getways/addEditGetways/:id`}
                        element={<AddEditGetways />}
                      />
                      {/* //Extensions */}
                      <Route
                        path={`/accounts/extensions`}
                        element={<IndexExtension />}
                      />
                      <Route
                        path={`/accounts/extensions/addEditExtension`}
                        element={<AddEditExtension />}
                      />
                      <Route
                        path={`/accounts/extensions/addEditExtension/:id`}
                        element={<AddEditExtension />}
                      />
                      {/* //Devices */}
                      <Route
                        path={`/accounts/devices`}
                        element={<IndexDevices />}
                      />
                      <Route
                        path={`/accounts/devices/addEditDevices`}
                        element={<AddEditDevices />}
                      />
                      <Route
                        path={`/devices/addEditDevices/:id`}
                        element={<AddEditDevices />}
                      />
                      {/*  //Destination */}
                      <Route
                        path={`/dialplan/destinations`}
                        element={<IndexDestination />}
                      />
                      <Route
                        path={`/dialplan/destinations/addEditDestination`}
                        element={<AddEditDestination />}
                      />
                      <Route
                        path={`/dialplan/destinations/AddEditDestination/:id`}
                        element={<AddEditDestination />}
                      />
                      {/* //Inbounds */}
                      <Route
                        path={`/dialplan/inbounds`}
                        element={<IndexInbounds />}
                      />
                      <Route
                        path={`/dialplan/inbounds/AddEditInbound`}
                        element={<AddEditInbound />}
                      />
                      <Route
                        path={`/dialplan/inbounds/AddEditInbound/:id`}
                        element={<AddEditInbound />}
                      />
                      {/* //Outbounds */}
                      <Route
                        path={`/dialpan/outbounds`}
                        element={<IndexOutbounds />}
                      />
                      <Route
                        path={`/dialpan/outbounds/AddEditOutbound`}
                        element={<AddEditOutbound />}
                      />
                      <Route
                        path={`/dialpan/outbounds/AddEditOutbound/:id`}
                        element={<AddEditOutbound />}
                      />
                      {/* //Agents */}
                      <Route
                        path={`/application/agents`}
                        element={<IndexAgents />}
                      />
                      <Route
                        path={`/application/agents/addEditAgent`}
                        element={<AddEditAgent />}
                      />
                      <Route
                        path={`/application/agents/addEditAgent/:id`}
                        element={<AddEditAgent />}
                      />
                      {/*  //Call_Center */}
                      <Route
                        path={`/application/call_center`}
                        element={<IndexCallCenter />}
                      />
                      <Route
                        path={`/application/call_center/addEditCallcenter`}
                        element={<AddEditCallcenter />}
                      />
                      {/*  //Bridges */}
                      <Route
                        path={`/application/bridges`}
                        element={<IndexBridges />}
                      />
                      <Route
                        path={`/application/bridges/AddEditBridge`}
                        element={<AddEditBridges />}
                      />
                      {/*  //CallBlocks */}
                      <Route
                        path={`/application/callBlocks`}
                        element={<IndexCallBlocks />}
                      />
                      <Route
                        path={`/application/callBlocks/addEditCallBlock`}
                        element={<AddEditCallBlocks />}
                      />
                      {/*  //CallFlows */}
                      <Route
                        path={`/application/callFlows`}
                        element={<IndexCallFlows />}
                      />
                      <Route
                        path={`/application/callFlows/addEditCallFlow`}
                        element={<AddEditCallFlows />}
                      />
                      {/* ConferenceCenter */}
                      <Route
                        path={`/application/conferenceCenters`}
                        element={<IndexConferenceCenter />}
                      />
                      <Route
                        path={`/application/conferenceCenters/addEditConferenceCenter`}
                        element={<AddEditConferenceCenter />}
                      />
                      {/* ConferenceRooms */}
                      <Route
                        path={`/application/conferenceRooms`}
                        element={<IndexConferenceRoom />}
                      />
                      <Route
                        path={`/application/conferenceRooms/addEditConferenceRoom`}
                        element={<AddEditConferenceRoom />}
                      />
                      {/* Contact */}
                      <Route
                        path={`/application/contacts`}
                        element={<Contact />}
                      />
                      <Route
                        path={`/application/addEditContact`}
                        element={<AddEditContact />}
                      />
                      {/* faxServers */}
                      <Route
                        path={`/application/faxServers`}
                        element={<IndexFaxServer />}
                      />
                      <Route
                        path={`/application/faxServers/addEditFaxServer`}
                        element={<AddEditFaxServer />}
                      />
                      {/* IVR_Menus */}
                      <Route
                        path={`/application/ivr_menus`}
                        element={<IndexIVR_Menus />}
                      />
                      <Route
                        path={`/application/ivr_menus/addEditIvrMenus`}
                        element={<AddEditIvrMenus />}
                      />
                    </Route>
                  </>
                ) : Groups === 'Admin' ? (
                  <>
                    <Route path={`/`}
                      element={<App />}>
                      <Route path={`/dashboard`} element={<Dashboard />} />
                      {/* //Accounts */}
                      <Route path={`/accounts/user`} element={<User />} />
                      <Route path={`/accounts/user/addEditUser`} element={<AddEditUser />} />

                      {/* //Extensions */}
                      <Route
                        path={`/accounts/extensions`}
                        element={<IndexExtension />}
                      />
                      <Route
                        path={`/accounts/extensions/addEditExtension`}
                        element={<AddEditExtension />}
                      />
                      {/* //Devices */}
                      <Route
                        path={`/accounts/devices`}
                        element={<IndexDevices />}
                      />
                      <Route
                        path={`/accounts/devices/addEditDevices`}
                        element={<AddEditDevices />}
                      />
                      {/*  //Destination */}
                      <Route
                        path={`/dialplan/destinations`}
                        element={<IndexDestination />}
                      />
                      <Route
                        path={`/dialplan/destinations/addEditDestination`}
                        element={<AddEditDestination />}
                      />
                      {/* //Agents */}
                      <Route
                        path={`/application/agents`}
                        element={<IndexAgents />}
                      />
                      <Route
                        path={`/application/agents/addEditAgent`}
                        element={<AddEditAgent />}
                      />
                      <Route
                        path={`/application/agents/addEditAgent/:id`}
                        element={<AddEditAgent />}
                      />

                      {/*  //Call_Center */}
                      <Route
                        path={`/application/call_center`}
                        element={<IndexCallCenter />}
                      />
                      <Route
                        path={`/application/call_center/addEditCallcenter`}
                        element={<AddEditCallcenter />}
                      />
                      {/*  //CallBlocks */}
                      <Route
                        path={`/application/callBlocks`}
                        element={<IndexCallBlocks />}
                      />
                      <Route
                        path={`/application/callBlocks/addEditCallBlock`}
                        element={<AddEditCallBlocks />}
                      />

                      {/*  //CallFlows */}
                      <Route
                        path={`/application/callFlows`}
                        element={<IndexCallFlows />}
                      />
                      <Route
                        path={`/application/callFlows/addEditCallFlow`}
                        element={<AddEditCallFlows />}
                      />
                      {/* ConferenceCenter */}
                      <Route
                        path={`/application/conferenceCenters`}
                        element={<IndexConferenceCenter />}
                      />
                      <Route
                        path={`/application/conferenceCenters/addEditConferenceCenter`}
                        element={<AddEditConferenceCenter />}
                      />
                      {/* ConferenceRooms */}
                      <Route
                        path={`/application/conferenceRooms`}
                        element={<IndexConferenceRoom />}
                      />
                      <Route
                        path={`/application/conferenceRooms/addEditConferenceRoom`}
                        element={<AddEditConferenceRoom />}
                      />
                      {/* Contact */}
                      <Route
                        path={`/application/contacts`}
                        element={<Contact />}
                      />
                      <Route
                        path={`/application/addEditContact`}
                        element={<AddEditContact />}
                      />
                      {/* faxServers */}
                      <Route
                        path={`/application/faxServers`}
                        element={<IndexFaxServer />}
                      />
                      <Route
                        path={`/application/faxServers/addEditFaxServer`}
                        element={<AddEditFaxServer />}
                      />
                      {/* IVR_Menus */}
                      <Route
                        path={`/application/ivr_menus`}
                        element={<IndexIVR_Menus />}
                      />
                      <Route
                        path={`/application/ivr_menus/addEditIvrMenus`}
                        element={<AddEditIvrMenus />}
                      />

                    </Route>
                  </>
                ) : Groups === 'User' ? (
                  <>
                    <Route path={`/`}
                      element={<App />}>
                      <Route path={`/dashboard`} element={<Dashboard />} />
                      {/*  //CallBlocks */}
                      <Route
                        path={`/application/callBlocks`}
                        element={<IndexCallBlocks />}
                      />
                      <Route
                        path={`/application/callBlocks/addEditCallBlock`}
                        element={<AddEditCallBlocks />}
                      />
                      {/* ConferenceCenter */}
                      <Route
                        path={`/application/conferenceCenters`}
                        element={<IndexConferenceCenter />}
                      />
                      <Route
                        path={`/application/conferenceCenters/addEditConferenceCenter`}
                        element={<AddEditConferenceCenter />}
                      />
                      {/* ConferenceRooms */}
                      <Route
                        path={`/application/conferenceRooms`}
                        element={<IndexConferenceRoom />}
                      />
                      <Route
                        path={`/application/conferenceRooms/addEditConferenceRoom`}
                        element={<AddEditConferenceRoom />}
                      />
                      {/* faxServers */}
                      <Route
                        path={`/application/faxServers`}
                        element={<IndexFaxServer />}
                      />
                      <Route
                        path={`/application/faxServers/addEditFaxServer`}
                        element={<AddEditFaxServer />}
                      />
                    </Route>
                  </>
                ) : null}
                
              </Route>
            </Routes>
          </React.Suspense>
        </BrowserRouter>
      </Provider>
    </Fragment>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />)





