import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class ContactApiService extends BaseApiService {

  constructor() {

    super('Contacts');
  }
  getcustomer(params) {

  }
 

}

const contactApiService = new ContactApiService();

export default contactApiService;
