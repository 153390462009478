import BaseApiService from "../BaseApiService";
import axios from "axios";
import { apiUrl } from "../../configs/site.config";


class IVR_MenusApiService extends BaseApiService {

  constructor() {

    super('IVR_Menus');
  }
  getcustomer(params) {

  }
 

}

const iVR_MenusApiService = new IVR_MenusApiService();

export default iVR_MenusApiService;
