import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import {
    Button, Card, CardBody, Col, FormGroup, Label, Row
} from 'reactstrap'
import 'react-toastify/dist/ReactToastify.css';
import SuccessAlert from "../data/extensions/alerte_extensions/SuccessAlert";
import ErrorAlert from "../data/extensions/alerte_extensions/ErrorAlert";
import { useLocation } from 'react-router-dom';
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation';
import { Breadcrumb } from "react-bootstrap";
import Select from 'react-select'
import { selectThemeColors } from '../data/Utils'
import { RecordOptions, StatusOptions, ContactOptions } from "../util/FormAgent";
import accountsApiService from "../util/services/AccountsApiService";
import { ToastContainer } from 'react-toastify';
import AgentApiService from "../util/services/AgentApiService";
import ExtensionsApiService from "../util/services/ExtensionsApiService";

const AddEditAgent = () => {
    const [account, setAccounts] = useState({});
    const [initApp, setInitApp] = useState(true);
    const [user_info, setUser_info] = useState(null);
    const [record, setRecord] = useState(null);
    const [contact, setContact] = useState(null);
    const [status, setStatus] = useState(null);
    const [extension_info, setExtension_info] = useState(null);
    const [extension, setExtension] = useState(null);

    let Groups = localStorage.getItem('Groups');
    console.log("role",Groups)
    let Account_id = localStorage.getItem('account_id');
    console.log("id",Account_id)

    let navigate = useNavigate();
    const location = useLocation();
    const dataa = location?.state?.object;
    const onFinish = (event, values) => {
        
        event.preventDefault();
        values.account_id = user_info?.value
        values.Record = record?.value
        values.Contact = contact?.value
        values.Status = status?.value
        values.Extension_id= extension_info?.value

         if(Groups=='Admin' || Groups=='User'){
            values.account_id=Account_id }
        if (location?.state?.object) {
            values.agent_id = dataa.agent_id
            AgentApiService.update(values).then(res => {
                setTimeout(() => {
                    navigate(`/application/agents`);
                }, 3000);
                notifySuccess("Agent has been updated");
            }).catch(err => {
                notifyError("Error to update Agent" + err);
            });
        }
        else {
            AgentApiService.save(values).then(res => {
                console.log("test",res)
                setTimeout(() => {
                    navigate(`/application/agents`);
                }, 3000);
                notifySuccess("Agent  has been added")
            }).catch(err => {
                notifyError(" error ");
            });
        }
   
    }

    const getAllAccounts = () => {
        accountsApiService.find().then(res => {
            const Useer = []
            const accounts = res.data.data
            accounts.forEach(element => {
                if (element.account_id !== undefined && element.account_id !== null) {
                    let user_data = {
                        value: element.account_id,
                        label: element.Username
                    }
                    Useer.push(user_data)
                    if (dataa && element && dataa.account_id === element.account_id) {
                        setUser_info(user_data);
                    }
                }
            });
            setAccounts(Useer);
        });
    };
     const getAllExtension = () => {
        ExtensionsApiService.find().then(res => {
            const Extension = []
            const Extensions = res.data.data
            Extensions.forEach(element => {
                if (element.Extension_id !== undefined && element.Extension_id !== null) {
                    let user_data = {
                        value: element.Extension_id,
                        label: element.Extension
                    }
                    Extension.push(user_data)
                    if (dataa && element && dataa.Extension_id === element.Extension_id) {
                        setExtension_info(user_data);
                    }
                }
            });
            setExtension(Extension);
        });
    }; 

    useEffect(() => {
        const Contact = ContactOptions.find(item => item.value == dataa?.Contact)
        setContact(Contact)
        const Status = StatusOptions.find(item => item.value == dataa?.Status)
        setStatus(Status)
        const Record = RecordOptions.find(item => item.value == dataa?.Record)
        setRecord(Record)
        getAllAccounts()
        getAllExtension()

    }, [initApp]);

    const notifySuccess = (message) => SuccessAlert(message)
    const notifyError = (message) => ErrorAlert(message)

    return (
        <Fragment>
            <ToastContainer
            />
            <div className="page-header">
                <div>
                    {dataa ? <h2 className="main-content-title tx-24 mg-b-5"> Edit Agent</h2> : <h2 className="main-content-title tx-24 mg-b-5"> Add Agent</h2>}
                    <Breadcrumb >
                        <Link to={`/dashboard`}>
                            <Breadcrumb.Item active>
                                Home /
                            </Breadcrumb.Item></Link>
                        <Link to={`/application/agents`}>
                            <Breadcrumb.Item active>
                                Agents
                            </Breadcrumb.Item></Link>
                    </Breadcrumb>
                </div>

            </div>
            <AvForm onValidSubmit={onFinish}>
                <Row>
                    <Col md='12'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Agent Name</Label>
                                            <AvInput type='text' name='Agent_Name'
                                                value={dataa?.Agent_Name} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Type</Label>
                                            <AvInput type='text' name='Type'
                                                value={dataa?.Type} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Call Timeout</Label>
                                            <AvInput type='text' name='Call_Timeout'
                                                value={dataa?.Call_Timeout} />
                                        </FormGroup>
                                    </Col>

                                    <Col sm='6'>
                                    {Groups =='SuperAdmin' ?   <FormGroup>
                                            <Label>Username</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={user_info}
                                                options={account}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setUser_info(obj)}
                                                name='account_id'
                                            />
                                        </FormGroup> : null }
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Agent ID</Label>
                                            <AvInput type='text' name='Agent_Id'
                                                value={dataa?.Agent_Id} />
                                        </FormGroup>
                                    </Col>

                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Agent Password</Label>
                                            <AvInput type='text' name='Agent_Password'
                                                value={dataa?.Agent_Password} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>No Answer Delay Time</Label>
                                            <AvInput type='text' name='No_Answer_Delay_Time'
                                                value={dataa?.No_Answer_Delay_Time} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Max No Answer</Label>
                                            <AvInput type='text' name='Max_No_Answer'
                                                value={dataa?.Max_No_Answer} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Wrap Up Time</Label>
                                            <AvInput type='text' name='Wrap_Up_Time'
                                                value={dataa?.Wrap_Up_Time} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Reject Delay Time</Label>
                                            <AvInput type='text' name='Reject_Delay_Time'
                                                value={dataa?.Reject_Delay_Time} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Busy Delay Time</Label>
                                            <AvInput type='text' name='Busy_Delay_Time'
                                                value={dataa?.Busy_Delay_Time} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup>
                                            <Label>Record</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={record}
                                                options={RecordOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setRecord(obj)}
                                                name='Record'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='4'>
                                        <FormGroup>
                                            <Label>Contact</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={contact}
                                                options={ContactOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setContact(obj)}
                                                name='Contact'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='4'>
                                        <FormGroup>
                                            <Label>Status</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={status}
                                                options={StatusOptions}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setStatus(obj)}
                                                name='Status'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm='4'>
                                        <FormGroup>
                                            <Label>Extension</Label>
                                            <Select
                                                theme={selectThemeColors}
                                                className='react-select'
                                                classNamePrefix='select'
                                                value={extension_info}
                                                options={extension}
                                                isLoading={false}
                                                isClearable={true}
                                                onChange={(obj) => setExtension_info(obj)}
                                                name='Extension_id'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col><div className="col-md-8 ps-md-2 btn-list">
                                <Link to={`/application/agents`}> <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='button' >
                                    Previous
                                </Button></Link>
                            </div></Col>
                            <Col md={2}> <div className="col-md-8 ps-md-9 btn-list" style={{ left: '40%' }}>
                                <Button className="btn ripple btn-primary pd-x-30 mg-r-5" type='submit' >
                                    Submit
                                </Button>
                            </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </AvForm>
        </Fragment>
    )
}

AddEditAgent.propTypes = {};
AddEditAgent.defaultProps = {};
export default AddEditAgent;