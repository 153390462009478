import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap'
import "react-data-table-component-extensions/dist/index.css";
import { Archive, MoreVertical, Trash2 } from "react-feather";
import { useNavigate } from 'react-router-dom'

const ContactTable = ({contacts, enableDelete, handleRowsPerPageChange, handleChangePage, totalRows }) => {
    const [contact_id, setContact_id] = useState(0)
    const [handleDelete, setHandleDelete] = useState(false)
    const [data, setData] = useState([])
    let navigate = useNavigate();

    const OnDeleteDestinationItem = (contact_id) => {
        setContact_id(contact_id);
        setHandleDelete(!handleDelete);
        enableDelete(contact_id)
    };

    const handleEdit = (id) => {
        const object = contacts.find(obj => obj.contact_id === id)
        let path = `/application/addEditContact`;
        navigate(path, { state: { object } });
    }
    
    const columns = [
        {
            name: "NAME",
            selector: row => [row.Name.Organization],
            sortable: true
        },
        {
            name: "Other",
            selector: row => [row.Other.Type],
            sortable: true
        },

        {
            name: " Numbers",
            selector: row => [row.Numbers.Label],
            sortable: true
        },
        {
            name: 'Actions',
            minWidth: '100px',
            cell: row => (
                <UncontrolledDropdown>
                    <DropdownToggle tag='div' className='btn btn-sm'>
                        <MoreVertical size={14} className='cursor-pointer' />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => handleEdit(row.contact_id)} className='w-100'>
                            <Archive size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }}>Edit</span>
                        </DropdownItem>
                        <DropdownItem className='w-100' onClick={() => OnDeleteDestinationItem(row.contact_id)}  >
                            <Trash2 size={14} className='mr-50' />
                            <span className='align-middle' style={{ paddingLeft: '10px' }}>Delete</span>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )
        }
    ];
    const tableDatas = {
        columns,
        data: contacts
    };

    return (
        <Fragment>
            <DataTableExtensions {...tableDatas}  >
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onChangePage={handleChangePage}
                    paginationServer
                    paginationTotalRows={totalRows}
                    noHeader={false}
                />
            </DataTableExtensions>
        </Fragment>
    )
}
export default ContactTable
